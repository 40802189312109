import * as React from 'react';
import { BaseIconProps } from '../commonProps';

const FavoriteIcon = (props: BaseIconProps): React.ReactElement<BaseIconProps> => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M9.91239 4.39608L9.99988 4.55416L10.0874 4.39608C10.5333 3.59037 11.184 2.91659 11.9736 2.4428C12.7625 1.96951 13.6621 1.71252 14.5818 1.69769C16.0502 1.76227 17.4335 2.40497 18.4299 3.48563C19.4269 4.56702 19.9554 5.99906 19.9 7.46889L19.8999 7.46888V7.47266C19.8999 9.33433 18.9187 11.2945 17.508 13.1475C16.0991 14.9982 14.272 16.7288 12.6023 18.1294L12.6022 18.1294C11.8734 18.7419 10.9519 19.0777 9.99988 19.0777C9.04788 19.0777 8.12638 18.7419 7.39755 18.1294L7.39748 18.1294C5.72779 16.7288 3.90065 14.9982 2.49174 13.1475C1.08104 11.2945 0.0998839 9.33433 0.0998839 7.47266H0.099955L0.0998127 7.46889C0.0443503 5.99906 0.572864 4.56702 1.56989 3.48563C2.56625 2.40497 3.94955 1.76227 5.41794 1.69769C6.3377 1.71252 7.23732 1.96951 8.02613 2.4428C8.81579 2.91659 9.46644 3.59037 9.91239 4.39608ZM11.6601 17.0075L11.6957 16.9775V16.9743C13.8239 15.1865 15.5025 13.432 16.6515 11.8194C17.8044 10.2015 18.4323 8.71519 18.4332 7.47533C18.4908 6.39551 18.1195 5.3366 17.4001 4.52921C16.68 3.72114 15.6696 3.23058 14.5893 3.16451L14.5894 3.16376L14.5771 3.16451C13.4968 3.23058 12.4864 3.72114 11.7664 4.52921C11.0469 5.33658 10.6756 6.39545 10.7332 7.47525C10.7325 7.6688 10.6553 7.85428 10.5184 7.9912C10.3809 8.12873 10.1944 8.20599 9.99988 8.20599C9.80539 8.20599 9.61887 8.12873 9.48134 7.9912C9.34442 7.85428 9.26724 7.6688 9.26655 7.47525C9.32415 6.39545 8.95284 5.33658 8.23341 4.52921C7.51335 3.72114 6.50298 3.23058 5.42266 3.16451L5.4227 3.16376L5.41045 3.16451C4.33013 3.23058 3.31975 3.72114 2.59969 4.52921C1.88023 5.33661 1.50892 6.39553 1.56655 7.47536C1.56746 8.71521 2.19535 10.2015 3.34823 11.8198C4.50371 13.4418 6.1946 15.2077 8.33971 17.0075C8.80441 17.3988 9.39238 17.6134 9.99988 17.6134C10.6074 17.6134 11.1954 17.3988 11.6601 17.0075Z"
        fill="currentColor"
        stroke="white"
        strokeWidth="0.2"
      />
    </svg>
  );
};

export default FavoriteIcon;
