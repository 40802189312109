import { RouteSegment } from 'config/routes/constants';
import { routerUrls } from 'config/routes/routerUrls';

type MenuItem = {
  // Паттерн для мэтчинга через matchPath
  pattern: string;
  // URL страницы для навигации при клике на элемент меню
  url: string;
  title: string;
  isAuthRequired: boolean;
};

export const MENU_ITEMS: MenuItem[] = [
  {
    pattern: `/${RouteSegment.lots}/*`,
    url: routerUrls.lotsList.create(),
    title: 'Каталог объектов',
    isAuthRequired: false,
  },
  {
    pattern: `/${RouteSegment.profile}/${RouteSegment.robots}`,
    url: routerUrls.profileRobot.create(),
    title: 'Умный поиск',
    isAuthRequired: true,
  },
  {
    pattern: `/${RouteSegment.tariffs}`,
    url: routerUrls.tariffs.create(),
    title: 'Тарифы',
    isAuthRequired: false,
  },
  {
    pattern: `/${RouteSegment.services_online}`,
    url: routerUrls.servicesOnline.create(),
    title: 'Онлайн-показ',
    isAuthRequired: false,
  },
];
