import { Button, Flex, Typography } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { SupportModal } from 'components/SupportModal';
import { Wrapper } from 'components/Wrapper';
import { LogoSmallIcon } from 'components/icons/Logo/Logo';
import TelegramIcon from 'components/icons/TelegramIcon';
import { LINKS } from 'config/links';
import { ProfileListType, routerUrls } from 'config/routes';
import { AnalyticsEvent } from 'entities/analytics';
import useToggleState from 'hooks/useToggleState';
import { useRootStore } from 'stores/RootStore';
import { useThemeStore } from 'stores/RootStore/context';

import s from './Footer.module.scss';

type FooterProps = {
  className?: string;
};

const Footer: React.FC<FooterProps> = ({ className }) => {
  const { userStore } = useRootStore();
  const currentYear = new Date().getFullYear();
  const { analyticsStore, authStore } = useRootStore();
  const { theme } = useThemeStore();

  const { open: openSupportModal, close: closeSupportModal, opened: openedSupportModal } = useToggleState();

  const handleTelegramClick = React.useCallback(() => {
    analyticsStore.trackEvent({ event: AnalyticsEvent.telegramFooterLinkClick });
  }, [analyticsStore]);

  const handleMenuClick = (e: React.MouseEvent) => {
    if (!userStore.authorized) {
      e.preventDefault();
      e.stopPropagation();
      authStore.popupController.open();
      authStore.mode.change('login');
    }
  };

  return (
    <div className={cn(className, s.footer, theme.value === 'dark' && s.footer__dark)}>
      <Wrapper className={cn(s.footer__row, s.footer__links)}>
        <LogoSmallIcon variant="light" />
        <Flex className={s.footer__list}>
          <Link to={routerUrls.lotsList.create()}>
            <Typography.Text className={s.footer__text}>Каталог объектов</Typography.Text>
          </Link>

          <Link to={routerUrls.profileSettings.create()} onClick={handleMenuClick}>
            <Typography.Text className={s.footer__text}>Личный кабинет</Typography.Text>
          </Link>

          <Typography.Link className={s.footer__text} onClick={openSupportModal}>
            Служба поддержки
          </Typography.Link>

          <Link to={routerUrls.tariffs.create()}>
            <Typography.Text className={s.footer__text}>Тарифы</Typography.Text>
          </Link>
        </Flex>
        <Button
          className={s['footer__button-icon']}
          type="link"
          href={LINKS.telegram}
          target="_blank"
          icon={<TelegramIcon />}
          onClick={handleTelegramClick}
          aria-label="Telegram"
        />
      </Wrapper>
      <Wrapper className={s.footer__contacts}>
        <Typography.Text className={cn(s.footer__contacts__title, s.footer__contacts__text)}>Контакты:</Typography.Text>
        <Typography.Text className={cn(s.footer__contacts__item, s.footer__contacts__text)}>
          E-mail: <a href="mailto:services@kupilot.ru">services@kupilot.ru</a>
        </Typography.Text>
        <Typography.Text className={cn(s.footer__contacts__item, s.footer__contacts__text)}>
          Telegram: <a href="https://t.me/kupilot_robot">@kupilot_robot</a>
        </Typography.Text>
      </Wrapper>
      <Wrapper className={cn(s.footer__row, s.footer__agreements, s['align-end'])}>
        <Typography.Text className={s.footer__agreement}>
          © Copyright {currentYear}. Все права защищены.
        </Typography.Text>
        <div className={s.column}>
          <Typography.Link target="_blank" className={s.footer__agreement} href={LINKS.agree}>
            Согласие на обработку персональных данных
          </Typography.Link>
          <Typography.Link target="_blank" className={s.footer__agreement} href={LINKS.politics}>
            Политика обработки персональных данных
          </Typography.Link>
          <Typography.Link target="_blank" className={s.footer__agreement} href={LINKS.terms}>
            Условия предоставления услуг и тарификации
          </Typography.Link>
        </div>
      </Wrapper>
      <SupportModal opened={openedSupportModal} close={closeSupportModal} />
    </div>
  );
};

export default observer(Footer);
