import * as React from 'react';
import { BaseIconProps } from '../commonProps';

const ArrowStockIcon = (props: BaseIconProps): React.ReactElement<BaseIconProps> => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none" {...props}>
      <path d="M17.5 7L10.5 14L3.5 7" stroke="currentColor" stroke-linecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ArrowStockIcon;
