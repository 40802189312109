import { RobotHeader } from 'components/Robot';
import { useWidth } from 'hooks/useWidth';
import { observer } from 'mobx-react-lite';
import React, { ReactNode } from 'react';
import { useLotListStore } from 'stores/LotListCatalogStore/context';

export const ListWithFiltersRobot = observer(
  ({ className = '', title, icon }: { className?: string; title?: string | ReactNode; icon?: ReactNode }) => {
    const { width } = useWidth();
    const { setFilters } = useLotListStore();

    const titleFilter = React.useMemo(() => {
      if (title) return title;
      return width >= 768 ? 'Вы можете создать автоматизированный поиск по параметрам' : 'Создать автоматический поиск';
    }, [width, title]);
    return <RobotHeader className={className} title={titleFilter} onClickFilter={setFilters} icon={icon} />;
  },
);
