import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { BaseInput } from 'components/BaseInput';
import { MultiSelect } from 'components/MultiSelect';
import { useLotListStore } from 'stores/LotListCatalogStore/context';

type CityProps = {
  className?: string;
};

const Sections = ({ className }: CityProps): React.ReactElement<CityProps> => {
  const { filters } = useLotListStore();

  return (
    <BaseInput className={className} title="Секция">
      <MultiSelect<string>
        options={filters.sections.options}
        selectedOptions={filters.sections.selectedOptions}
        value={filters.sections.selectedValue.value}
        onChange={filters.sections.toggleValue}
        onSearch={filters.sections.handleSearch}
        searchValue={filters.sections.searchValue.value}
        isOptionsLoading={false}
        dropdownStyle={{
          zIndex: 1300,
        }}
      />
    </BaseInput>
  );
};

export default observer(Sections);
