import { Collapse, CollapseProps, Space, Typography } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { Wrapper } from 'components/Wrapper';
import { ElementId } from 'config/ids';
import { useLotPageMultiStore, useLotPageStore } from 'stores/LotPageStore';
import { useRootStore } from 'stores/RootStore';

import CommonSection from './CommonSection';
import DocsSection from './DocsSection';
import Navigation from './Navigation';
import PropertySection from './PropertySection';

import s from './CommonInfo.module.scss';
import { isLotModel, isLotValueModel } from 'utils/lot';
import { LotModel } from 'models/LotModel';

type CommonInfoProps = {
  className?: string;
  desktopOnly?: boolean;
};

const CommonInfo: React.FC<CommonInfoProps> = ({ className, desktopOnly = false }) => {
  const { lot } = useLotPageMultiStore();
  const { onboardingStore, userStore } = useRootStore();
  const refOne = React.useRef<null | HTMLDivElement>(null);
  const refTwo = React.useRef<null | HTMLDivElement>(null);
  const refThree = React.useRef<null | HTMLDivElement>(null);

  const dataLotInfo = React.useMemo(() => {
    if (isLotValueModel(lot)) {
      return {
        common: {
          title: lot.value.title,
          description: lot.value.description,
          lotPageAddress: lot.value.lotPageAddress,
          objectType: lot.value.objectType,
          auctionOrganizer: lot.value.auctionOrganizer?.name,
          auctionType: lot.value.auctionType,
        },
        property: {
          totalAreaFormatted: lot.value.totalAreaFormatted,
          floor: lot.value.floor,
          objectType: lot.value.objectType,
        },
      };
    }

    return {
      common: {
        title: lot.value.title,
        description: 'Зарегистрируйтесь для просмотра',
        lotPageAddress: 'Зарегистрируйтесь для просмотра',
        objectType: lot.value.objectType,
        auctionOrganizer: 'Зарегистрируйтесь для просмотра',
        auctionType: 'Зарегистрируйтесь для просмотра',
      },
      property: {
        totalAreaFormatted: 'Зарегистрируйтесь для просмотра',
        floor: 'Зарегистрируйтесь для просмотра',
        objectType: lot.value.objectType,
      },
    };
  }, [userStore.authorized, lot]);

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: 'Общие сведения',
      children: (
        <CommonSection lot={dataLotInfo.common} id={ElementId.lotPageCommonMobile} hide={!userStore.authorized} />
      ),
    },
    {
      key: '2',
      label: 'Характеристики лота',
      children: (
        <PropertySection
          lot={dataLotInfo.property}
          id={ElementId.lotPagePropertyCharacteristicsMobile}
          hide={!userStore.authorized}
        />
      ),
    },
  ];

  if (lot.value.documents.length > 0) {
    items.push({
      key: '3',
      label: 'Документы лота',
      children: <DocsSection lot={lot.value} id={ElementId.lotPageDocsMobile} auth={userStore.authorized} hideTitle />,
    });
  }

  // Для онбординга необходимо раскрыть секцию со сведениями о лоте
  const defaultActiveKey = !onboardingStore.isOnboardingPassedModel.value ? ['1'] : undefined;

  const onScroll = React.useCallback(
    (id: ElementId) => {
      switch (id) {
        case ElementId.lotPageCommonDesktop:
          if (refOne?.current) {
            refOne.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
          }
          break;
        case ElementId.lotPagePropertyCharacteristicsDesktop:
          if (refTwo?.current) {
            refTwo.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
          }
          break;
        case ElementId.lotPageDocsDesktop:
          if (refThree?.current) {
            refThree.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
          }
          break;
      }
    },
    [lot.value.id],
  );

  return (
    <Wrapper id={ElementId.lotPage} className={cn(className, s.info, desktopOnly && s.info__info__desktop__only)}>
      <Navigation onScroll={onScroll} grid={desktopOnly} />
      <Space className={cn(s.info__content, desktopOnly && s.info__info__desktop__only)} direction="vertical" size={0}>
        <div ref={refOne}>
          <CommonSection lot={dataLotInfo.common} id={ElementId.lotPageCommonDesktop} hide={!userStore.authorized} />
          {!desktopOnly && <div style={{ paddingTop: 70 }}></div>}
        </div>
        <div>
          <PropertySection
            lot={dataLotInfo.property}
            id={ElementId.lotPagePropertyCharacteristicsDesktop}
            hide={!userStore.authorized}
          />
          {!desktopOnly && <div style={{ paddingTop: 70 }}></div>}
        </div>
        <div>
          <DocsSection lot={lot.value} id={ElementId.lotPageDocsDesktop} auth={userStore.authorized} />
          {!desktopOnly && <div style={{ paddingTop: 70 }}></div>}
        </div>
      </Space>
      {!desktopOnly && (
        <div className={s.collapse__wrap}>
          <Typography.Title className={s.collapse__title} level={3}>
            Сведения о лоте
          </Typography.Title>
          <Collapse
            className={s['info__mobile-content']}
            defaultActiveKey={defaultActiveKey}
            items={items}
            bordered={false}
          />
        </div>
      )}
    </Wrapper>
  );
};

export default observer(CommonInfo);
