import cn from 'classnames';
import * as React from 'react';
import s from './ProfileIconButton.module.scss';
import { UserModel } from 'models/UserModel';
import { LogoutOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react-lite';

type ProfileIconButtonProps = {
  onClick?: VoidFunction;
  className?: string;
  color?: 'grey' | 'blue';
  user?: UserModel | null;
};

const ProfileIconButton: React.FC<ProfileIconButtonProps> = observer(({ onClick, className, color = 'grey', user }) => {
  return (
    <button onClick={onClick} className={cn(className, s['button'])} aria-label="Войти">
      <LogoutOutlined className={cn(s['button__icon'])} />
      {user?.firstName || 'Купи лот'}
    </button>
  );
});

export default ProfileIconButton;
