import { Button, Input, Modal, Space, Typography } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { LINKS } from 'config/links';
import { useKeyboardEvent } from 'hooks/useKeyboardEvent';
import { LoadingStageModel } from 'models/LoadingStageModel';
import { ValueModel } from 'models/ValueModel';
import { useRootStore } from 'stores/RootStore';

import s from './FormModal.module.scss';

type FormModalProps = {
  opened: boolean;
  close: () => void;
  onSubmit: () => void;
  name: ValueModel<string>;
  email: ValueModel<string>;
  description: ValueModel<string>;
  maxDescriptionLength: number;
  submitStage: LoadingStageModel;
  hasErrors: boolean;
  isValidateOnChange: boolean;
  className?: string;
  hideName?: boolean;
  hideEmail?: boolean;
};

const FormModal = ({
  opened,
  close,
  onSubmit,
  name,
  email,
  description,
  maxDescriptionLength,
  submitStage,
  hasErrors,
  isValidateOnChange,
  className,
  hideEmail = false,
  hideName = false,
}: FormModalProps): React.ReactElement<FormModalProps> => {
  const { userStore } = useRootStore();

  const handleNameChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      name.change(event.target.value);

      if (isValidateOnChange) {
        name.validate();
      }
    },
    [name, isValidateOnChange],
  );

  const handleEmailChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      email.change(event.target.value);

      if (isValidateOnChange) {
        email.validate();
      }
    },
    [email, isValidateOnChange],
  );

  const handleDescriptionChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      description.change(event.target.value);

      if (isValidateOnChange) {
        description.validate();
      }
    },
    [description, isValidateOnChange],
  );

  const handleTextareaKeydown = React.useCallback((event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // отмена всплытия по Enter
    event.stopPropagation();
  }, []);

  useKeyboardEvent('Enter', onSubmit, opened);

  return (
    <Modal
      classNames={{ body: s.popup__body }}
      className={cn(s.popup, className)}
      open={opened}
      footer={null}
      closeIcon={null}
      onCancel={close}
      width={640}
      centered
    >
      <Space className={s.popup__body_inner} direction="vertical" size={24}>
        <div>
          <Typography.Title className={s.popup__title} level={2}>
            Оставить заявку
          </Typography.Title>
          <Typography.Paragraph className={s['popup__subtitle']} type="secondary">
            Наш специалист свяжется с вами в ближайшее время
          </Typography.Paragraph>
        </div>
        {!userStore.user?.firstName && !hideName && (
          <Space className={s['popup__input-wrapper']} direction="vertical" size={0}>
            <Input
              className={cn(s['popup__input'], s['popup__input_large'])}
              onChange={handleNameChange}
              placeholder="Имя Фамилия"
              value={name.value}
              status={name.isError ? 'error' : undefined}
            />
            <Typography.Text type="danger">{name.error}</Typography.Text>
          </Space>
        )}

        <Space className={cn(s['popup__input-wrapper'], s['popup__input_large'])} direction="vertical" size={0}>
          <Input
            className={cn(s['popup__input'], s['popup__input_large'])}
            onChange={handleEmailChange}
            placeholder="Email"
            value={email.value}
            status={email.isError ? 'error' : undefined}
          />
          <Typography.Text type="danger">{email.error}</Typography.Text>
        </Space>

        <Space className={s['popup__input-wrapper']} direction="vertical" size={0}>
          <Input.TextArea
            className={cn(s['popup__input'], s['popup__input-textarea'])}
            onChange={handleDescriptionChange}
            onKeyDown={handleTextareaKeydown}
            placeholder="Текст сообщения"
            value={description.value}
            status={description.isError ? 'error' : undefined}
          />
          <div className={s['popup__textarea-footer']}>
            <Typography.Text type="danger">{description.error}</Typography.Text>
            <Typography.Text
              className={cn(s['popup__chars-counter'], description.isError && s['popup__chars-counter_error'])}
              type="secondary"
            >
              {description.value.length}&nbsp;/&nbsp;{maxDescriptionLength}
            </Typography.Text>
          </div>
          <Button
            className={s['popup__submit-button']}
            type="primary"
            onClick={onSubmit}
            loading={submitStage.isLoading}
            disabled={hasErrors}
          >
            Отправить
          </Button>
        </Space>
        <Typography.Text type="secondary" className={s['popup__disclaimer']}>
          Нажимая на кнопку «Отправить» вы соглашаетесь с{' '}
          <Typography.Link className={s['popup__link']} href={LINKS.politics} target="_blank">
            политикой обработки персональных данных
          </Typography.Link>
        </Typography.Text>
      </Space>
    </Modal>
  );
};

export default observer(FormModal);
