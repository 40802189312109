import { Typography } from 'antd';
import cn from 'classnames';
import * as React from 'react';
import MaskedInput from 'react-text-mask';
import { convertStringToNumber } from 'utils/convertStringToNumber';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import s from './RangeFilter.module.scss';
import { formatNumberToString } from 'utils/formatNumberToString';

const defaultMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ' ',
  allowDecimal: true,
  decimalSymbol: '.',
  integerLimit: 15, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

type RangeFilterProps = {
  from: number | null;
  to: number | null;
  valueMin: number | null;
  valueMax: number | null;
  onChangeMin: (v: number | null) => void;
  onChangeMax: (v: number | null) => void;
  placeholderPostfix?: string;
  className?: string;
  placeholderSufix?: {
    from: string;
    to: string;
  };
  placeholderPrefixInput?: {
    from: string;
    to: string;
  };
  placeholderTo?: number;
  seporatorHide?: boolean;
  flat?: boolean;
  size?: 'default' | 'big';
  placeholder?: {
    from: string;
    to: string;
  };
};

const RangeFilterPrice = ({
  from,
  to,
  valueMin,
  valueMax,
  onChangeMin,
  onChangeMax,
  placeholderPostfix,
  className,
  placeholderSufix = {
    from: '',
    to: '',
  },
  placeholderTo,
  seporatorHide = false,
  flat = false,
  size = 'default',
  placeholder,
}: RangeFilterProps): React.ReactElement<RangeFilterProps> => {
  const handleChangeMin = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChangeMin(convertStringToNumber(e.target.value.split(' ').join('')));
    },
    [onChangeMin],
  );

  const handleChangeMax = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChangeMax(convertStringToNumber(e.target.value.split(' ').join('')));
    },
    [onChangeMax],
  );

  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
  });

  return (
    <div className={cn(flat ? s['range-filter-flat'] : s['range-filter'], className)}>
      <div className={s.wrap__input}>
        {placeholderPostfix ? (
          <span className={s.prefix + ' ' + (valueMin !== null ? s.active : '')}>{placeholderPostfix}</span>
        ) : undefined}
        <MaskedInput
          mask={currencyMask}
          onChange={handleChangeMin}
          placeholder={placeholder?.from ? placeholder.from : placeholderSufix.from + '' + (placeholderTo || to)}
          value={valueMin ? formatNumberToString(valueMin, { minimumFractionDigits: 0 }) : undefined}
          className={cn(
            s['range__input__' + size],
            flat ? s['range-filter__input__flat'] : s['range-filter__input'],
            flat && s.border__right,
          )}
        />
      </div>

      {!seporatorHide && <Typography.Text className={s['range-filter__dash']}>–</Typography.Text>}
      <div className={s.wrap__input}>
        {placeholderPostfix ? (
          <span className={s.prefix + ' ' + (valueMax !== null ? s.active : '')}>{placeholderPostfix}</span>
        ) : undefined}
        <MaskedInput
          mask={currencyMask}
          onChange={handleChangeMax}
          placeholder={placeholder?.to ? placeholder.to : placeholderSufix.to + '' + (placeholderTo || to)}
          value={valueMax ? formatNumberToString(valueMax, { minimumFractionDigits: 0 }) : undefined}
          className={cn(s['range__input__' + size], flat ? s['range-filter__input__flat'] : s['range-filter__input'])}
        />
      </div>
    </div>
  );
};

export default React.memo(RangeFilterPrice);
