import { Button, Input, Typography, notification } from 'antd';
import React from 'react';
import { useProfileSettingsStore } from 'stores/ProfileSettingsStore/context';
import s from './FormPersonalData.module.scss';
import { ValueModel } from 'models/ValueModel';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import MaskedInput from 'react-text-mask';
import type { NotificationArgsProps } from 'antd';
import { WarningOutlined } from '@ant-design/icons';

type NotificationPlacement = NotificationArgsProps['placement'];

const key = 'updatable';

export const FormPersonalData = observer(() => {
  const { fieldsPresonalData, submitPersonalDataForm, submitPersonalDataStage } = useProfileSettingsStore();
  const { firstName, email, lastName, phone, patronymic, region, userType } = fieldsPresonalData;

  const [api, contextHolder] = notification.useNotification();

  const openNotification = (placement: NotificationPlacement, msg: string) => {
    api.info({
      message: `Ошибка!`,
      description: msg,
      placement,
      icon: <WarningOutlined style={{ color: 'red' }} />,
      key,
    });
  };

  const onSubmit = () => {
    if (!submitPersonalDataStage.isLoading)
      submitPersonalDataForm().then((res) => {
        if (res.isError) {
          openNotification('topRight', res.data);
        }
      });
  };

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const key = event.target.id;
      const fields = fieldsPresonalData as any;
      const field = fields[key] as ValueModel;
      field.change(event.target.value);

      if (fieldsPresonalData.isValidateOnChange) {
        field.validate();
      }
    },
    [firstName, email, lastName, phone, patronymic, fieldsPresonalData.isValidateOnChange],
  );
  const handlePhoneChange = React.useCallback(
    (e: any) => {
      phone.change(e.target.value);
    },
    [phone],
  );
  return (
    <div>
      {contextHolder}
      <Typography.Title level={2}>Персональные данные</Typography.Title>
      <form className={s.form}>
        <div className={s.form__control}>
          <span className={s.field__label}>
            Имя<span>*</span>
          </span>
          <Input
            className={cn(s['field__input'])}
            onChange={handleChange}
            placeholder="Имя"
            value={firstName.value}
            status={firstName.isError ? 'error' : undefined}
            id="firstName"
          />
          <Typography.Text type="danger">{firstName.error}</Typography.Text>
        </div>
        <div className={s.form__control}>
          <span className={s.field__label}>
            Фамилия<span>*</span>
          </span>
          <Input
            className={cn(s['field__input'])}
            onChange={handleChange}
            placeholder="Фамилия"
            value={lastName.value}
            status={lastName.isError ? 'error' : undefined}
            id="lastName"
          />
          <Typography.Text type="danger">{lastName.error}</Typography.Text>
        </div>
        <div className={s.form__control}>
          <span className={s.field__label}>Отчество</span>
          <Input
            className={cn(s['field__input'])}
            onChange={handleChange}
            placeholder="Отчество"
            value={patronymic.value}
            status={patronymic.isError ? 'error' : undefined}
            id="patronymic"
          />
          <Typography.Text type="danger">{patronymic.error}</Typography.Text>
        </div>
        <div className={s.form__control}>
          <span className={s.field__label}>
            Email<span>*</span>
          </span>
          <Input
            className={cn(s['field__input'])}
            onChange={handleChange}
            placeholder="E-mail"
            value={email.value}
            status={email.isError ? 'error' : undefined}
            id="email"
          />
          <Typography.Text type="danger">{email.error}</Typography.Text>
        </div>
        <div className={s.form__control}>
          <span className={s.field__label}>
            Телефон<span>*</span>
          </span>
          <div className={s.field__phone__wrap}>
            <div className={s.field__phone__icon}>+7</div>
            <MaskedInput
              mask={['(', /[1-9]/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
              onChange={handlePhoneChange}
              value={phone.value || ''}
              className={`${s.field__phone} ${phone.isError ? s.error : ''}`}
            />
          </div>
          <Typography.Text type="danger">{phone.error}</Typography.Text>
        </div>
        {/* <div className={s.form__control}>
          <span className={s.field__label}>Город</span>
          <Input placeholder="Город" value={region.value || ''} className={s.readonly} readOnly />
        </div> */}
        {/* <div className={s.form__control}>
          <span className={s.field__label}>Тип пользователя</span>
          <Input placeholder="Тип пользователя" value={userType.value || ''} className={s.readonly} readOnly />
        </div> */}
        {/* <div className={s.hideMobile}></div>
        <div className={s.hideMobile}></div>
        <div className={s.hideMobile}></div> */}
        <div className={s.form__control + ' ' + s.flex__end}>
          <Button type="primary" className={s.btn__send} onClick={onSubmit} loading={submitPersonalDataStage.isLoading}>
            Применить
          </Button>
        </div>
      </form>
    </div>
  );
});
