import { computed, makeObservable } from 'mobx';

import { apiUrls } from 'config/apiUrls';
import {
  LotCommonFiltersResponse,
  LotFiltersResponse,
  SortDirection,
  SortOption,
  SortOptionItem,
} from 'entities/lotFilters';
import { FilterModel } from 'models/FilterModel';
import { FilterModelMulti } from 'models/FilterModelMulti';
import { LoadingStageModel } from 'models/LoadingStageModel';
import { QueryParamsModel } from 'models/QueryParamsModel';
import { ValueModel } from 'models/ValueModel';
import { RouterStore } from 'stores/RootStore/RouterStore';
import { ILocalStore } from 'types/ILocalStore';
import { BaseResponse } from 'types/meta';
import { convertToArray } from 'utils/convertToArray';

import { DEFAULT_PAGINATION_PARAMS } from '../../types/pagination';

import { sortOptions as sortOptionsFull } from './config';
import type { LotCommonQueryParams, LotCommonQueryParamsRaw } from './types';
import { apiCustom } from 'utils/api';
const sortOptions = Object.values(sortOptionsFull).filter((item) => item.id !== SortOption.popularity);
type LotListCommonFiltersModelParams<Q extends LotCommonQueryParams> = {
  params: Q;
  routerStore: RouterStore;
};

export class LotListCommonFiltersModel<Q extends LotCommonQueryParams>
  extends QueryParamsModel<Q>
  implements ILocalStore
{
  readonly loadingStage: LoadingStageModel = new LoadingStageModel();

  readonly sortOption = new FilterModel<SortOptionItem, SortOption>(SortOption.new, Object.values(sortOptions));
  readonly sortDirection: ValueModel<SortDirection> = new ValueModel<SortDirection>(SortDirection.asc);
  readonly auctionType = new FilterModelMulti();
  readonly status = new FilterModelMulti();
  readonly formType = new FilterModelMulti();
  readonly objectType = new FilterModelMulti();
  readonly offsetModel: ValueModel<number> = new ValueModel<number>(DEFAULT_PAGINATION_PARAMS.offset);

  private filtersRequest = apiCustom;
  private abortController?: AbortController;

  constructor({ params, routerStore }: LotListCommonFiltersModelParams<Q>) {
    super({ params, routerStore });

    makeObservable(this, {
      offset: computed,
      limit: computed,
    });

    this.sortOption.selectedValue.change(params.order);
    this.sortDirection.change(params.order_direction);
    this.offsetModel.change(params.offset || 0);
  }

  get offset(): number {
    return this.offsetModel.value;
  }

  get limit(): number {
    return DEFAULT_PAGINATION_PARAMS.limit;
  }

  setFilterParam = <K extends keyof Q>(param: K, value: Q[K], replace: boolean = true): void => {
    // @ts-ignore
    const params: Partial<Q> = {
      [param]: value,
    };

    // Сбрасываем значение offset для полного обновления списка лотов
    if (replace) {
      this.resetOffset();
    }

    this.setParams(params);
  };

  // Методы для вызова из компонентов
  incrementPage = (): void => {
    this.offsetModel.change(this.offset + this.limit);
  };
  setPage = (page: number): void => {
    const offset = page * this.limit;
    this.offsetModel.change(offset);
    this.setParam('offset', offset);
  };

  toggleSortDirection = (): void => {
    const currentSortDirection = this.sortDirection.value;

    if (currentSortDirection === SortDirection.asc) {
      this.sortDirection.change(SortDirection.desc);
    } else {
      this.sortDirection.change(SortDirection.asc);
    }
  };

  setSortValue = (value: SortOption, direction?: SortDirection): void => {
    const sortDirection = this.sortDirection.value;
    const sortOption = this.sortOption.selectedValue.value;

    if (value !== this.sortOption.selectedValue.value) {
      this.sortOption.selectedValue.change(value);
      this.sortDirection.change(sortOptionsFull[value].defaultDirection);
    } else if (sortOptionsFull[value].withSortDirection && !direction) {
      this.toggleSortDirection();
    }

    if (direction) {
      this.sortDirection.change(direction);
    }

    if (sortDirection !== this.sortDirection.value || sortOption !== this.sortOption.selectedValue.value) {
      // @ts-ignore
      this.setParams({
        order: this.sortOption.selectedValue.value,
        order_direction: this.sortDirection.value,
      });
      this.resetOffset();
    }
  };

  resetSort = (): void => {
    this.sortDirection.change(sortOptionsFull[SortOption.new].defaultDirection);
    this.sortOption.selectedValue.change(SortOption.new);
  };

  resetOffset(key?: string) {
    this.offsetModel.change(DEFAULT_PAGINATION_PARAMS.offset);
  }

  protected async fetchFiltersOptions(
    params?: Record<string, string | null>,
  ): Promise<BaseResponse<LotFiltersResponse['filters']>> {
    let request = (this.abortController = new AbortController());
    if (this.loadingStage.isLoading && this.abortController) {
      this.abortController.abort();
    }

    this.loadingStage.loading();

    const response = await this.filtersRequest<LotFiltersResponse>({
      url: `${apiUrls.lotListFilters}`,
      method: 'GET',
      data: {
        ...params,
        aggregated_type: params?.aggregated_type || null,
      },
      config: {
        signal: request.signal,
      },
    });

    if (response.isError) {
      this.loadingStage.error();

      return { isError: true };
    }

    this.loadingStage.success();

    return {
      isError: false,
      data: response.data.filters,
    };
  }

  protected setFilterOptions(filters: LotCommonFiltersResponse['filters']): void {
    this.auctionType.setOptions(filters.auction_type, this.params.auction_type);
    this.formType.setOptions(filters.form_type, this.params.form_type);
    this.status.setOptions(filters.status, this.params.status);
    this.objectType.setOptions(filters.object_type, this.params.object_type);
  }

  static normalizeCommonQueryParams(paramsObj: Partial<LotCommonQueryParamsRaw>): LotCommonQueryParams {
    return {
      order: paramsObj.order ?? SortOption.new,
      order_direction: paramsObj.order_direction ?? SortDirection.desc,
      auction_type: paramsObj.auction_type ? convertToArray(paramsObj.auction_type) : [],
      status: paramsObj.status ? convertToArray(paramsObj.status) : [],
      form_type: paramsObj.form_type ? convertToArray(paramsObj.form_type) : [],
      object_type: paramsObj.object_type ? convertToArray(paramsObj.object_type) : [],
    };
  }
}
