import { IReactionDisposer, action, computed, makeObservable, observable, reaction } from 'mobx';
import qs from 'query-string';

import { RouterStore } from 'stores/RootStore/RouterStore';
import { ILocalStore } from 'types/ILocalStore';

export type QueryParamsModelParams<P> = {
  params: P;
  routerStore: RouterStore;
};
export class QueryParamsModel<P extends Record<string, any>> implements ILocalStore {
  readonly routerStore: RouterStore;
  private _params: P;
  private readonly _disposers: IReactionDisposer[] = [];

  constructor({ params, routerStore }: QueryParamsModelParams<P>) {
    type PrivateFields = '_params';

    makeObservable<this, PrivateFields>(this, {
      _params: observable.ref,
      params: computed,
      setParam: action,
    });

    this._params = params;
    this.routerStore = routerStore;

    this._disposers.push(
      reaction(
        () => this._params,
        () =>
          this.routerStore.navigate({
            pathname: '/lots',
            search: qs.stringify(this._params, { skipNull: true, skipEmptyString: true }),
          }),
      ),
    );
  }

  get params(): P {
    return this._params;
  }

  setParam = <K extends keyof P>(param: K, value: P[K]): void => {
    this._params = {
      ...this.params,
      [param]: value,
    };
  };

  setParams = (params: Partial<P>): void => {
    this._params = {
      ...this.params,
      ...params,
    };
  };

  destroy(): void {
    this._disposers.forEach((d) => d());
  }
}
