import * as React from 'react';
import { Outlet } from 'react-router-dom';

import AuthPopup from 'components/AuthPopup';
import EmailPopup from 'components/EmailPopup';
import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import { Onboarding } from 'components/Onboarding';
import { useScrollToTop } from 'hooks/useScrollTop';
import { WidthProvider } from 'hooks/useWidth';
import { RootStoreContextProvider } from 'stores/RootStore';

import s from './App.module.scss';
import { BugReport } from 'components/BugReport/BugReport';
import { CookiesContextProvider } from 'components/Cookies';
import { ModalTextProvider } from 'components/ModalText/ModalText.context';
import { RouteFullPath } from 'config/routes';
import { HeaderFlat } from 'components/HeaderFlat';
import { FooterLight } from 'components/FooterLight';

const App: React.FC = () => {
  useScrollToTop();
  const isNewLayout = window.location.pathname === RouteFullPath.home;
  return (
    <RootStoreContextProvider>
      <ModalTextProvider>
        <CookiesContextProvider>
          <WidthProvider>
            <div className={s.app__container}>
              {isNewLayout ? <HeaderFlat /> : <Header />}
              <Outlet />
              {isNewLayout ? <FooterLight /> : <Footer />}
            </div>
            {!isNewLayout && <BugReport />}
            <AuthPopup />
            <EmailPopup />
            <Onboarding />
          </WidthProvider>
        </CookiesContextProvider>
      </ModalTextProvider>
    </RootStoreContextProvider>
  );
};

export default App;
