import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { PageContainer } from 'components/PageContainer';
import s from './Home.module.scss';
import { TextInfo } from './TextInfo/TextInfo';
import { useNavigate } from 'react-router-dom';
import { RouteFullPath } from 'config/routes';
import textImgOne from './assets/text-image-one.png';
import textImgTwo from './assets/text-image-two.png';
import msgIng from './assets/msg.png';
import { Services } from './Services/Services';
import useToggleState from 'hooks/useToggleState';
import { SupportModal } from 'components/SupportModal';
import { MarqueeSection } from './Marquee/Marquee';
import { Faq } from './Faq/Faq';
import { Banner } from './Banner/Banner';
import { FirstScreen } from './FirstScreen/FirstScreen';
import { LotListStoreProvider } from 'stores/LotListCatalogStore/context';

type HomePageProps = {
  className?: string;
};

const HomePage = ({ className }: HomePageProps): React.ReactElement<HomePageProps> => {
  const navigation = useNavigate();
  const { open: openSupportModal, close: closeSupportModal, opened: openedSupportModal } = useToggleState();
  const gotoLots = () => navigation(RouteFullPath.lots);
  return (
    <div className={s.overflow}>
      <LotListStoreProvider>
        <FirstScreen />

        <PageContainer className={className + ' ' + s.padding__content}>
          <div className={s.section__text}>
            <TextInfo
              title="Широкий ассортимент"
              description="Агрегируем лоты с электронных торговых площадок.<br />Мы ежедневно добавляем и обновляем список объектов на нашем сайте"
              action={{
                label: 'Перейти в каталог',
                onClick: gotoLots,
              }}
              image={textImgOne}
              fixed
            />
            <TextInfo
              title="Умный поиск"
              description="Ваш персональный ассистент на нашем маркетплейсе.<br />Запоминает и предлагает подходящие объекты по сохраненным фильтрам,<br />экономя ваше время"
              image={textImgTwo}
              reverse
              fixed
            />
          </div>
        </PageContainer>
        <Services />
        <PageContainer className={s.padding + ' ' + s.padding__services__form}>
          <TextInfo
            title="Выбрали подходящую услугу?"
            description="Оставьте заявку, и наши специалисты свяжутся с вами в ближайшее время"
            action={{
              label: 'Оставить заявку',
              onClick: () => {
                openSupportModal();
              },
            }}
            image={msgIng}
            clxs={{
              colRight: s.supportColumn,
              btn: s.btn__support,
            }}
            fixed
          />
        </PageContainer>
        <MarqueeSection />
        <PageContainer withoutBottomPadding className={s.padding__faq}>
          <Faq />
          <div className={s.bannerWrap}>
            <Banner
              title="Лучшие предложения ждут вас"
              description="Найдите для себя самые актуальные и выгодные объекты.<br/>Удобный интерфейс и умные фильтры для легкого поиска"
              action={{
                label: 'Перейти в каталог',
                onClick: gotoLots,
              }}
              className={s.banner}
              loading={false}
            />
          </div>
        </PageContainer>
        <SupportModal opened={openedSupportModal} close={closeSupportModal} hideName />
      </LotListStoreProvider>
    </div>
  );
};

export default observer(HomePage);
