import * as React from 'react';
import { BaseIconProps } from '../commonProps';

const NotificationNewIcon = (props: BaseIconProps): React.ReactElement<BaseIconProps> => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M8.99981 18.6989C7.30456 18.6989 5.92535 17.3195 5.92535 15.624C5.92535 15.2444 6.23314 14.9365 6.61285 14.9365H11.3871C11.7668 14.9365 12.0746 15.2444 12.0746 15.624C12.0746 17.3195 10.6953 18.6989 8.99981 18.6989ZM7.44559 16.3116C7.71006 16.9073 8.30724 17.324 8.99981 17.324C9.69251 17.324 10.2898 16.9074 10.5543 16.3116H7.44559Z"
        fill="currentColor"
      />
      <path
        d="M16.7746 16.3115C16.7743 16.3115 16.774 16.3115 16.7737 16.3115H1.22632C0.846604 16.3115 0.538818 16.0037 0.538818 15.624C0.538818 14.1196 1.44473 12.7897 2.79812 12.2238V7.43888C2.79812 5.79941 3.43654 4.25812 4.5958 3.09891C5.75501 1.93966 7.29617 1.30127 8.93534 1.30127H9.06468C10.6801 1.30127 12.2618 1.9565 13.4042 3.09895C14.5635 4.25816 15.2019 5.79945 15.2019 7.43893V12.2238C16.5438 12.785 17.4458 14.097 17.461 15.5856C17.4617 15.5983 17.462 15.6112 17.462 15.6241C17.4621 16.0037 17.1542 16.3115 16.7746 16.3115ZM2.01772 14.9365H15.9823C15.7488 14.1839 15.14 13.585 14.3455 13.3836C14.0405 13.3063 13.8269 13.0318 13.8269 12.7172V7.43888C13.8269 6.16671 13.3315 4.97067 12.432 4.07121C11.5455 3.18468 10.3182 2.67627 9.06468 2.67627H8.93534C7.66338 2.67627 6.46756 3.17166 5.5681 4.07121C4.6685 4.97071 4.17312 6.16675 4.17312 7.43893V12.7172C4.17312 13.0319 3.95952 13.3064 3.65453 13.3837C2.85999 13.585 2.25125 14.1839 2.01772 14.9365Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default NotificationNewIcon;
