import type { MenuProps } from 'antd';
import { Dropdown } from 'antd';
import { ItemType } from 'antd/es/menu/interface';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Link, useMatch } from 'react-router-dom';

import { routerUrls } from 'config/routes';
import { AnalyticsEvent } from 'entities/analytics';
import { useRootStore } from 'stores/RootStore';

import { MENU_ITEMS } from '../HeaderMenu/config';

import s from './HeaderDropdown.module.scss';

type DropdownItemsProps = {
  isProfileRoute: boolean;
  onLogout: VoidFunction;
  onLogin: VoidFunction;
  isAuth: boolean;
  activeHref: string;
  onMenuClick: (e: React.MouseEvent, item: (typeof MENU_ITEMS)[number]) => void;
};

type ProfileMenuProps = {
  className?: string;
};

const getDropdownItems = (props: DropdownItemsProps): MenuProps['items'] => {
  const ITEMS_PROFILE: ItemType[] = [
    {
      key: '3',
      label: <Link to={routerUrls.favorites.create()}>Избранное</Link>,
    },
    {
      key: '4',
      label: <Link to={routerUrls.profileRobot.create()}>Умный поиск</Link>,
    },
    {
      key: '5',
      label: <Link to={routerUrls.profileNotification.create()}>Уведомления</Link>,
    },
    {
      key: '6',
      label: <Link to={routerUrls.profileSettings.create()}>Настройки</Link>,
    },
    {
      key: '7',
      label: <Link to={routerUrls.profileServices.create('escort')}>Мои услуги</Link>,
    },
  ];

  let menu: ItemType[] = MENU_ITEMS.map((item) => ({
    key: item.url,
    label: (
      <Link
        to={item.url}
        onClick={(e) => props.onMenuClick(e, item)}
        className={props.activeHref === item.url ? s.active : ''}
      >
        {item.title}
      </Link>
    ),
  }));

  if (props.isAuth) {
    menu = [...menu, ...ITEMS_PROFILE];
    menu.push({
      key: 'logout',
      label: (
        <button className={cn(s['profile-menu__button'], s['profile-menu__button_logout'])} onClick={props.onLogout}>
          <span className={cn(s['profile-menu__logout-text'])}>Выйти</span>
        </button>
      ),
    });
  } else {
    menu.push({
      key: 'login',
      label: (
        <button className={cn(s['profile-menu__button'], s['profile-menu__button_logout'])} onClick={props.onLogin}>
          <span className={cn(s['profile-menu__logout-text'])}>Войти</span>
        </button>
      ),
    });
  }

  return menu;
};

const HeaderDropdownMenu = ({ className }: ProfileMenuProps): React.ReactElement<ProfileMenuProps> => {
  const { authStore, analyticsStore, userStore } = useRootStore();
  const isProfileRoute = Boolean(useMatch(routerUrls.profile.mask));
  const activeHref = window.location.pathname;
  const handleLoginButtonClick = React.useCallback(() => {
    authStore.popupController.open();
    analyticsStore.trackEvent({ event: AnalyticsEvent.loginButtonClick });
  }, [authStore.popupController, analyticsStore]);

  const handleMenuClick = (e: React.MouseEvent, item: (typeof MENU_ITEMS)[number]) => {
    if (item.isAuthRequired && !userStore.authorized) {
      e.preventDefault();
      e.stopPropagation();
      authStore.popupController.open();
      authStore.mode.change('login');
    }
  };

  return (
    <Dropdown
      overlayClassName={cn(s['profile-menu'], className)}
      menu={{
        items: getDropdownItems({
          isAuth: userStore.authorized,
          isProfileRoute,
          onLogout: authStore.logout,
          onLogin: handleLoginButtonClick,
          activeHref,
          onMenuClick: handleMenuClick,
        }),
      }}
      trigger={['click']}
      placement="bottomRight"
      arrow={{ pointAtCenter: true }}
      aria-label="Меню"
    >
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" rx="16" fill="white" />
        <path
          d="M22.9998 9.71094H8.99979C8.92122 9.71094 8.85693 9.77522 8.85693 9.85379V10.9967C8.85693 11.0752 8.92122 11.1395 8.99979 11.1395H22.9998C23.0784 11.1395 23.1426 11.0752 23.1426 10.9967V9.85379C23.1426 9.77522 23.0784 9.71094 22.9998 9.71094ZM22.9998 20.8538H8.99979C8.92122 20.8538 8.85693 20.9181 8.85693 20.9967V22.1395C8.85693 22.2181 8.92122 22.2824 8.99979 22.2824H22.9998C23.0784 22.2824 23.1426 22.2181 23.1426 22.1395V20.9967C23.1426 20.9181 23.0784 20.8538 22.9998 20.8538ZM22.9998 15.2824H8.99979C8.92122 15.2824 8.85693 15.3467 8.85693 15.4252V16.5681C8.85693 16.6467 8.92122 16.7109 8.99979 16.7109H22.9998C23.0784 16.7109 23.1426 16.6467 23.1426 16.5681V15.4252C23.1426 15.3467 23.0784 15.2824 22.9998 15.2824Z"
          fill="#5482E0"
        />
      </svg>
    </Dropdown>
  );
};

export default observer(HeaderDropdownMenu);
