import React, { ReactNode, useState } from 'react';
import s from './Сollapsed.module.scss';
import { useCollapsed } from './Сollapsed.context';

export const Collapsed = ({
  children,
  className,
  defaultOpen,
  header,
}: {
  children: ReactNode;
  header: ReactNode;
  className?: string;
  defaultOpen?: boolean;
}) => {
  const { open, onToggle } = useCollapsed();
  const filtersDivRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (defaultOpen) {
      onToggle(true);
    }
  }, []);

  React.useEffect(() => {
    if (filtersDivRef && filtersDivRef.current) {
      const isOpen = filtersDivRef.current?.getAttribute('aria-hidden') || 'true';
      if (isOpen === 'false') {
        filtersDivRef.current?.setAttribute('aria-hidden', 'true');
      } else {
        filtersDivRef.current?.setAttribute('aria-hidden', 'false');
      }
    }
  }, [open]);

  return (
    <div className={`${s.wrapper} ${className}`}>
      <div className={s.row}>{header}</div>
      <div className={s.filters} aria-hidden={open} ref={filtersDivRef}>
        <div>{children}</div>
      </div>
    </div>
  );
};

export const CollapsedStatic = ({
  children,
  className,
  defaultOpen,
  title,
  header,
  icon,
}: {
  children: ReactNode;
  title?: string;
  icon?: ReactNode;
  className?: string;
  defaultOpen?: boolean;
  header?: ReactNode;
}) => {
  const [open, setOpen] = useState(false);

  React.useEffect(() => {
    if (defaultOpen) {
      setOpen(true);
    }
  }, []);

  return (
    <div className={`${s.wrapper} ${className}`}>
      <div className={s.row} data-open={open} onClick={() => setOpen((prev) => !prev)}>
        {header ? (
          header
        ) : (
          <div className={s.header}>
            <div className={`${s.title} ${icon ? s.iconTitle : ''}`}>{title}</div>
            {icon && <div className={s.icon}>{icon}</div>}
          </div>
        )}
      </div>
      <div className={s.filters + ' ' + s.border} aria-hidden={!open}>
        <div className={s.content}>{children}</div>
      </div>
    </div>
  );
};
