export enum RouteSegment {
  // "Каталог объектов"
  lots = 'lots',
  // "Личный кабинет"
  profile = 'profile',
  favorites = 'favorites',
  tariffs = 'tariffs',
  premium = 'premium',
  robots = 'robots',
  subscriptions = 'subscriptions',
  notification = 'notification',
  settings = 'settings',
  history = 'history',
  services = 'services',
  services_online = 'services/online',
  services_audit_legal = 'services/audit-legal',
  services_escort = 'services/escort',
  policy = 'privacy',
  agree = 'agree',
  terms_of_service = 'terms-of-service-and-tariffs',
  cookies = 'cookies',
  agreement = 'agreement',
  home = '/',
}

export enum RouteFullPath {
  home = '/',
  lots = 'lots',
  profile = 'profile',
  favorites = 'profile/favorites',
  robots = 'profile/robots',
  notification = 'profile/notification',
  settings = 'profile/settings',
  history = 'profile/history',
  premium = 'tariffs/premium',
  services = 'profile/services',
  services_online = 'services/online',
  services_audit_legal = 'services/audit-legal',
  services_escort = 'services/escort',
}

export enum ProfileListType {
  favorites = 'favorites',
  bids = 'bids',
  subs = 'subs',
}

export enum ProfileFavoriteListType {
  favorites = 'favorites',
  favorites_notif = 'favorites_notif',
  favorites_unnotif = 'favorites_unnotif',
}
