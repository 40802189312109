import React from 'react';
import Marquee from 'react-fast-marquee';
import s from './Marquee.module.scss';
import logo1 from '../assets/L1.png';
import logo2 from '../assets/L2.png';
import logo3 from '../assets/L3.png';
import logo4 from '../assets/L4.png';
import logo5 from '../assets/L5.png';
import { PageContainer } from 'components/PageContainer';

const LOGOS: string[] = [logo1, logo2, logo3, logo4, logo5, logo1, logo2, logo3, logo4, logo5];

export const MarqueeSection = () => {
  return (
    <section className={s.section}>
      <PageContainer withoutBottomPadding withoutTopPadding>
        <h2 className={s.title}>Наши партнеры</h2>
      </PageContainer>
      <div>
        <Marquee>
          {LOGOS.map((item, index) => (
            <img key={index} src={item} className={s.logo} loading="lazy" decoding="async" />
          ))}
        </Marquee>
      </div>
    </section>
  );
};
