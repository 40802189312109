import { Button, Typography } from 'antd';
import cn from 'classnames';
import React from 'react';

import { SupportModal } from 'components/SupportModal';
import useToggleState from 'hooks/useToggleState';

import s from './Questions.module.scss';

export const Questions = ({ className = '' }: { className?: string }) => {
  const { open: openSupportModal, close: closeSupportModal, opened: openedSupportModal } = useToggleState();
  return (
    <>
      <div className={cn(s.questions, className)}>
        <Typography.Title className={cn(s.questions__title)} level={3}>
          Остались вопросы?
        </Typography.Title>
        <Typography.Text className={cn(s.questions__desc)}>
          Оставьте конкаты и наш менеджер свяжется с вами, чтобы помочь подобрать подходящий пакет услуг
        </Typography.Text>
        <Button onClick={openSupportModal} className={s.questions__btn} type="primary">
          Оставить заявку
        </Button>
      </div>
      <SupportModal opened={openedSupportModal} close={closeSupportModal} />
    </>
  );
};
