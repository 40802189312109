import * as React from 'react';

import { ProfileServicesStore } from './ProfileServicesStore';
import { useRootStore } from 'stores/RootStore';

type ProfileServicesStoreContextValue = ProfileServicesStore;

const ProfileServicesStoreContext = React.createContext<ProfileServicesStoreContextValue | null>(null);

export const ProfileServicesStoreProvider = ({
  children,
  mount = true,
}: {
  children: React.ReactNode;
  mount?: boolean;
}) => {
  const rootStore = useRootStore();

  React.useEffect(() => {
    if (mount) {
      rootStore.profileService.init(10, false);
    }
  }, []);

  return (
    <ProfileServicesStoreContext.Provider value={rootStore.profileService}>
      {children}
    </ProfileServicesStoreContext.Provider>
  );
};

export const useProfileServicesStore = () => {
  const context = React.useContext(ProfileServicesStoreContext);

  if (!context) {
    throw new Error('LotListProfileStoreContext was not provided');
  }

  return context;
};
