import { Typography } from 'antd';
import { PageContainer } from 'components/PageContainer';
import { observer } from 'mobx-react-lite';
import React from 'react';
import s from '../StaticPages.module.scss';

const AgreementPage = observer(() => {
  return (
    <PageContainer>
      <Typography.Title level={1}>ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ</Typography.Title>

      <Typography.Title level={3}>1. Предмет и общие положения Соглашения</Typography.Title>
      <div className={s.text__container}>
        <Typography.Text>
          1.1. Предметом настоящего Пользовательского соглашения (далее — «Соглашение») являются отношения между
          Обществом с ограниченной ответственностью «КупиЛот», юридическим лицом, зарегистрированным и действующим по
          законодательству Российской Федерации, являющимся владельцем и администратором (далее — «Администратор») сайта
          www.kupilot.ru (далее – «Сайт/Платформа»), и Вами (далее — «Пользователь»), относительно использования портала
          «КУПИЛОТ, в том числе Контента, доступ к просмотру которого предоставляется посредством Сайта/Платформы, а
          также иных услуг, предоставляемых Администратором.
        </Typography.Text>
        <Typography.Text>
          1.2. Пользователем признается любое физическое/юридическое лицо, которое использует Сайт на платной или
          бесплатной основе вне зависимости от факта регистрации в Портале.
        </Typography.Text>
        <Typography.Text>
          1.3. Сайт предоставляет Пользователям доступ к просмотру и/или прослушиванию Контента, а также возможность
          пользования другими услугами Сайта.
        </Typography.Text>
        <Typography.Text>
          1.3.1. Контентом являются, графические, текстовые, фото, видео и иные материалы, доступ к просмотру которых
          предоставляется Пользователям посредством Платформы.
        </Typography.Text>
      </div>

      <Typography.Title level={3}>2. Права и обязанности, гарантии Пользователя</Typography.Title>
      <div className={s.text__container}>
        <Typography.Text>
          2.1. Использование Пользователем Сайта, любых его услуг, просмотр и/или прослушивание Контента означает
          безоговорочное согласие Пользователя со всеми пунктами настоящего Соглашения, со всеми его изменениями и
          дополнениями, и безоговорочное принятие его условий. В случае несогласия Пользователя с каким-либо из условий
          настоящего Соглашения, Пользователь обязан отказаться от дальнейшего использования Сайта.
        </Typography.Text>
        <Typography.Text>
          2.2. Пользователь обязуется использовать Сайт только в личных некоммерческих целях, соблюдать условия
          настоящего Соглашения, не нарушать права и законные интересы Администратора и/или правообладателей Контента.
        </Typography.Text>
        <Typography.Text>
          2.3. Пользователь обязуется ознакомиться с условиями настоящего Соглашения и самостоятельно отслеживать их
          изменения. Продолжение использования Пользователем Сайта после любых изменений и/или дополнений Соглашения
          подразумевает согласие Пользователя с такими изменениями и/или дополнениями. Незнание Пользователем
          действующих условий Соглашения не освобождает Пользователя от обязательств, предусмотренных в Соглашении, а
          также ответственности за их невыполнение и/или ненадлежащее выполнение.
        </Typography.Text>
        <Typography.Text>
          2.4. Пользователь настоящим подтверждает, что достиг 18-летного возраста либо иного возраста, установленного в
          качестве минимально разрешенного в Российской Федерации для просмотра и использования соответствующего
          Контента и/или для возможности совершения оплаты за просмотр и использование Контента в случаях,
          предусмотренных Администратором. Пользователь, не достигший требуемого возраста, обязуется воздержаться от
          доступа к просмотру и использованию такого Контента и/или от совершения оплаты за его просмотр и использование
          без согласия родителей, усыновителей, опекунов, попечителей или других законных представителей согласно
          действующего законодательства Российской Федерации. В ином случае, ответственность за нарушения условий
          настоящего пункта Соглашения Пользователем, не достигшим требуемого возраста, возлагается на родителей,
          усыновителей, опекунов, попечителей или других законных представителей согласно действующего законодательства
          Российской Федерации. Администратор не несет ответственности за законность просмотра/использования Контента
          Пользователем.
        </Typography.Text>
        <Typography.Text>
          2.5. Пользователь гарантирует, что при использовании Сайта не осуществляет и не будет осуществлять каких-либо
          действий, направленных на обход технических средств защиты от несанкционированного использования Сайта,
          просмотра/прослушивания, копирования Контента, в частности, системы территориального ограничения доступа к
          просмотру Контента по IP-адресам, а также любых других действий, направленных на изменение функциональных
          характеристик, дестабилизацию работы Сайта.
        </Typography.Text>
        <Typography.Text>
          2.6. Пользователь настоящим дает свое согласие на получение информационных, в том числе рекламных материалов,
          push-уведомлений от Администратора любым незапрещенным действующим законодательством, применимым к Соглашению,
          способом, в частности в интерфейсе Сайта, в любой период времени, а также по адресу электронной почты, номеру
          телефона Пользователя или иным способом. Пользователь вправе отказаться от получения такой информации,
          следующим путем: посредством самостоятельного направления Администратору электронного письма на адрес
          электронной почты Администратора support@kupilot.ru; отключения получений push-уведомлений в настройках
          мобильных устройств; блокировки получения сообщений в приложениях-мессенджерах.
        </Typography.Text>
        <Typography.Text>
          2.7. Пользователь настоящим дает свое согласие Администратору использовать телефонный номер (номера)
          Пользователя, используемые им для коммуникации с Администратором и/или совершения платежных операций на
          Платформе, для звонков, направления информационных сообщений, любым, незапрещенным действующим
          законодательством Российской Федерации, способом. Пользователь может отказаться от дальнейшего использования
          его номера для коммуникации с ним, позвонив и сообщив об этом в службу поддержки Администратора.
        </Typography.Text>
        <Typography.Text>
          2.8. Пользователь до момента расчетов (оплаты Пользователем услуг платного доступа к просмотру Контента)
          обязуется предоставить Администратору информацию об абонентском номере или адресе электронной почты для
          направления информации об оплате. На абонентский номер или электронную почту Пользователя Администратор
          направляет информацию об оплате Пользователем услуг платного доступа к просмотру Контента. Пользователь
          самостоятельно отвечает за корректность предоставленных данных об абонентском номере или адресе электронной
          почты. Администратор не несет ответственности за достоверность информации, указанной Пользователем.
        </Typography.Text>
      </div>

      <Typography.Title level={3}> </Typography.Title>
      <div className={s.text__container}>
        <Typography.Text></Typography.Text>
        <Typography.Text></Typography.Text>
        <Typography.Text></Typography.Text>
      </div>

      <Typography.Title level={3}>3. Права, обязанности и гарантии Администратора</Typography.Title>
      <div className={s.text__container}>
        <Typography.Text>
          3.1. Администратор обязуется открыть Пользователю доступ к Платформе в порядке и на условиях, предусмотренных
          настоящим Соглашением.
        </Typography.Text>
        <Typography.Text>
          3.2. Администратор имеет право по своему усмотрению и без специального уведомления Пользователя ограничить
          доступ Пользователя к Сайту, определенным единицам Контента, в том числе посредством системы территориального
          ограничения доступа к просмотру Контента по IP-адресам Пользователей, изменять перечень и иные характеристики
          Контента, другой информации, размещенной на Портале, а также функциональные параметры Портала. Предоставляя
          согласие с данным пунктом, Пользователь освобождает Администратора от любых видов возмещений и компенсаций за
          реализацию Администратором прав по данному пункту Соглашения.
        </Typography.Text>
        <Typography.Text>
          3.3. Администратор имеет право применять любые действия, не противоречащие действующему законодательству,
          применимому к настоящему Соглашению, с целью недопущения несанкционированного доступа к Платформе,
          размещенному на ней Контенту, дестабилизации работы Сайта и других действий, нарушающих права и законные
          интересы Администратора и/или правообладателей Контента.
        </Typography.Text>
        <Typography.Text>
          3.4. Администратор предоставляет Пользователю доступ к Контенту для легального некоммерческого индивидуального
          использования способами, указанными в настоящем Пользовательском соглашении. Передача третьим лицам доступа к
          просмотру Контента, в том числе для группового просмотра и/или иного распространения, является грубым
          нарушением Пользовательского соглашения. Администратор оставляет за собой право без предупреждения блокировать
          профиль Пользователя и/или Пользователей, нарушающего (-их) условия использования и/или блокировать прием
          оплаты платежными картами Пользователя и/или Пользователей, используемые для приобретения доступа к Контенту
          на Платформе, деактивировать услуги или применять другие методы, с целью предотвращения неавторизированной
          передачи доступа к Контенту Портала другим Пользователям или третьим лицам.
        </Typography.Text>
      </div>

      <Typography.Title level={3}>4. Ответственность Сторон. Ограничение ответственности</Typography.Title>
      <div className={s.text__container}>
        <Typography.Text>
          4.1. В случае невыполнения и/или ненадлежащего выполнения условий настоящего Соглашения, Сторона, допустившая
          нарушение, несет ответственность в соответствии с действующим законодательством, применимым к Соглашению.
        </Typography.Text>
        <Typography.Text>
          4.2. Администратор имеет право в любое время по своему усмотрению приостанавливать, ограничивать или
          прекращать доступ Пользователя к Платформе либо к ее отдельным услугам, в том числе, но не исключительно, в
          случае нарушения Пользователем условий настоящего Соглашения, действующего законодательства, применимого к
          настоящему Соглашению, а также в случае, если у Администратора есть основания считать действия Пользователя
          недобросовестными, направленными на нарушение работоспособности Портала и/или такими, которые могут привести к
          нарушению прав, законных интересов Администратора и/или правообладателей Контента, причинить вред их деловой
          репутации и тому подобное. Администратор не несет ответственности за любой ущерб, который может быть причинен
          Пользователю такими действиями.
        </Typography.Text>
        <Typography.Text>4.3. Пользователь понимает и соглашается с тем, что:</Typography.Text>
        <Typography.Text>
          4.3.1. Доступ к Порталу, в том числе к Контенту, другим услугам, предоставляется "в том виде, как есть", и
          Администратор не гарантирует их соответствие ожиданиям Пользователя.
        </Typography.Text>
        <Typography.Text>
          4.3.2. Администратор не несет перед Пользователем ответственность за содержание Контента или другой
          информации, размещенной на Портале Администратором или третьими лицами. Ответственность за содержание Контента
          полностью несут его правообладатели.
        </Typography.Text>
        <Typography.Text>
          4.3.3. Администратор не несет ответственности за содержание, достоверность и точность рекламной информации,
          размещенной на Портале, и за качество рекламируемых товаров/услуг.
        </Typography.Text>
        <Typography.Text>
          4.3.4. Администратор не несет ответственности за любые технические неполадки, задержку в обработке или
          передаче данных, задержку поступления платежей за услуги платного доступа к просмотру Контента, неправомерный
          доступ третьих лиц к просмотру Контента, сохранность логина и пароля Пользователя. Администратор не
          гарантирует безошибочную и бесперебойную работу Платформы и по умолчанию не несет ответственности за вред,
          причиненный Пользователю техническими сбоями аппаратного или программного обеспечения на любой стороне.
        </Typography.Text>
        <Typography.Text>
          4.3.5. Администратор не несет ответственность перед Пользователем или любыми третьими лицами за любой прямой,
          косвенный, неумышленный ущерб, включая упущенную выгоду, ущерб чести, достоинству или деловой репутации,
          которые возникли в связи с использованием Портала, в том числе просмотра Контента или использованием других
          услуг, предоставляемых посредством Платформы. В любом случае, при оплате Пользователем услуг платного доступа
          к просмотру и использованию Контента, Стороны принимают и соглашаются с тем, что сумма возможной компенсации
          Администратором убытков Пользователей за любые нарушения, связанные с использованием Сайта или настоящим
          Соглашением, ограничена суммой такой оплаты, внесенной Пользователем за соответствующую услугу. В случае
          наличия у Пользователя документально подтвержденных убытков в связи с данным Соглашением без оплат платного
          доступа к просмотру Контента, Стороны принимают и соглашаются с тем, что сумма возможной компенсации
          Администратором может быть не более 1000 Российских рублей.
        </Typography.Text>
        <Typography.Text>
          4.3.6. В случае предъявления к Администратору и/или третьему лицу каких-либо претензий, исков, а также любых
          иных требований, имеющих отношение к использованию Платформы Пользователем или третьим лицом с учетной записи
          Пользователя, Пользователь обязан урегулировать вышеуказанные претензии/иски/требования своими силами и за
          свой счет и компенсировать в полном объеме все расходы и убытки, понесенные Администратором.
        </Typography.Text>
        <Typography.Text>
          4.3.7. Администратор не несет никакой ответственности за доступность и содержание веб-сайтов третьих лиц в
          сети Интернет, переход к которым осуществляется посредством гиперссылок, размещенных в интерфейсе Платформы, а
          также за любые последствия, связанные с использованием таких веб-сайтов.
        </Typography.Text>
        <Typography.Text>
          4.3.8. Контент размещается на Портале на русском языке. Пользователь принимает и соглашается на просмотр и/или
          использование Контента в той языковой версии, которая доступна на Платформе.
        </Typography.Text>
      </div>

      <Typography.Title level={3}>5. Регистрация Пользователя</Typography.Title>
      <div className={s.text__container}>
        <Typography.Text>
          5.1. Для оплаты Разового доступа, Доступа по подписке, иных платных доступов, Пользователь обязан
          зарегистрироваться на Портале, создав свою учетную запись.
        </Typography.Text>
        <Typography.Text>
          5.2. Регистрация осуществляется одним из следующих способов: путем ввода Пользователем адреса электронной
          почты (логина) и пароля на Платформе, либо посредством использования телефонного номера Пользователя.
        </Typography.Text>
        <Typography.Text>
          5.3. Последующий вход в зарегистрированную учетную запись Пользователя осуществляется путем его авторизации.
        </Typography.Text>
        <Typography.Text>
          5.4. Пользователь несет ответственность и самостоятельно принимает меры для обеспечения безопасности своей
          учетной записи на Портале, включая контроль за адресом электронной почты либо телефонным номером (логином),
          указанным в учетной записи, сохранностью логина и пароля Пользователя. Пользователь несет полную
          ответственность за все действия, совершенные с использованием учетной записи Пользователя. Пользователь
          обязуется незамедлительно уведомить Администратора о любых случаях несанкционированного использования учетной
          записи Пользователя третьими лицами.
        </Typography.Text>
        <Typography.Text>
          5.5. Пользователь обязуется без предварительного письменного согласия Администратора не продавать, иным
          образом отчуждать или передавать в пользование и тому подобное третьим лицам учетную запись на Платформе и/или
          данные, позволяющие иметь к ней доступ.
        </Typography.Text>
        <Typography.Text>
          5.6. В случае утери информации и/или невозможности войти в свою учетную запись, Пользователь может ее
          возобновить, путем ввода своего адреса электронной почты (логина), на который будет автоматически отправлено
          письмо с ссылкой для восстановления пароля.
        </Typography.Text>
      </div>

      <Typography.Title level={3}>6. Предоставление доступа к просмотру Контента</Typography.Title>
      <div className={s.text__container}>
        <Typography.Text>
          6.1. Администратор оказывает Пользователю услуги доступа к просмотру Контента на следующих условиях:
        </Typography.Text>
        <Typography.Text>
          6.1.1. Бесплатный доступ к просмотру Контента - означает предоставление Администратором Пользователю
          возможности доступа к просмотру Контента на бесплатной основе.
        </Typography.Text>
        <Typography.Text>
          6.1.2. Бесплатный доступ к просмотру Контента, совмещенному с рекламными материалами – означает предоставление
          Администратором Пользователю возможности доступа к просмотру Контента на бесплатной основе, но при условии
          совмещения показа Контента с рекламными материалами.
        </Typography.Text>
        <Typography.Text>
          6.1.3. Разовый доступ к просмотру Контента – означает предоставление Администратором Пользователю возможности
          доступа к просмотру выбранной таким Пользователем единице Контента, за разовую плату, после отражения факта
          оплаты такой услуги в электронной системе учета платежей Администратора или после срока указанного в условиях
          получения доступа.
        </Typography.Text>
        <Typography.Text>
          6.1.4. Доступ по подписке* – означает предоставление Администратором Пользователю в соответствии с условиями
          предоставления услуг и тарификации определенному Администратором перечню единиц Контента, в течение срока
          действия Доступа по подписке в соответствии с правилами, ограничениями и техническими требованиями, указанными
          в настоящем Соглашении. При этом Администратор имеет право предоставлять в рамках Платформы несколько
          разновидностей (типов) Доступа по подписке. Начало срока действия Доступа по подписке исчисляется с момента
          его оплаты Пользователем и отражения факта оплаты такой услуги в электронной системе учета платежей
          Администратора. С более детальной информацией о типах Доступа по подписке можно ознакомиться в интерфейсе
          Сайта.
        </Typography.Text>
        <Typography.Text>
          6.2. Услуги платного доступа к просмотру Контента предоставляются Пользователю в порядке и на условиях,
          указанных ниже:
        </Typography.Text>
        <Typography.Text>
          6.2.1. Платный доступ к просмотру Контента может предоставляться Пользователю, при условии внесения таким
          Пользователем соответствующей платы за платный доступ к просмотру Контента, если иное не предусмотрено
          условиями акции и тому подобному. Администратор имеет право предусмотреть требование о регистрации
          Пользователя на Портале для внесения оплаты за доступ к просмотру Контента.
        </Typography.Text>
        <Typography.Text>
          6.2.2. Платный доступ к просмотру Контента может иметь различную стоимость и может предоставлять доступ к
          просмотру различных видов платного доступа к Контенту, которые могут предлагаться на усмотрение Администратора
          Пользователю как по несколько сразу, так и по одному. Стоимость платного доступа к просмотру Контента, способы
          и другие условия оплаты указаны в Условиях предоставления услуг и тарификации.
        </Typography.Text>
        <Typography.Text>
          6.2.3. Администратором могут быть установлены ограничения доступа к просмотру оплаченного Контента
          одновременно с нескольких Устройств.
        </Typography.Text>
        <Typography.Text>
          6.2.4. В случае невозможности предоставления доступа по вине Администратора к выбранному и оплаченному
          Пользователем платного доступа к просмотру Контента в течение более, чем одни сутки, но менее, чем 30
          (тридцать) календарных дней с даты оплаты соответствующего Контента, Администратор по письменному запросу
          Пользователя продлевает Пользователю срок доступа к просмотру такого Контента на соответствующий период (при
          наличии такой возможности) или возвращает Пользователю оплаченную им сумму. Пользователь направляет запрос по
          электронному адресу Администратора, указанному в п. 10.7. настоящего Соглашения.
        </Typography.Text>
        <Typography.Text>
          6.2.5. Стоимость платного доступа к просмотру Контента и перечень Контента, доступного к просмотру
          Пользователем посредством такого доступа, могут быть изменены Администратором в любой момент по своему
          усмотрению без какого-либо уведомления об этом Пользователя.
        </Typography.Text>
        <Typography.Text>
          6.2.6 Оплачивая платный доступ к просмотру Контента, Пользователь уведомлен и соглашается с тем, что:
        </Typography.Text>
        <Typography.Text>
          6.2.6.1. Пользователь и Администратор по условиям настоящего Соглашения подтверждают и соглашаются, что Услуги
          по предоставлению платного доступа к просмотру Контента считаются оказанными в момент их оплаты Пользователем.
        </Typography.Text>
        <Typography.Text>
          6.2.6.2. Услуга считается оказанной Администратором надлежащим образом и в полном объеме, вне зависимости от
          факта использования Пользователем предоставленного доступа на Портале, кроме случаев, предусмотренных п.
          6.2.4. настоящего Соглашения.
        </Typography.Text>
        <Typography.Text>
          6.2.6.3. По истечении срока оплаченного доступа к просмотру Контента, такой Контент становится недоступным для
          Пользователя.
        </Typography.Text>
        <Typography.Text>
          6.2.6.4. Оплата платного доступа (за исключением Разового доступа) может осуществляться без непосредственного
          участия Пользователя, но с его предварительного согласия на постоянной основе посредством автоматического
          списания денежных средств с банковского счета или счета в электронной платежной системе Пользователя и тому
          подобное, согласно правилам и условиям банка и/или платежной системы, при условии наличия денежных средств на
          таком счете. Данный пункт является согласием Пользователя на договорное списание его обслуживающим банком с
          его счета при выборе опции «автоматическое продление» в своей учетной записи и/или при оформлении любого из
          видов платного доступа (за исключением Разового доступа). Автоматическое списание денежных средств за платные
          виды доступа к просмотру Контента, кроме Разового доступа, происходит систематически, в соответствии с
          выбранной Пользователем длительностью доступа, независимо от срока окончания действия предыдущего доступа
          Пользователя, до начала оказания услуги по любому из видов платного доступа (за исключением Разового доступа)
          в размере стоимости такого доступа. В случае неудачной попытки списания средств со счета Пользователя на
          момент осуществления автоматического списания, автоматическое списание будет осуществляться до момента
          успешного списания средств. Пользователь может в любой момент отменить автоматическое списание в разделе
          «Управление подпиской» профиле Пользователя на сайте www.kupilot.ru. Следующий срок платного доступа к
          просмотру Контента равен 30 (тридцати) календарным дням с соответствующей стоимостью такого доступа за 30
          (тридцать) календарных дней в момент автоматического продления.
        </Typography.Text>
        <Typography.Text>
          6.2.6.5. В случае изменения стоимости платного доступа, Администратор уведомляет об этом Пользователя,
          оплатившего такой доступ, путем отображения соответствующего изменения в интерфейсе Платформы.
        </Typography.Text>
        <Typography.Text>
          6.2.7. Настоящим Пользователь подтверждает и соглашается, что, в день, когда Контент впервые становится
          доступным на Платформе услуга по предоставлению доступа к такому Контенту считается оказанной Администратором
          в полном объеме.
        </Typography.Text>
      </div>

      <Typography.Title level={3}>7. Технические требования</Typography.Title>
      <div className={s.text__container}>
        <Typography.Text>
          7.1. Доступ к Порталу предоставляется с учетом технических требований, предусмотренных в настоящем Соглашении
          и в интерфейсе Платформы.
        </Typography.Text>
        <Typography.Text>
          7.2. Пользователь подтверждает и соглашается, что доступ к Платформе предоставляется Пользователю только
          посредством Устройств. Услуги могут быть недоступны (иметь ограниченную функциональность) при использовании
          Устройств, не отвечающих техническим требованиям для использования Платформы, а также в случае наличия других
          ограничений, предусмотренных Администратором.
        </Typography.Text>
        <Typography.Text>
          7.3. Администратор устанавливает технические средства защиты Контента от несанкционированного доступа и/или
          иных действий, запрещенных согласно условиям настоящего Соглашения или действующего законодательства,
          применимому к Соглашению.
        </Typography.Text>
        <Typography.Text>
          7.4. Для доступа к просмотру Контента на Платформе посредством Устройства необходимо, чтобы у Пользователя
          было установлено соответствующее программное обеспечение, позволяющее воспроизводить Контент.
        </Typography.Text>
        <Typography.Text>
          7.5. Качество просмотра Контента зависит от скорости работы сети Интернет и других критериев, в т. ч. модели
          используемого Устройства, о которых Администратор уведомляет Пользователя.
        </Typography.Text>
        <Typography.Text>
          7.6. Администратор не несет ответственности за любой ущерб оборудованию или программному обеспечению
          Пользователя или иного лица, вызванный или связанный с использованием Платформы.
        </Typography.Text>
      </div>

      <Typography.Title level={3}>8. Учетная запись (профиль) Пользователя</Typography.Title>
      <div className={s.text__container}>
        <Typography.Text>
          8.1. После регистрации на Платформе в порядке, предусмотренном разделом 5 настоящего Соглашения, Пользователь
          получает доступ к своей учетной записи.
        </Typography.Text>
        <Typography.Text>
          8.2. Под учетной записью в рамках данного Соглашения подразумевается учетная запись Пользователя на Платформе,
          которая включает в себя сведения, необходимые для идентификации Пользователя, и информацию для авторизации.
        </Typography.Text>
        <Typography.Text>8.3. С помощью учетной записи Пользователь может:</Typography.Text>
        <Typography.Text>
          8.3.1 Заполнить свой профиль с информацией о себе, вносить в него изменения, при изменении собственных данных;
        </Typography.Text>
        <Typography.Text>
          8.3.2. Контролировать действие своих доступов, срок платежей, а также историю оплат и просмотра Контента.
        </Typography.Text>
        <Typography.Text>8.3.3. Выполнять другие действия, описанные в данном Соглашении.</Typography.Text>
      </div>

      <Typography.Title level={3}>9. Интеллектуальная собственность</Typography.Title>
      <div className={s.text__container}>
        <Typography.Text>
          9.1. Все права интеллектуальной собственности на использование Платформы принадлежат Администратору, а на
          Контент, размещенный на Платформе - его законным правообладателям. Платформа и Контент, как объекты
          интеллектуальной собственности, подлежат правовой охране согласно действующего законодательства Российской
          Федерации и международно-правовым нормам.
        </Typography.Text>
        <Typography.Text>
          9.2. Пользователь не получает прав интеллектуальной собственности на Контент, в частности не имеет права на
          воспроизведение единиц Контента, продажу, иное отчуждение, публичный показ, публичное исполнение, доведение до
          всеобщего сведения и тому подобное. За нарушение прав интеллектуальной собственности Администратора,
          правообладателей Контента или третьих лиц Пользователь несет ответственность в соответствии с действующим
          законодательством Российской Федерации и международно-правовыми нормами.
        </Typography.Text>
        <Typography.Text>
          9.3. Любые действия, направленные на обход технических средств защиты согласно данному Соглашению, с целью
          получения доступа к просмотру такого Контента, являются нарушением авторского права, и Пользователь
          самостоятельно несет юридическую и иную ответственность, предусмотренную применимым действующим
          законодательством Российской Федерации за такие действия.
        </Typography.Text>
        <Typography.Text>
          9.4. В случае, если Контент, или другая информация, предоставленная на Платформе, будет доводиться публично,
          организаторы такого публичного доведения несут ответственность и самостоятельно разрешают претензии
          правообладателей и/или третьих лиц, связанных с таким использованием.
        </Typography.Text>
      </div>

      <Typography.Title level={3}>10. Дополнительные условия</Typography.Title>
      <div className={s.text__container}>
        <Typography.Text>
          10.1. Настоящее Соглашение находится на Платформе в свободном доступе для Пользователя. Действующая редакция
          Соглашения доступна по адресу support@kupilot.ru
        </Typography.Text>
        <Typography.Text>
          10.2. К настоящему Соглашению применяется действующее законодательство Российской Федерации. Вопросы, не
          урегулированные Соглашением, подлежат разрешению в соответствии с законодательством Российской Федерации.
        </Typography.Text>
        <Typography.Text>
          10.3. Настоящее Соглашение заключается на неопределенный срок и распространяет свое действие на Пользователей
          с момента осуществления факта использования Платформы.
        </Typography.Text>
        <Typography.Text>
          10.4. Все споры, разногласия, требования и претензии по настоящему Соглашению должны решаться путем
          переговоров. В случае недостижения согласия между Сторонами путем переговоров, спор подлежит разрешению в
          судебном порядке по местонахождению Администратора согласно действующему законодательству, применимому к
          Соглашению.
        </Typography.Text>
        <Typography.Text>
          10.5. Недействительность (ничтожность) или несоответствие применимому действующему законодательству
          какого-либо из условий настоящего Соглашения не влияет на действительность его остальных условий. Если
          какое-либо из условий Соглашения будет признано недействительным (ничтожным), Администратор обязуется
          незамедлительно заменить его иным условием, которое будет в наибольшей мере соответствовать содержанию
          заменяемого условия, но при этом являться действительным.
        </Typography.Text>
        <Typography.Text>
          10.6. Заголовки в Соглашении приводятся для удобства и не влияют на толкование содержания статей Соглашения.
        </Typography.Text>
        <Typography.Text>
          10.7. В случае каких-либо вопросов, уведомлений, предложений, жалоб относительно Платформы Пользователь может
          обратиться к Администратору путем отправки соответствующего письма по почтовому адресу: 125504, г. Москва, ш.
          Дмитровское, д. 71Б, помещ. 16/4, или по электронному адресу: support@kupilot.ru, или по тел.: +7
          495-773-33-73.
        </Typography.Text>
        <Typography.Text>
          10.8. В случае подачи Пользователем, в том числе, если Пользователь является собственником авторского права,
          которое нарушено, жалобы в отношении Контента, размещенного на Платформе, Пользователь обязан предоставлять
          документы, которые подтверждают основания для жалобы, четкую аргументацию и контактные данные для связи.
        </Typography.Text>
      </div>
    </PageContainer>
  );
});

export default AgreementPage;
