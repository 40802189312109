import { TariffPlanType, TariffPricingBillingCycle } from 'entities/tariffs';
import { TariffModel } from 'models/TariffModel';

export const TARIFFS_MOCK = [
  // new TariffModel({
  //     "id": 5,
  //     "pricing_options": [
  //         {
  //             "id": 9,
  //             "price": "0.00",
  //             "billing_cycle": TariffPricingBillingCycle.free
  //         },
  //         {
  //             "id": 10,
  //             "price": "0.00",
  //             "billing_cycle": TariffPricingBillingCycle.free
  //         }
  //     ],
  //     "name": "Бесплатно",
  //     "plan_type": TariffPlanType.personal,
  //     "active": true,
  //     "ordering": 1,
  //     "access_to_objects": true,
  //     "access_to_source": false,
  //     "robot_assistant": false,
  //     "support_service": false,
  //     "is_trial": false,
  //     "is_free": false,
  //     "number_of_devices": 2,
  //     "additional_services": [],
  //     "image": null,
  //     "price_text": "",
  //     "description": "Оптимальный пакет услуг с расширенным функционалом для частных лиц",
  //     static_advantages: [
  //         'Просмотр адреса объекта',
  //         'Юридический аудит 10 000 ₽',
  //         'Онлайн-показ 1 000 ₽',
  //         'Поддержка с 2-х устройств'
  //     ]
  // }),
  new TariffModel({
    id: 3,
    pricing_options: [
      {
        id: 5,
        price: '0',
        billing_cycle: TariffPricingBillingCycle.month,
      },
      {
        id: 6,
        price: '0',
        billing_cycle: TariffPricingBillingCycle.year,
      },
    ],
    name: 'Базовый',
    plan_type: TariffPlanType.personal,
    active: true,
    ordering: 2,
    access_to_objects: true,
    access_to_source: true,
    robot_assistant: false,
    support_service: false,
    is_trial: false,
    is_free: false,
    number_of_devices: 2,
    additional_services: [
      {
        id: 16,
        cost: '0',
        free_uses: 0,
        service: 2,
      },
      {
        id: 17,
        cost: '0',
        free_uses: 0,
        service: 1,
      },
      {
        id: 18,
        cost: '0',
        free_uses: 0,
        service: 3,
      },
    ],
    image: null,
    price_text: '',
    description: 'Оптимальный пакет услуг с расширенным функционалом для частных лиц',
    static_advantages: [
      'Просмотр адреса объекта',
      'Юридический аудит 20 000 ₽',
      'Онлайн-показ 5 000 ₽',
      'Поддержка с 2-х устройств',
    ],
  }),
  new TariffModel({
    id: 2,
    pricing_options: [
      {
        id: 3,
        price: '1000.00',
        billing_cycle: TariffPricingBillingCycle.month,
      },
      {
        id: 4,
        price: '15000.00',
        billing_cycle: TariffPricingBillingCycle.year,
      },
    ],
    name: 'Стандарт',
    plan_type: TariffPlanType.personal,
    active: true,
    ordering: 3,
    access_to_objects: true,
    access_to_source: true,
    robot_assistant: true,
    support_service: true,
    is_trial: false,
    is_free: false,
    number_of_devices: 2,
    additional_services: [
      {
        id: 4,
        cost: '1000.00',
        free_uses: 2,
        service: 2,
      },
      {
        id: 5,
        cost: '10000.00',
        free_uses: 0,
        service: 1,
      },
      {
        id: 12,
        cost: '500.00',
        free_uses: 0,
        service: 3,
      },
    ],
    image: null,
    price_text: '',
    description: 'Полный набор функций с роботом-помощником и приоритетной поддержкой',
    static_advantages: [
      'Просмотр адреса объекта',
      'Юридический аудит 15 000 ₽',
      'Онлайн-показ  5 000 ₽',
      'Поддержка с 3-х устройств',
    ],
  }),
  new TariffModel({
    id: 1,
    pricing_options: [
      {
        id: 1,
        price: '5000.00',
        billing_cycle: TariffPricingBillingCycle.month,
      },
      {
        id: 2,
        price: '50000.00',
        billing_cycle: TariffPricingBillingCycle.year,
      },
    ],
    name: 'Максимум',
    plan_type: TariffPlanType.personal,
    active: true,
    ordering: 4,
    access_to_objects: true,
    access_to_source: true,
    robot_assistant: true,
    support_service: true,
    is_trial: false,
    is_free: false,
    number_of_devices: 2,
    additional_services: [
      {
        id: 13,
        cost: '500.00',
        free_uses: 0,
        service: 2,
      },
      {
        id: 14,
        cost: '8000.00',
        free_uses: 0,
        service: 1,
      },
      {
        id: 15,
        cost: '500.00',
        free_uses: 2,
        service: 3,
      },
    ],
    image: 'http://dev.kupilot.terabit.ai/media/tariff/2024/11/14.png',
    price_text: '',
    description: 'Максимум возможностей для профессионалов с бесплатными услугами сопровождения',
    static_advantages: [
      'Просмотр адреса объекта',
      'Умный поиск',
      'Приоритетная служба поддержки',
      'Юридический аудит 10 000 ₽',
      'Онлайн-показ: 4 раза бесплатно, далее 500 ₽',
      'Поддержка 5-и устройств',
    ],
  }),
  new TariffModel({
    id: 6,
    pricing_options: [
      {
        id: 11,
        price: '6000.00',
        billing_cycle: TariffPricingBillingCycle.month,
      },
      {
        id: 12,
        price: '60000.00',
        billing_cycle: TariffPricingBillingCycle.year,
      },
    ],
    name: 'Профи',
    plan_type: TariffPlanType.corporate,
    active: true,
    ordering: 1,
    access_to_objects: true,
    access_to_source: true,
    robot_assistant: true,
    support_service: true,
    is_trial: false,
    is_free: false,
    number_of_devices: 5,
    additional_services: [
      {
        id: 19,
        cost: '5000.00',
        free_uses: 0,
        service: 2,
      },
      {
        id: 20,
        cost: '10000.00',
        free_uses: 0,
        service: 1,
      },
      {
        id: 21,
        cost: '1000.00',
        free_uses: 0,
        service: 3,
      },
    ],
    image: null,
    price_text: '',
    description: 'Базовый корпоративный пакет услуг для малого и среднего бизнеса с подключением сотрудников',
    static_advantages: [
      'Просмотр адреса объекта',
      'Информация по объектам',
      'Умный поиск',
      'Приоритетная служба поддержки',
      'Юридический аудит 15 000 ₽',
      'Онлайн-показ: 5 000 ₽',
      'Подключение до 5 сотрудников ',
    ],
  }),
  new TariffModel({
    id: 7,
    pricing_options: [
      {
        id: 13,
        price: '10000.00',
        billing_cycle: TariffPricingBillingCycle.month,
      },
      {
        id: 14,
        price: '100000.00',
        billing_cycle: TariffPricingBillingCycle.year,
      },
    ],
    name: 'Профи Плюс',
    plan_type: TariffPlanType.corporate,
    active: true,
    ordering: 2,
    access_to_objects: true,
    access_to_source: true,
    robot_assistant: true,
    support_service: true,
    is_trial: false,
    is_free: false,
    number_of_devices: 5,
    additional_services: [
      {
        id: 22,
        cost: '500.00',
        free_uses: 0,
        service: 2,
      },
      {
        id: 23,
        cost: '8000.00',
        free_uses: 0,
        service: 1,
      },
      {
        id: 24,
        cost: '500.00',
        free_uses: 0,
        service: 3,
      },
    ],
    image:
      'http://dev.kupilot.terabit.ai/media/tariff/2024/11/universal_upscale_0_d2b7cafb-71de-4cd3-ab6f-47f1c62c50eb_0_upscale_ultra.png',
    price_text: '',
    description: 'Расширенный пакет услуг для бизнеса с бесплатными аудитами и аналитикой',
    static_advantages: [
      'Просмотр адреса объекта',
      'Информация по объектам',
      'Умный поиск',
      'Приоритетная служба поддержки',
      'Юридический аудит 10 000 ₽',
      'Онлайн-показ: 5000 ₽',
      'Подключение до 5 сотрудников',
    ],
  }),
  new TariffModel({
    id: 8,
    pricing_options: [
      // {
      //   id: 15,
      //   price: '15000.00',
      //   billing_cycle: TariffPricingBillingCycle.month,
      // },
      // {
      //   id: 16,
      //   price: '150000.00',
      //   billing_cycle: TariffPricingBillingCycle.year,
      // },
    ],
    name: 'Под ключ',
    plan_type: TariffPlanType.corporate,
    active: true,
    ordering: 3,
    access_to_objects: true,
    access_to_source: true,
    robot_assistant: true,
    support_service: true,
    is_trial: false,
    is_free: false,
    number_of_devices: 10,
    additional_services: [
      {
        id: 25,
        cost: '500.00',
        free_uses: 0,
        service: 2,
      },
      {
        id: 26,
        cost: '8000.00',
        free_uses: 0,
        service: 1,
      },
      {
        id: 27,
        cost: '500.00',
        free_uses: 0,
        service: 3,
      },
    ],
    image:
      'http://dev.kupilot.terabit.ai/media/tariff/2024/11/universal_upscale_0_d2b7cafb-71de-4cd3-ab6f-47f1c62c50eb_0_upscale_ultra_7397Hr4.png',
    price_text: '',
    description: 'Индивидуальные условия для крупных компаний с максимальным набором услуг',
    static_advantages: [],
  }),
];
