import { Typography } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { Pagination } from 'swiper/modules';
import imgExample1 from 'assets/services/ex1.png';
import imgExample2 from 'assets/services/ex2.png';
import imgExample3 from 'assets/services/ex3.png';
import imgExample4 from 'assets/services/ex4.png';
import imgExample5 from 'assets/services/ex5.png';
import imgExample6 from 'assets/services/ex6.png';
import { FaqItem } from 'pages/ServicesInfoPage/components/FaqItem/FaqItem';
import { ServicesStoreProvider } from 'stores/ServicesStore/context';
import bannerImg from 'assets/services/bn-online.png';
import img1 from 'assets/services/s1.png';
import img2 from 'assets/services/s2.png';
import img3 from 'assets/services/s4.png';
import { PageContainer } from 'components/PageContainer';
import Slider from 'components/Slider';
import { ServicesSlugStatic } from 'entities/services/services';
import { BannerPage } from 'pages/ServicesInfoPage/components/Banner/BannerPage';
import { CardStep } from 'pages/ServicesInfoPage/components/CardStep/CardStep';
import { SliderStep } from 'pages/ServicesInfoPage/components/SliderStep/SliderStep';

import s from './OnlinePage.module.scss';

const BREAKPOINTS = {
  0: {
    spaceBetween: 0,
    slidesPerView: 1,
  },
  575: {
    spaceBetween: 5,
    slidesPerView: 1.3,
  },
  768: {
    spaceBetween: 10,
    slidesPerView: 1.8,
  },
  850: {
    spaceBetween: 10,
    slidesPerView: 2.1,
  },
  1050: {
    spaceBetween: 55,
    slidesPerView: 2.5,
  },
  1280: {
    spaceBetween: 55,
    slidesPerView: 3,
  },
};

const ServisesInfoOnlinePage = () => {
  const pagination = {
    clickable: true,
    renderBullet: (index: number, className: string) => {
      return `<span class="${className} ${s.bullet}" > </span>`;
    },
  };

  return (
    <ServicesStoreProvider>
      <PageContainer className={cn(s['lot-page'], s['lot-page-mobile'])}>
        <BannerPage
          bannerImg={bannerImg}
          title={`Онлайн показ объекта\nнедвижимости`}
          description={`Нет возможности самому посмотреть интересующий объект?\nСделаем это за Вас! Просто оставьте заявку`}
          list={[
            'Не тратьте время на поездки',
            'Не ограничивайтесь объектами своего региона',
            'Не подстраивайтесь под график показа объекта',
          ]}
          price={''}
          className={s.banner}
          serviceSlug={ServicesSlugStatic.online}
          printLabelPrice
        />
      </PageContainer>
      <PageContainer className={cn(s['lot-page'])}>
        <div className={s.section}>
          <Typography.Title className={s.title} level={2}>
            Три простых шага
          </Typography.Title>
          <SliderStep
            slidesPerView={3}
            spaceBetween={55}
            breakpoints={BREAKPOINTS}
            slideClass={s.slide}
            slides={[
              <CardStep
                item={{
                  title: 'Оставьте заявку',
                  description: 'Просмотр интересующего объекта вне зависимости от его расположения и торговой площадки',
                  step: 1,
                  image: img1,
                }}
              />,
              <CardStep
                item={{
                  title: 'Согласование времени',
                  description:
                    'Менеджер согласовывает  удобное время для проведения  онлайн-показа с вами и владельцем объекта',
                  step: 2,
                  image: img2,
                }}
              />,
              <CardStep
                item={{
                  title: 'Онлайн-показ',
                  description: 'Наш менеджер проводит онлайн-показ',
                  step: 3,
                  image: img3,
                }}
              />,
            ]}
          />
        </div>
        <div className={s.section}>
          <Typography.Title className={s.title} level={2}>
            Наши достоинства
          </Typography.Title>
          <ul className={s.list + ' ' + s.list_max}>
            <li className={s.list__item}>HD-качество трансляции</li>
            <li className={s.list__item}>Менеджер показа разбирается в недвижимости</li>
            <li className={s.list__item}>Возможность задавать менеджеру вопросы в реальном времени</li>
            <li className={s.list__item}>Запись видео и фото по итогам показа</li>
          </ul>
        </div>
        <div className={s.section}>
          <Typography.Title className={s.title} level={2}>
            Примеры
          </Typography.Title>
          <Slider
            className={s.swiper__images}
            slides={[
              <img src={imgExample1} alt="" className={s.example__img} />,
              <img src={imgExample2} alt="" className={s.example__img} />,
              <img src={imgExample3} alt="" className={s.example__img} />,
              <img src={imgExample4} alt="" className={s.example__img} />,
              <img src={imgExample5} alt="" className={s.example__img} />,
              <img src={imgExample6} alt="" className={s.example__img} />,
            ]}
            onSlideChange={() => void 0}
            slideClass={s.swiper__slide}
            slidesPerView={3}
            spaceBetween={75}
            pagination={pagination}
            modules={[Pagination]}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 30,
              },
              575: {
                slidesPerView: 1.2,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 2.2,
                spaceBetween: 10,
              },
              1110: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              1280: {
                slidesPerView: 3,
                spaceBetween: 75,
              },
            }}
          />
        </div>

        <div className={s.section}>
          <Typography.Title className={s.title} level={2}>
            Частые вопросы (FAQ):
          </Typography.Title>
          <div className={s.padding}>
            <FaqItem
              title="1. Что такое онлайн показ недвижимости?"
              description="Онлайн показ — это виртуальный тур по объекту недвижимости, который проводится через видеосвязь. Наш специалист на месте покажет вам все детали объекта в реальном времени и ответит на ваши вопросы."
            />
            <FaqItem
              title="2. Как заказать услугу онлайн показа?"
              description={`Для заказа необходимо нажать кнопку "Оставить заявку на онлайн-показ" на странице услуги и заполнить контактные данные. Мы свяжемся с вами для подтверждения.`}
            />
            <FaqItem
              title="3. Сколько стоит онлайн показ?"
              description={`Стоимость зависит от выбранной подписки. Подробную информацию о цене вы можете узнать при заказе услуги или в разделе "Пакет услуг".`}
            />
            <FaqItem
              title="4. Какие технологии используются для онлайн показа?"
              description="Для онлайн показа мы используем платформы видеосвязи (например, Zoom), обеспечивая стабильное соединение и высокое качество видео."
            />
            <FaqItem
              title="5. Нужно ли что-то устанавливать для участия в онлайн показе?"
              description="Нет, вам нужно только устройство с доступом в интернет и возможность пользоваться видеосвязью (например, через браузер)."
            />
            <FaqItem
              title="6. Могу ли я задать вопросы по ходу онлайн показа?"
              description="Да, вы можете задавать вопросы специалисту в режиме реального времени во время показа."
            />
            <FaqItem
              title="7. Как долго длится онлайн показ?"
              description="Средняя продолжительность онлайн показа составляет от 20 до 40 минут, в зависимости от объекта и ваших запросов."
            />
            <FaqItem
              title="8. Что делать, если у меня возникнут проблемы с подключением?"
              description="Если возникнут технические трудности, наши специалисты помогут вам наладить соединение или предоставят запись онлайн-показа."
            />
            <FaqItem
              title="9. Могу ли я отменить или перенести время онлайн показа?"
              description="Да, вы можете изменить время или отменить заказ, связавшись с нашей службой поддержки не позднее чем за 24 часа до назначенного времени показа."
            />
          </div>
        </div>
      </PageContainer>
    </ServicesStoreProvider>
  );
};

export default observer(ServisesInfoOnlinePage);
