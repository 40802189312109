import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { BaseInput } from 'components/BaseInput';
import { MultiSelect } from 'components/MultiSelect';
import { useLotListStore } from 'stores/LotListCatalogStore/context';

type FormTypeProps = {
  className?: string;
};

const FormType = ({ className }: FormTypeProps): React.ReactElement<FormTypeProps> => {
  const { filters } = useLotListStore();

  return (
    <BaseInput className={className} title="Форма проведения торгов">
      <MultiSelect
        options={filters.formType.options}
        selectedOptions={filters.formType.selectedOptions}
        value={filters.formType.selectedValue.value}
        onChange={filters.formType.toggleValue}
        onSearch={filters.formType.handleSearch}
        searchValue={filters.formType.searchValue.value}
        isOptionsLoading={filters.loadingStage.isLoading}
        dropdownStyle={{
          zIndex: 1300,
        }}
      />
    </BaseInput>
  );
};

export default observer(FormType);
