import React, { useState } from 'react';
import s from './CardService.module.scss';

export const CardService = ({
  title,
  description,
  image,
  onClick,
}: {
  title: string;
  description: string;
  image: string;
  onClick: () => void;
}) => {
  const [hover, setHover] = useState(false);

  return (
    <div className={s.card}>
      <div className={s.body}>
        <div className={s.preview + ' ' + (hover ? s.hover : '')}>
          <img src={image} alt="" loading="lazy" decoding="async" />
        </div>
        <h3 className={s.title}>{title}</h3>
        <p className={s.description} dangerouslySetInnerHTML={{ __html: description }} />
      </div>
      <button
        className={s.buttonRound}
        onClick={onClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        Узнать подробнее
      </button>
    </div>
  );
};
