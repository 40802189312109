import cn from 'classnames';
import React, { ReactNode } from 'react';
import s from './TariffItem.module.scss';

type HeaderType = {
  subtitle: string;
  title: string;
  onClick?: () => void;
  description: string;
  isLoading?: boolean;
  priceText?: string;
};

const Header = ({
  header,
  loading,
  hover,
  hideAction,
}: {
  header: HeaderType;
  loading: boolean;
  hover?: boolean;
  hideAction?: boolean;
}) => {
  return (
    <div className={cn(s.tariffs__card__header, hover && s.header__white)}>
      <span className={s.tariffs__card__header__subtitle}>{header.subtitle}</span>
      <h4
        className={`${s.tariffs__card__header__title} ${header.title === 'UNSET' ? s.tariffs__card__header__title__hide : ''}`}
      >
        {hover && header?.priceText ? header.priceText : header.title}
      </h4>
      <p className={s.tariffs__card__header__desc}>{header.description}</p>
      {header?.onClick && (
        <button
          className={`${s.tariffs__card__header__btn} ${hideAction ? s.tariffs__card__header__btn__hide : ''}`}
          disabled={loading}
          onClick={header.onClick}
        >
          Выбрать
        </button>
      )}
    </div>
  );
};

export const TariffCard = ({
  header,
  list,
  clsx,
  border,
  center,
  background,
  hoverFixed,
  onClickCall,
  hideAction = false,
}: {
  list: { label?: string; icon?: ReactNode }[];
  header?: HeaderType;
  clsx?: {
    item?: string;
    list?: string;
    card?: string;
  };
  border?: boolean;
  center?: boolean;
  background?: string;
  hoverFixed?: boolean;
  onClickCall?: () => void;
  hideAction?: boolean;
}) => {
  const loading = header?.isLoading || false;
  const isHover = background && background !== '';

  return (
    <div className={cn(s.tariffs__card, clsx?.card, border && s.tariffs__card__border)}>
      {isHover && (
        <div
          className={s.hover + ' ' + (hoverFixed ? s.hover__fixed : '')}
          style={{ backgroundImage: `url(${background})` }}
        >
          {header && <Header header={header} loading={loading} hover hideAction={hideAction} />}
          <div role="button" className={s.hover__call} onClick={onClickCall}>
            Оставить заявку
          </div>
        </div>
      )}

      {header && <Header header={header} loading={loading} />}
      <ul className={cn(s.tariffs__card__list, clsx?.list)}>
        {list.map((item, index) => (
          <li
            key={index}
            className={cn(
              s.tariffs__card__list__item,
              clsx?.item,
              center && s.tariffs__card__text__center,
              item.label === '—' && s.tariffs__card__text__empty,
            )}
          >
            {item.icon}
            {item.label && (
              <span className={s.dangerouslySetInnerHTML} dangerouslySetInnerHTML={{ __html: item.label }} />
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};
