import { Button } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { ProfileNotificationMenu } from 'components/profile/ProfileNotification/ProfileNotification';
import { ElementId } from 'config/ids';
import { RouteFullPath } from 'config/routes';
import { AnalyticsEvent } from 'entities/analytics';
import { useRootStore } from 'stores/RootStore';

import { ProfileMenu } from './ProfileMenu';

import s from './HeaderActions.module.scss';
import { ymEvents } from 'utils/ym';
import FavoriteIcon from 'components/icons/FavoriteIcon';

type HeaderProfileProps = {
  className?: string;
};

export const HeaderActionButtons = ({
  className = '',
  onCallbackBtn,
  icons,
  btnStyles,
}: {
  className?: string;
  onCallbackBtn?: () => void;
  btnStyles?: { first?: string; last?: string };
  icons?: { first?: React.ReactNode; last?: React.ReactNode };
}) => {
  const { authStore, analyticsStore } = useRootStore();
  const handleLoginButtonClick = React.useCallback(() => {
    onCallbackBtn && onCallbackBtn();
    authStore.popupController.open();
    authStore.mode.change('login');
    ymEvents.knopkaEnter();
    analyticsStore.trackEvent({ event: AnalyticsEvent.loginButtonClick });
  }, [authStore.popupController, analyticsStore]);

  const handleRegisterButtonClick = React.useCallback(() => {
    onCallbackBtn && onCallbackBtn();
    authStore.popupController.open();
    authStore.mode.change('register');
    analyticsStore.trackEvent({ event: AnalyticsEvent.registerButtonClick });
  }, [authStore.popupController, analyticsStore]);
  return (
    <div className={s.header__actions + ' ' + className}>
      <Button
        className={s.singin + ' ' + btnStyles?.first}
        loading={authStore.authorizingStage.isLoading}
        onClick={handleLoginButtonClick}
        type="primary"
      >
        {icons?.first}
        Вход
      </Button>
      <Button
        className={s.singin + ' ' + (btnStyles?.last ? btnStyles?.last : s.outline) + ' ' + s.sigin__in}
        loading={authStore.authorizingStage.isLoading}
        onClick={handleRegisterButtonClick}
        type="primary"
      >
        {icons?.last}
        Регистрация
      </Button>
    </div>
  );
};

const HeaderActions = ({ className }: HeaderProfileProps): React.ReactElement<HeaderProfileProps> => {
  const { userStore, notificationStore } = useRootStore();
  const navigate = useNavigate();

  const notificationMenu = React.useMemo(() => {
    return (
      <ProfileNotificationMenu
        menu
        onClick={() => {
          navigate(RouteFullPath.notification);
        }}
      />
    );
  }, [notificationStore.currentTotalItems]);

  return (
    <div className={cn(s['header-profile'], className)} id={ElementId.loginButton}>
      {!userStore.authorized ? (
        <HeaderActionButtons />
      ) : (
        <div className={s.header__profile__actions}>
          <Link to={RouteFullPath.favorites} className={s.link}>
            <FavoriteIcon />
          </Link>
          {notificationMenu}
          <ProfileMenu />
        </div>
      )}
    </div>
  );
};

export default observer(HeaderActions);
