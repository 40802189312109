import React, { useEffect, useState } from 'react';
import s from './Filters.module.scss';
import { observer } from 'mobx-react-lite';
import { AuctionType } from './components/AuctionType';
import { ObjectType } from './components/ObjectType';
import { Area } from './components/Area';
import { FormType } from './components/FormType';
import { Status } from './components/Status';
import { Organizer } from './components/Organizer';
import { SourceArea } from './components/SourceArea';
import { CadastralNumber } from './components/CadastralNumber';
import { Sections } from './components/Sections';
import { Floor } from './components/Floor';
import { AmountFloors } from './components/AmountFloors';
import { useWidth } from 'hooks/useWidth';
import { ActionButtons } from './components/ActionButtons';
import { RobotLotAction } from './components/RobotAction/RobotAction';
import { createPortal } from 'react-dom';
import { useRootStore } from 'stores/RootStore';
import CloseIcon from 'components/icons/CloseIcon/CloseIcon';
import { useLotListStore } from 'stores/LotListCatalogStore/context';
import { numberDeclination } from 'utils/filters';

function createWrapperAndAppendToBody(wrapperId: string) {
  const wrapperElement = document.createElement('div');
  wrapperElement.setAttribute('id', wrapperId);
  document.body.appendChild(wrapperElement);
  return wrapperElement;
}

export const FilterHeader = observer(
  ({ className = '', onClose, title }: { className?: string; onClose: () => void; title: string }) => {
    return (
      <div className={s.filter__header + ' ' + className}>
        <div></div>
        <h5 className={s.filter__header__title}>{title}</h5>
        <button className={s.filter__header__btn} onClick={onClose}>
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
            <g clipPath="url(#clip0_608_61031)">
              <circle cx="15" cy="15" r="15" fill="#E5E5E5" />
              <path
                d="M8.36175 21.6381C8.07933 21.3554 8.07942 20.8956 8.362 20.613L13.8672 15.1063L13.9732 15.0002L13.8672 14.8942L8.362 9.38746C8.07933 9.10471 8.07933 8.64479 8.362 8.36204C8.64465 8.07932 9.10437 8.07932 9.38701 8.36204L14.8922 13.8687L14.9982 13.9749L15.1043 13.8687L20.6095 8.36204C20.8921 8.07932 21.3519 8.07932 21.6345 8.36204C21.9172 8.64479 21.9172 9.10471 21.6345 9.38746L21.3785 9.64351H21.382L16.1328 14.8942L16.0268 15.0002L16.1328 15.1063L21.638 20.613C21.9207 20.8957 21.9207 21.3556 21.638 21.6384C21.4962 21.7803 21.311 21.85 21.1246 21.85C20.939 21.85 20.7535 21.7791 20.611 21.6381C20.6109 21.638 20.6108 21.6379 20.6107 21.6378L15.1061 16.1317L15 16.0256L14.8939 16.1317L9.38876 21.6384C9.24692 21.7803 9.06177 21.85 8.87538 21.85C8.68981 21.85 8.50425 21.7791 8.36175 21.6381Z"
                fill="#252525"
                stroke="#E5E5E5"
                stroke-width="0.3"
              />
            </g>
            <defs>
              <clipPath id="clip0_608_61031">
                <rect width="30" height="30" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </button>
      </div>
    );
  },
);

const FilterContent = observer(
  ({ open, className = '', onClose }: { open: boolean; className?: string; onClose: () => void }) => {
    return (
      <div className={s.filters__modal + ' ' + className} aria-hidden={!open}>
        <div>
          <FilterHeader onClose={onClose} title="Умный поиск" />
          <div className={`${s.filtersGrid}`}>
            <AuctionType />
            <ObjectType />
            <Area />
            {/* */}
            <FormType />
            <Status />
            <Organizer />
            <SourceArea />
            <Sections />
            <CadastralNumber />
            <Floor />
            <AmountFloors />
            <div className={s.actions}>
              <RobotLotAction skipInitList={true} />
              <ActionButtons onFiltersClose={() => {}} />
            </div>
          </div>
        </div>
      </div>
    );
  },
);

const FilterContentMobile = observer(
  ({ open, className = '', onClose }: { open: boolean; className?: string; onClose: () => void }) => {
    return (
      <div className={s.filters__modal + ' ' + className} aria-hidden={!open}>
        <div>
          <FilterHeader onClose={onClose} title="Умный поиск" />
          <div className={`${s.filtersGrid}`}>
            <AuctionType classNameField={s.mobile__size} />
            <FormType className={s.mobile__size} />
            <SourceArea className={s.mobile__size} />
            <Floor className={s.mobile__size} />
            <ObjectType className={s.mobile__size} />
            <Status className={s.mobile__size} />
            <Sections className={s.mobile__size} />
            <AmountFloors className={s.mobile__size} />
            <Area className={s.mobile__size} />
            <Organizer className={s.mobile__size} />
            <CadastralNumber className={s.mobile__size} />
            <div className={s.actions}>
              <RobotLotAction skipInitList={true} />
              <ActionButtons onFiltersClose={() => {}} />
            </div>
          </div>
        </div>
      </div>
    );
  },
);

export const Filters = observer(() => {
  const { setFilters, filters } = useLotListStore();
  const { robotStore } = useRootStore();
  const { robotFilters, deleteRobotItemFetch } = robotStore;
  const [open, setOpen] = useState(false);
  const { width } = useWidth();
  const [wrapperElement, setWrapperElement] = useState<HTMLElement | null>(null);

  const handlerDelete = (id: number) => {
    deleteRobotItemFetch(id);
  };

  useEffect(() => {
    let element = document.getElementById('filters-mobile');
    let systemCreated = false;

    if (!element) {
      systemCreated = true;
      element = createWrapperAndAppendToBody('filters-mobile');
    }
    setWrapperElement(element);

    return () => {
      if (systemCreated && element?.parentNode) {
        element.parentNode.removeChild(element);
      }
    };
  }, []);

  useEffect(() => {
    const body = document.querySelector('body');
    if (body) {
      if (width >= 1200 && open) {
        body.style.overflow = '';
      } else if (open) {
        body.style.overflow = 'hidden';
      } else {
        body.style.overflow = '';
      }
    }

    return () => {
      if (body) body.style.overflow = '';
    };
  }, [width, open]);

  const onClose = () => setOpen(false);
  const currentItemRobot = robotFilters.items.length > 0 ? robotFilters.items[robotFilters.items.length - 1] : null;

  return (
    <div className={s.filters__wrap + ' ' + (open ? s.open : '')}>
      <button className={s.filters__button} data-open={open}>
        <span className={s.title}>
          <>
            {!robotFilters.loadingStage.isLoading && currentItemRobot ? (
              <div
                className={s.chip}
                role="button"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setFilters(currentItemRobot.filters);
                }}
              >
                <span>{currentItemRobot.name}</span>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handlerDelete(currentItemRobot.id);
                  }}
                >
                  <CloseIcon />
                </button>
                filters
              </div>
            ) : currentItemRobot === null && filters.activeFilterCount > 0 ? (
              <div className={s.chip}>
                <span>
                  Выбрано {filters.activeFilterCount}{' '}
                  {numberDeclination(filters.activeFilterCount, ['фильтр', 'фильтра', 'фильтров'])}
                </span>
                <button
                  onClick={() => {
                    filters.clearSideFilters(true);
                  }}
                >
                  <CloseIcon />
                </button>
              </div>
            ) : (
              <>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12.6661 8V12C12.6661 12.368 12.3675 12.6667 11.9995 12.6667C11.6315 12.6667 11.3329 12.368 11.3329 12V8C11.3329 7.632 11.6315 7.33333 11.9995 7.33333C12.3675 7.33333 12.6661 7.632 12.6661 8ZM7.71968 4.478L9.76693 11.8307C9.88359 12.2507 9.56827 12.6667 9.13162 12.6667C8.8363 12.6667 8.57764 12.4707 8.49698 12.1867L8.06833 10.6667H4.59781L4.16916 12.1867C4.08916 12.4707 3.82984 12.6667 3.53452 12.6667C3.09854 12.6667 2.78255 12.2507 2.89921 11.8307L4.95979 4.42533C5.05379 4.02 5.32044 3.67867 5.69176 3.48867C6.07041 3.294 6.50506 3.282 6.91771 3.45533C7.30903 3.61933 7.60835 3.99933 7.71968 4.478ZM7.69235 9.33333L6.4064 4.77667C6.38707 4.70067 6.27907 4.70067 6.26041 4.77667L4.97446 9.33333H7.69235ZM15.3334 6.66667C14.9654 6.66667 14.6667 6.96533 14.6667 7.33333V12.6667C14.6667 13.7693 13.7694 14.6667 12.6668 14.6667H3.33319C2.23057 14.6667 1.33328 13.7693 1.33328 12.6667V3.33333C1.33328 2.23067 2.23057 1.33333 3.33319 1.33333H8.66631C9.03429 1.33333 9.33294 1.03467 9.33294 0.666667C9.33294 0.298667 9.03429 0 8.66631 0H3.33319C1.49527 0 0 1.49533 0 3.33333V12.6667C0 14.5047 1.49527 16 3.33319 16H12.6661C14.5041 16 15.9993 14.5047 15.9993 12.6667V7.33333C15.9993 6.96533 15.7013 6.66667 15.3334 6.66667ZM10.7669 3.45333L12.0122 3.978L12.5088 5.22133C12.6161 5.49067 12.8768 5.66667 13.1668 5.66667C13.4568 5.66667 13.7168 5.49 13.8248 5.22133L14.3187 3.98533L15.5547 3.49133C15.824 3.384 16 3.12333 16 2.83333C16 2.54333 15.8233 2.28333 15.5547 2.17533L14.3187 1.68133L13.8248 0.445333C13.7161 0.176667 13.4554 0 13.1661 0C12.8768 0 12.6161 0.176667 12.5081 0.445333L12.0168 1.674L10.7902 2.13867C10.5196 2.24133 10.3382 2.49867 10.3329 2.788C10.3276 3.078 10.5002 3.34067 10.7669 3.45333Z"
                    fill="currentColor"
                  />
                </svg>
                <span className={s.placeholder}>
                  {width > 996 ? 'Вы можете создать автоматизированный поиск по параметрам' : 'Умный поиск'}
                </span>
              </>
            )}
          </>
        </span>
        <div className={s.filter__name__wrap} onClick={() => setOpen((prev) => !prev)} role="button">
          <span className={s.filter__name}>Фильтры</span>
          <svg
            className={s.icon__arrow}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.99937 6C10.504 6 10.9796 6.19418 11.3379 6.54644L18 13.12L17.1081 14L10.446 7.42648C10.2075 7.19122 9.79248 7.19122 9.55405 7.42648L2.8919 14L2 13.12L8.66215 6.54644C9.01916 6.19418 9.49476 6 10.0006 6L9.99937 6Z"
              fill="#252525"
            />
          </svg>
        </div>
      </button>
      <FilterContent onClose={onClose} open={open} className={s.filterDesc} />
      {wrapperElement &&
        createPortal(<FilterContentMobile onClose={onClose} className={s.filterMob} open={open} />, wrapperElement)}
    </div>
  );
});
