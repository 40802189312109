import React from 'react';

import Slider from 'components/Slider';

import SubscriptionsCard from './SubscriptionsCard/SubscriptionsCard';

import s from './SubscriptionsCards.module.scss';
import { observer } from 'mobx-react-lite';
import { TariffPlanType } from 'entities/tariffs';
import { TariffModel } from 'models/TariffModel';
import { numberWithSpaces } from 'utils/function';

interface SubscriptionsCardsProps {
  className?: string;
  listType: TariffPlanType;
  items: TariffModel[];
  isLoading?: boolean;
  onSubscriptionTariff: (tariff: number, plan_option: number) => void;
  active?: number;
}

const PRICES_LABEL: { [key: number]: string } = {
  1: 'Анализ локации',
  2: 'Юр. аудит',
  3: 'Онлайн показ',
};

const SubscriptionsCards = observer(
  ({
    className,
    listType,
    items,
    isLoading = false,
    onSubscriptionTariff,
    active,
  }: SubscriptionsCardsProps): React.ReactElement => {
    return (
      <div className={className}>
        <Slider
          className={s.swiper}
          spaceBetween={27}
          slidesPerView={'auto'}
          centerInsufficientSlides
          slideClass={s.slide}
          slides={items.map((item) => {
            const prices = item.pricing_options.map((option) => ({
              label: numberWithSpaces(parseInt(option.price)) + ' ₽',
            }));

            const price = item.pricing_options[0]
              ? numberWithSpaces(parseInt(item.pricing_options[0].price))
              : 'По запросу';

            let advantages = [];
            if (item.static_advantages) {
              advantages = item.static_advantages;
            } else {
              advantages.push('Доступ к объектам (адрес)');
              if (item.access_to_source) {
                advantages.push(`Умный поиск`);
              }

              if (item.robot_assistant) {
                advantages.push(`Приоритетная cлужба поддержки`);
              }

              if (item.support_service) {
                advantages.push(`Инфо по объектам`);
              }

              prices.forEach((item, index) => {
                advantages.push(PRICES_LABEL[index + 1] + ' ' + item.label);
              });

              if (item.number_of_devices) {
                advantages.push(`Поддержка на ${item.number_of_devices} устройствах`);
              }
            }

            return (
              <SubscriptionsCard
                key={item.id}
                id={item.id}
                tariffId={item.id}
                optionId={item.pricing_options[0] ? item.pricing_options[0].id : -1}
                className={s.card}
                title={item.name}
                description={item.description || ''}
                advantages={advantages}
                onClick={onSubscriptionTariff}
                isLoading={isLoading}
                isActive={active === item.id}
                price={price}
                //TODO: костыль. так хотят
                hidePrice={'Corp Max' === item.name}
                hideAction={'Corp Max' === item.name}
              />
            );
          })}
          onSlideChange={() => {}}
        />
      </div>
    );
  },
);

export default SubscriptionsCards;
