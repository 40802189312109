import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import bgTop from 'assets/banner-tariffs-top.png';
import { Banner } from 'components/Banner';
import { PageContainer } from 'components/PageContainer';
import { TariffsStoreProvider } from 'stores/TariffsStore/context';

import { Questions } from './Questions/Questions';
import { TariffsList } from './TariffsList/TariffsList';

import s from './TariffsPage.module.scss';

type TariffsPageProps = {
  className?: string;
};

const TariffsPage = ({ className }: TariffsPageProps): React.ReactElement<TariffsPageProps> => {
  return (
    <TariffsStoreProvider>
      <PageContainer className={cn(s['lot-page'], className)}>
        <Banner
          title="Тарифы"
          description="Выберите тариф и пользуйтесь всеми возможностями маркетплейса без ограничений"
          backgroundImage={bgTop}
          className={s.bgTop}
          clsx={{
            title: s.bgTopTitle,
            desc: s.bgTopDesc,
          }}
        />
        <TariffsList />
        <Questions />
      </PageContainer>
    </TariffsStoreProvider>
  );
};

export default observer(TariffsPage);
