const createApiUrl = (url: string) => `/api/${url}`;
const createPhonePrefix = (url: string) => `${createApiUrl('phone')}/${url}`;
const createEmailPrefix = (url: string) => `${createApiUrl('email')}/${url}`;
const createProfilePrefix = (url: string) => `${createApiUrl('profile')}/${url}`;
const createSubjectFilterPrefix = (url: string) => `${createApiUrl('filters/subject')}/${url}`;
const createProfileUser = (url: string = '', prefix?: string) =>
  `${createApiUrl(prefix ? prefix + '/user_filters' : 'user_filters')}/${url}`;
const createNotificationUrl = (url: string) => `${createApiUrl('notifications')}/${url}`;

export const apiUrls = {
  lotList: createApiUrl('lots'),
  lotListPublic: createApiUrl('lots/public'),
  lotHistoryDelete: (id: number) => createApiUrl('lots/history/delete/' + id),
  lotViewsAdd: (id: number) => createApiUrl(`lots/user_filters/${id}/add_viewed_lots/`),
  lotListSimilar: createApiUrl('lots/similar'),
  lotListSimilarPublic: createApiUrl('lots/public/similar'),
  lotListProfile: createProfilePrefix('lots'),
  bidListProfile: createProfilePrefix('bids'),
  lot: (id: number) => createApiUrl(`lots/${id}`),
  lotPublic: (id: number) => createApiUrl(`lots/public/${id}`),
  logout: createApiUrl('user/logout'),
  lotListFilters: createApiUrl('filters'),
  lotListCityFilters: createSubjectFilterPrefix('cities'),
  lotListRegionFilters: createSubjectFilterPrefix('regions'),
  lotListProfileFilters: createProfileUser('', 'lots'),
  phone: {
    send: createPhonePrefix('send'),
    confirm: createPhonePrefix('confirm'),
    registration: createPhonePrefix('registration'),
  },
  email: {
    send: createEmailPrefix('send'),
    confirm: createEmailPrefix('confirm'),
    registration: createEmailPrefix('registration'),
  },
  user: createApiUrl('user/current'),
  organizers: createApiUrl('filters/organizers'),
  toggleFavorite: (lotId: number) => createApiUrl(`lots/${lotId}/toggle_favorite`),
  toggleSubscription: (lotId: number) => createApiUrl(`lots/${lotId}/subscribe`),
  resizeImage: ({ sign, w, h, url }: { sign?: string; w: number; h?: number; url: string }): string =>
    `/optimizer/${sign ?? 'insecure'}/w:${w}/h:${h ?? w}/plain/${url}`,
  editUser: createApiUrl('user/edit'),
  bid: (id: number) => createApiUrl(`lots/${id}/bid`),
  support: createApiUrl('profile/support'),
  additionalServices: createApiUrl('additional_services'),
  additionalServicesRequests: createApiUrl('additional_services/requests'),
  tariffs: createApiUrl('tariffs'),
  tariffsHistory: createApiUrl('subscription/history'),
  tariffsUser: createApiUrl('tariffs/user_subscription'),
  tariffsSubscription: createApiUrl('tariffs/user_subscription'),
  banners: createApiUrl('site/banners'),
  notifications: createApiUrl('notifications'),
  notificationsSettings: createApiUrl('notifications/settings'),
  notificationsSettingsUpdate: (id: string | number) => createApiUrl(`notifications/settings/${id}`),
  notificationsDelete: createNotificationUrl('delete'),
  notificationsCount: createNotificationUrl('new_count'),
  notificationsSetRead: createNotificationUrl('set_read'),
  notificationsSetUnread: createNotificationUrl('set_unread'),
  bugReport: createApiUrl('site/bug_report'),
  registrationBanner: createApiUrl('site/registration_banner'),
};
