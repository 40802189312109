import { Checkbox } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { BaseInput } from 'components/BaseInput';
import { RangeFilter } from 'components/RangeFilter';
import { useLotListStore } from 'stores/LotListCatalogStore/context';

import s from './AmountFloors.module.scss';

type AmountFloorsProps = {
  className?: string;
};

const AmountFloors = ({}: AmountFloorsProps): React.ReactElement<AmountFloorsProps> => {
  const { filters } = useLotListStore();

  return (
    <div className={s.floor}>
      <BaseInput title="Этажность здания">
        <RangeFilter
          from={filters.amountFloors.from.value}
          to={filters.amountFloors.to.value}
          valueMin={filters.amountFloors.valueMin.value}
          valueMax={filters.amountFloors.valueMax.value}
          onChangeMin={filters.amountFloors.valueMin.change}
          onChangeMax={filters.amountFloors.valueMax.change}
          placeholderSufix={{
            from: 'от ',
            to: 'до ',
          }}
          preventNonInteger
          allowNegative
        />
      </BaseInput>
      <div className={s.options}>
        <Checkbox
          onChange={() => {
            if (filters.showUnknownAmountOfFloors.value === null) {
              filters.showUnknownAmountOfFloors.change(true);
            } else {
              filters.showUnknownAmountOfFloors.change(!filters.showUnknownAmountOfFloors.value);
            }
          }}
          checked={filters.showUnknownAmountOfFloors.value || false}
        >
          Этажность не указана
        </Checkbox>
      </div>
    </div>
  );
};

export default observer(AmountFloors);
