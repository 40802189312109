import React, { useCallback, useEffect, useState } from 'react';
import banner from '../assets/filters.png';
import s from './FirstScreen.module.scss';
import { Banner } from '../Banner/Banner';
import { useRootStore } from 'stores/RootStore';
import { AnalyticsEvent } from 'entities/analytics';
import { apiCustom } from 'utils/api';
import { apiUrls } from 'config/apiUrls';
import { SearchBar } from '../SearchBar';
import { observer } from 'mobx-react-lite';
import { Filters } from '../ScreenFilters/Filters/Filters';
import { useWidth } from 'hooks/useWidth';
import { useLotListStore } from 'stores/LotListCatalogStore/context';
import { ymEvents } from 'utils/ym';

export const FirstScreen = observer(() => {
  const { filters } = useLotListStore();
  const { isDesktop } = useWidth();
  const { userStore, authStore, analyticsStore } = useRootStore();
  const [dataBanner, setDataBanner] = useState<null | { id: number; header: string; text: string }>(null);
  const handleLoginButtonClick = useCallback(() => {
    ymEvents.registHome();
    authStore.popupController.open();
    authStore.mode.change('register');
    analyticsStore.trackEvent({ event: AnalyticsEvent.loginButtonClick });
  }, [authStore.popupController, analyticsStore]);

  const isAuth = userStore.authorized;

  const onSearch = () => {
    filters.setSearchValue(filters.search.value);
    filters.setSideFilters();
  };

  useEffect(() => {
    apiCustom({
      url: apiUrls.registrationBanner,
    }).then((res) => {
      setDataBanner(res.data as { id: number; header: string; text: string });
    });
  }, []);

  return (
    <div
      className={s.container + ' ' + (!isAuth ? s.container__no__auth : '')}
      style={{ backgroundImage: `url(${banner})` }}
    >
      <div className={s.container__content}>
        <div className={s.info}>
          <h1>Торги. Аукционы. Залоговое имущество</h1>
          <p>Доступный и удобный процесс подбора лотов</p>
        </div>

        <SearchBar />
        <Filters />
        {!isDesktop && (
          <button
            onClick={onSearch}
            disabled={filters.isEmptySideFilters && filters.search.value === ''}
            className={s.btn + ' ' + s.btnSearchMobile}
          >
            Найти
          </button>
        )}
      </div>
      {!isAuth && (
        <Banner
          title={dataBanner?.header || ''}
          description={dataBanner?.text || ''}
          action={{
            label: 'Зарегистрироваться',
            onClick: handleLoginButtonClick,
          }}
          className={s.banner}
          loading={!Boolean(dataBanner)}
        />
      )}
    </div>
  );
});
