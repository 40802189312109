import { Checkbox, CheckboxProps, Empty, Input, Pagination, Popover, Typography } from 'antd';
import React from 'react';
import s from './Table.module.scss';
import { ServiceModel } from 'models/ServiceModel';
import { DateFormat } from 'entities/dateFormat';
import { formatDate } from 'utils/getFormattedDate';
import { ServiceStatus, STAUS_TEXT } from 'entities/services/services';
import { SearchOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react-lite';
import { debounce } from '@kts-front/utils';
import { ValueModel } from 'models/ValueModel';

export const TableSection = observer(
  ({
    title,
    className = '',
    data,
    loading,
    total = 0,
    page = 1,
    setPage,
    onChangeSearch,
    filters,
    orderingDate,
  }: {
    title?: string;
    className?: string;
    data: ServiceModel[];
    loading?: boolean;
    total?: number;
    setPage?: (page: number) => void;
    page?: number;
    search?: string;
    onChangeSearch?: (value: string) => void;
    filters: ValueModel<{ [key in ServiceStatus]: boolean }>;
    orderingDate: ValueModel<'-request_date' | 'request_date' | null>;
  }) => {
    const onChange: CheckboxProps['onChange'] = (e) => {
      filters.change({ ...filters.value, [e.target.id as ServiceStatus]: e.target.checked });
    };

    const onToggleOrder = () => {
      if (orderingDate.value === null || orderingDate.value === 'request_date') {
        orderingDate.change('-request_date');
      } else {
        orderingDate.change('request_date');
      }
    };

    const handleSearchChange = debounce({
      timeout: 300,
      func: (e: React.ChangeEvent<HTMLInputElement>): void => {
        onChangeSearch && onChangeSearch(e.target.value);
      },
    });

    return (
      <div className={`  ${className}`}>
        {title && <Typography.Title level={2}>{title}</Typography.Title>}
        <Input
          size="large"
          placeholder="Номер объекта/лота, название услуги"
          prefix={<SearchOutlined className={s.search__icon} />}
          className={s.search}
          onChange={handleSearchChange}
        />
        <div className="ant-table-container">
          <div className="ant-table-content" style={{ overflow: 'auto hidden' }}>
            <table className={s.table}>
              <thead className={s.table__thead}>
                <tr>
                  <th className={s['table-cell']} scope="col">
                    Номер <span className={s.showDesctop}>объекта/лота</span>
                  </th>
                  <th className={`${s['table-cell']} ${s['table-cell_pd0']}`} scope="col">
                    <div role="button" className={s.order_action} onClick={onToggleOrder}>
                      <span>
                        Дата <span className={s.showDesctop}>заказа</span>
                      </span>
                      <span className={s.order}>
                        <svg
                          width="11"
                          height="11"
                          viewBox="0 0 11 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className={orderingDate.value === 'request_date' ? s.orderActive : ''}
                        >
                          <path
                            d="M9.6921 7.63879L5.72356 3.03702C5.60997 2.9053 5.39124 2.9053 5.27644 3.03702L1.3079 7.63879C1.16047 7.81039 1.2934 8.06174 1.53146 8.06174H9.46854C9.7066 8.06174 9.83953 7.81039 9.6921 7.63879Z"
                            fill="black"
                            fillOpacity="0.25"
                          />
                        </svg>
                        <svg
                          width="11"
                          height="11"
                          viewBox="0 0 11 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className={orderingDate.value === '-request_date' ? s.orderActive : ''}
                        >
                          <path
                            d="M9.46854 2.93825H1.53146C1.2934 2.93825 1.16047 3.1896 1.3079 3.3612L5.27644 7.96297C5.39003 8.09469 5.60876 8.09469 5.72356 7.96297L9.6921 3.3612C9.83953 3.1896 9.7066 2.93825 9.46854 2.93825Z"
                            fill="black"
                            fillOpacity="0.25"
                          />
                        </svg>
                      </span>
                    </div>
                  </th>
                  <th className={`${s['table-cell']} ${s['table-cell_pd0']}`} scope="col">
                    <Popover
                      className={s.select}
                      overlayClassName={s.popoverNoPadding}
                      placement="bottom"
                      content={
                        <div className={s.select__list}>
                          <Checkbox
                            onChange={onChange}
                            checked={filters.value[ServiceStatus.received]}
                            id={ServiceStatus.received}
                          >
                            Принят
                          </Checkbox>
                          <Checkbox
                            onChange={onChange}
                            checked={filters.value[ServiceStatus.in_progress]}
                            id={ServiceStatus.in_progress}
                          >
                            В работе
                          </Checkbox>
                          <Checkbox
                            onChange={onChange}
                            checked={filters.value[ServiceStatus.completed]}
                            id={ServiceStatus.completed}
                          >
                            Готов
                          </Checkbox>
                          <Checkbox
                            onChange={onChange}
                            checked={filters.value[ServiceStatus.incomplete]}
                            id={ServiceStatus.incomplete}
                          >
                            Ожидание оплаты
                          </Checkbox>
                          <Checkbox
                            onChange={onChange}
                            checked={filters.value[ServiceStatus.paid]}
                            id={ServiceStatus.paid}
                          >
                            Оплачено
                          </Checkbox>
                        </div>
                      }
                      arrow={false}
                    >
                      <div className={s.select__label}>
                        <span>
                          Статус <span className={s.showDesctop}>заказа</span>
                        </span>
                        <span>
                          <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M10.8934 0.571426H9.88891C9.82061 0.571426 9.75632 0.604909 9.71614 0.659819L5.91123 5.90446L2.10632 0.659819C2.06614 0.604909 2.00186 0.571426 1.93355 0.571426H0.92909C0.842036 0.571426 0.791143 0.670534 0.842036 0.741516L5.56436 7.25178C5.73579 7.4875 6.08668 7.4875 6.25677 7.25178L10.9791 0.741516C11.0313 0.670534 10.9804 0.571426 10.8934 0.571426Z"
                              fill="black"
                              fillOpacity="0.25"
                            />
                          </svg>
                        </span>
                      </div>
                    </Popover>
                  </th>
                  <th className={s['table-cell']} scope="col">
                    Стоимость <span className={s.showDesctop}>услуги</span>
                  </th>
                  <th className={s['table-cell']} scope="col">
                    Документ
                  </th>
                </tr>
              </thead>
              <tbody className={s['table-tbody']} data-table={data.length}>
                {data.map((item, index) => (
                  <tr className={s['table-row']} key={index}>
                    <td className={s['table-cell']}>
                      <a href={'/lots/' + item.lot} target="_blank">
                        {item.lot}
                      </a>
                    </td>
                    <td className={s['table-cell']}>
                      <span>{formatDate(item.request_date, DateFormat.date)}</span>
                    </td>
                    <td className={s['table-cell']}>
                      <span className={s.cell__row}>
                        <span className={`${s.status} ${s[item.status]}`}></span> {STAUS_TEXT[item.status]}
                      </span>
                    </td>
                    <td className={s['table-cell']}>{item.cost} ₽</td>
                    <td className={s['table-cell']}></td>
                  </tr>
                ))}
              </tbody>
            </table>
            {data.length === 0 && !loading && (
              <div className={`${s['table-row']} ${s.tableEmpty}`} key={'table-empty'}>
                <Empty />
              </div>
            )}
            {total > 10 && setPage && (
              <Pagination
                className={s.pagination}
                align="end"
                defaultCurrent={1}
                current={page}
                total={total || 0}
                onChange={(page) => {
                  setPage(page);
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  },
);
