export enum ServiceStatus {
  received = 'received',
  in_progress = 'in_progress',
  completed = 'completed',
  incomplete = 'incomplete',
  paid = 'paid',
}

export const STAUS_TEXT = {
  [ServiceStatus.completed]: 'Готов',
  [ServiceStatus.in_progress]: 'В работе',
  [ServiceStatus.received]: 'Принят',
  [ServiceStatus.paid]: 'Оплачено',
  [ServiceStatus.incomplete]: 'Ожидание оплаты',
};

export enum ServicesSlugStatic {
  online = 'online',
  audit_legal = 'audit-legal',
  escort = 'escort',
}

export interface IServicesData {
  id: number;
  cost: string;
  request_date: string;
  status: ServiceStatus;
  user: number;
  service: number;
  user_tariff: number;
  lot: number;
  slug: string;
}

export interface IAdditionalServiceData {
  id: number;
  cost: string | null;
  name: string;
  ordering: number;
  image: string;
  slug: string;
}
