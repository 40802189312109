import * as React from 'react';
import s from './SideFilters.module.scss';
import { Area } from './Filters/Area';
import { AuctionType } from './Filters/AuctionType';
import { FormType } from './Filters/FormType';
import { ObjectType } from './Filters/ObjectType';
import { Organizer } from './Filters/Organizer';
import { Price } from './Filters/Price';
import { Status } from './Filters/Status';
import { ActionButtons } from './ActionButtons';
import { useWidth } from 'hooks/useWidth';
import { RobotLotAction } from './RobotAction/RobotAction';
import { Floor } from './Filters/Floor';
import { AmountFloors } from './Filters/AmountFloors';
import { Geography } from './Filters/Geography';
import { Sections } from './Filters/Sections';
import { SourceArea } from './Filters/SourceArea';
import { CadastralNumber } from './Filters/CadastralNumber';

type SideFiltersProps = {
  onFiltersClose: VoidFunction;
  className?: string;
};

const SideFilters = ({ onFiltersClose, className = '' }: SideFiltersProps): React.ReactElement<SideFiltersProps> => {
  const { width } = useWidth();
  const device = React.useMemo(() => {
    if (width >= 1280) {
      return {
        desktop: true,
        tablet: false,
        mobile: false,
      };
    } else if (width >= 768 && width < 1280) {
      return {
        desktop: false,
        tablet: true,
        mobile: false,
      };
    }

    return {
      desktop: false,
      tablet: false,
      mobile: true,
    };
  }, [width]);

  return (
    <div className={`${s.filtersGrid} ${className}`}>
      <AuctionType />
      <ObjectType />
      <Area />
      {/* */}
      <FormType />
      <Status />
      <Organizer />
      {!device.desktop && (
        <>
          <Price />
          <Geography placeholder="Регион" />
        </>
      )}
      <SourceArea />
      <Sections />
      <CadastralNumber />
      <Floor />
      <AmountFloors />
      <RobotLotAction />
      {device.desktop && (
        <>
          <div></div>
          <div></div>
        </>
      )}

      {!device.desktop && device.tablet && <div></div>}

      <div>
        <ActionButtons onFiltersClose={onFiltersClose} />
      </div>
    </div>
  );
};

export default SideFilters;
