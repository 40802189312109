import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { BaseInput } from 'components/BaseInput';
import { MultiSelect } from 'components/MultiSelect';
import { useLotListStore } from 'stores/LotListCatalogStore/context';

type ObjectTypeProps = {
  className?: string;
};

const ObjectType = ({ className }: ObjectTypeProps): React.ReactElement<ObjectTypeProps> => {
  const { filters } = useLotListStore();

  return (
    <BaseInput className={className} title="Тип">
      <MultiSelect
        options={filters.objectType.options}
        selectedOptions={filters.objectType.selectedOptions}
        value={filters.objectType.selectedValue.value}
        onChange={filters.objectType.toggleValue}
        onSearch={filters.objectType.handleSearch}
        searchValue={filters.objectType.searchValue.value}
        isOptionsLoading={filters.loadingStage.isLoading}
        dropdownStyle={{
          zIndex: 1300,
        }}
      />
    </BaseInput>
  );
};

export default observer(ObjectType);
