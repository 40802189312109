import { CloseOutlined } from '@ant-design/icons';
import animateScrollTo from 'animated-scroll-to';
import { Button } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import { AnalyticsEvent } from 'entities/analytics';
import { useRootStore } from 'stores/RootStore';

import s from './ActionButtons.module.scss';

type ActionButtonsProps = {
  isEmptySideFilters: boolean;
  onClearSideFilters: () => void;
  onSetSideFilters: () => void;
  isLoading: boolean;
  className?: string;
};

const ActionButtons = ({
  isEmptySideFilters,
  onClearSideFilters,
  onSetSideFilters,
  isLoading,
  className,
}: ActionButtonsProps): React.ReactElement<ActionButtonsProps> => {
  const { analyticsStore } = useRootStore();

  const handleSubmit = () => {
    onSetSideFilters();
    animateScrollTo(0, { elementToScroll: window });
    analyticsStore.trackEvent({ event: AnalyticsEvent.applyFilters });
  };

  return (
    <div className={cn(s['action-buttons'], className, isEmptySideFilters && s.end)}>
      <Button
        className={cn(s['action-buttons__button'], s['action-buttons__button-show'])}
        type="primary"
        onClick={handleSubmit}
        loading={isLoading}
        disabled={isLoading}
      >
        Применить
      </Button>
      {!isEmptySideFilters && (
        <button
          className={cn(s['action-buttons__button'], s['action-buttons__button-clear'])}
          onClick={onClearSideFilters}
        >
          Сбросить фильтры
          <CloseOutlined />
        </button>
      )}
    </div>
  );
};

export default React.memo(ActionButtons);
