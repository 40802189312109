import React from 'react';
import s from './TextInfo.module.scss';

export const TextInfo = ({
  image,
  title,
  description,
  action,
  className = '',
  reverse = false,
  clxs,
  fixed,
}: {
  clxs?: { image?: string; colRight?: string; btn?: string };
  reverse?: boolean;
  image: string;
  title: string;
  description: string;
  action?: { label: string; onClick: () => void };
  className?: string;
  fixed?: boolean;
}) => {
  return (
    <div className={(fixed ? s.item__fixed : s.item) + ' ' + className + ' ' + (reverse ? s.reverse : '')}>
      <div className={s.content}>
        <div>
          <h3>{title}</h3>
          <p dangerouslySetInnerHTML={{ __html: description }} />
        </div>
        {action && (
          <button onClick={action.onClick} className={s.buttonRound + ' ' + s.btn + ' ' + (clxs?.btn ? clxs.btn : '')}>
            {action.label}
          </button>
        )}
      </div>
      <div className={s.colRight + ' ' + (clxs && clxs.colRight ? clxs.colRight : '')}>
        <img src={image} loading="lazy" className={s.image + ' ' + (clxs && clxs.image ? clxs.image : '')} alt="" />
      </div>
    </div>
  );
};
