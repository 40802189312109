import { Typography } from 'antd';
import cn from 'classnames';
import * as React from 'react';
import MaskedInput from 'react-text-mask';
import { convertStringToNumber } from 'utils/convertStringToNumber';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import s from './RangeFilter.module.scss';
import { formatNumberToString } from 'utils/formatNumberToString';
import { fortamtPriceShortV2 } from 'utils/formatPrice';

const defaultMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ' ',
  allowDecimal: true,
  decimalSymbol: '.',
  integerLimit: 15, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

type RangeFilterProps = {
  from: number | null;
  to: number | null;
  valueMin: number | null;
  valueMax: number | null;
  onChangeMin: (v: number | null) => void;
  onChangeMax: (v: number | null) => void;
  placeholderPostfix?: string;
  className?: string;
  placeholderSufix?: {
    from: string;
    to: string;
  };
  placeholderPrefixInput?: {
    from: string;
    to: string;
  };
  placeholderTo?: number;
  seporatorHide?: boolean;
  flat?: boolean;
  size?: 'default' | 'big';
  placeholder?: {
    from: string;
    to: string;
  };
  focused?: boolean;
};

const RangeFilterPrice = ({
  from,
  to,
  valueMin,
  valueMax,
  onChangeMin,
  onChangeMax,
  placeholderPostfix,
  className,
  seporatorHide = false,
  flat = false,
  size = 'default',
  focused = false,
}: RangeFilterProps): React.ReactElement<RangeFilterProps> => {
  const [focusFrom, setFocusFrom] = React.useState(false);
  const [focusTo, setFocusTo] = React.useState(false);
  const handleChangeMin = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChangeMin(convertStringToNumber(e.target.value.split(' ').join('')));
    },
    [onChangeMin],
  );

  const handleChangeMax = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChangeMax(convertStringToNumber(e.target.value.split(' ').join('')));
    },
    [onChangeMax],
  );

  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
  });

  const valueTo = valueMax ? formatNumberToString(valueMax, { minimumFractionDigits: 0 }) : undefined;
  const valueFrom = valueMin ? formatNumberToString(valueMin, { minimumFractionDigits: 0 }) : undefined;
  const _placeholderTo = 'до ';
  const _placeholderFrom = 'от ';
  const isPlaceholderFrom = 'от ' + (valueMin || '') !== 'от ';
  const isPlaceholderTo = 'до ' + (valueMax || '') !== 'до ';

  return (
    <div className={cn(flat ? s['range-filter-flat'] : s['range-filter'], className)}>
      <div className={s.wrap__input + ' ' + s.border__right}>
        {placeholderPostfix ? (
          <span className={s.prefix + ' ' + (focusFrom || isPlaceholderFrom ? s.focus : '')}>{placeholderPostfix}</span>
        ) : undefined}
        <MaskedInput
          mask={currencyMask}
          onChange={handleChangeMin}
          placeholder={
            !focusFrom ? (valueMin ? 'от ' + fortamtPriceShortV2(valueMin) : _placeholderFrom) : _placeholderFrom
          }
          value={focusFrom ? valueFrom : undefined}
          className={cn(s['range__input__' + size], s['range-filter__input'], isPlaceholderFrom && s.active)}
          onFocus={() => {
            if (focused) {
              setFocusFrom(true);
            }
          }}
          onBlur={(e) => {
            setFocusFrom(false);
            e.target.value = '';
          }}
        />
      </div>

      {!seporatorHide && <Typography.Text className={s['range-filter__dash']}>–</Typography.Text>}
      <div className={s.wrap__input} data-test={focusTo}>
        {placeholderPostfix ? (
          <span className={s.prefix + ' ' + (focusTo || isPlaceholderTo ? s.focus : '')}>{placeholderPostfix}</span>
        ) : undefined}
        <MaskedInput
          mask={currencyMask}
          onChange={handleChangeMax}
          placeholder={!focusTo ? (valueMax ? 'до ' + fortamtPriceShortV2(valueMax) : _placeholderTo) : _placeholderTo}
          value={focusTo ? valueTo : undefined}
          className={cn(s['range__input__' + size], s['range-filter__input'], isPlaceholderTo && s.active)}
          onFocus={() => {
            setFocusTo(true);
          }}
          onBlur={(e) => {
            setFocusTo(false);
            e.target.value = '';
          }}
        />
      </div>
    </div>
  );
};

export default React.memo(RangeFilterPrice);
