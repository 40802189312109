import { computed, makeObservable, runInAction } from 'mobx';

import { apiUrls } from 'config/apiUrls';
import { AnalyticsEvent } from 'entities/analytics';
import { BidType } from 'entities/bid';
import { DateFormat } from 'entities/dateFormat';
import {
  lotSourceConfig,
  LotServer,
  LotPublicServer,
  ILotClient,
  LotBids,
  ILotPublic,
  ILotBase,
  LotBaseServer,
} from 'entities/lot';
import { LocalStore } from 'stores/LocalStore';
import { IRootStore } from 'stores/RootStore';
import { AppEvent } from 'types/appEvent';
import { BaseResponse } from 'types/meta';
import { apiCustom } from 'utils/api';
import { formatNumberToString } from 'utils/formatNumberToString';
import { formatPrice } from 'utils/formatPrice';
import { formatDate } from 'utils/getFormattedDate';

import { ImageModel } from './ImageModel';
import { LoadingStageModel } from './LoadingStageModel';
import { TimelineModel } from './TimelineModel';
import { ValueModel } from './ValueModel';

export class LotBaseModel extends LocalStore implements ILotBase {
  readonly id;
  readonly title;
  readonly objectType;
  readonly city;
  readonly externalId;
  readonly priceMin;
  readonly priceStep;
  readonly priceStepOfTotal;
  readonly totalArea;
  readonly floor;
  readonly amountOfFloors;
  readonly entrance;
  readonly lotUrl;
  readonly address;
  readonly description;
  readonly status;
  readonly source;
  readonly sourceIcon;
  readonly sourceTitle;
  readonly auctionType;
  readonly auctionFormat;
  readonly images;
  readonly dateAdded;
  readonly auctionOrganizer;
  readonly viewsCount;
  readonly isDowngradeAuction;
  readonly timelineModel: TimelineModel;
  readonly documents;
  private readonly rootStore: IRootStore;

  constructor(
    {
      id,
      title,
      objectType,
      city,
      externalId,
      priceMin,
      priceStep,
      priceStepOfTotal,
      totalArea,
      floor,
      auctionFormat,
      entrance,
      lotUrl,
      address,
      description,
      status,
      source,
      sourceIcon,
      sourceTitle,
      auctionType,
      images,
      amountOfFloors,
      auctionOrganizer,
      timelineModel,
      dateAdded,
      viewsCount,
      isDowngradeAuction,
      documents,
    }: ILotPublic,
    rootStore: IRootStore,
  ) {
    super();
    this.documents = documents;
    this.id = id;
    this.title = title;
    this.objectType = objectType;
    this.city = city;
    this.externalId = externalId;
    this.priceMin = priceMin;
    this.priceStep = priceStep;
    this.priceStepOfTotal = priceStepOfTotal;
    this.totalArea = totalArea;
    this.floor = floor;
    this.amountOfFloors = amountOfFloors;
    this.entrance = entrance;
    this.lotUrl = lotUrl;
    this.address = address;
    this.description = description;
    this.status = status;
    this.source = source;
    this.sourceIcon = sourceIcon;
    this.sourceTitle = sourceTitle;
    this.auctionType = auctionType;
    this.auctionFormat = auctionFormat;
    this.images = images;
    this.dateAdded = dateAdded;
    this.auctionOrganizer = auctionOrganizer;
    this.timelineModel = timelineModel;
    this.viewsCount = viewsCount;
    this.rootStore = rootStore;
    this.isDowngradeAuction = isDowngradeAuction;
    this.rootStore = rootStore;
    makeObservable(this, {
      priceMinFormatted: computed,
      totalAreaFormatted: computed,
    });
  }

  static async fromApi(id: number, rootStore: IRootStore): Promise<BaseResponse<LotBaseModel>> {
    const response = await apiCustom<LotBaseServer>({
      url: apiUrls.lot(id),
    });

    if (response.isError) {
      return {
        isError: response.isError,
      };
    }

    return {
      data: LotBaseModel.fromJson(response.data, rootStore),
      isError: false,
    };
  }

  static fromJson(raw: LotBaseServer, rootStore: IRootStore): LotBaseModel {
    const timeline = TimelineModel.fromJson(raw.timeline);
    return new LotBaseModel(
      {
        ...raw,
        sourceIcon: lotSourceConfig[raw.source] ? lotSourceConfig[raw.source].icon : lotSourceConfig['empty'].icon,
        sourceTitle: lotSourceConfig[raw.source] ? lotSourceConfig[raw.source].title : lotSourceConfig['empty'].title,
        objectType: raw.object_type,
        externalId: raw.external_id,
        priceMin: raw.price_min,
        priceStep: raw.price_step,
        priceStepOfTotal:
          raw.price_min !== null && raw.price_min !== 0 && raw.price_step !== null
            ? (raw.price_step / raw.price_min) * 100
            : null,
        totalArea: raw.total_area,
        amountOfFloors: raw.amount_of_floors,
        lotUrl: '',
        auctionType: raw.auction_type,
        auctionFormat: raw.auction_format,
        auctionOrganizer: raw?.auction_organizer ? raw.auction_organizer : null,
        timelineModel: timeline,
        viewsCount: raw.views_count,
        image_count: raw.image_count,
        dateAdded: raw.created_at ? formatDate(raw.created_at, DateFormat.date) : '',
        images: raw.images ? raw.images.map((image, index) => ImageModel.fromJson(image, index)) : [],
        isDowngradeAuction: raw.is_downgrade_auction,
      },
      rootStore,
    );
  }

  get priceMinFormatted(): string | null {
    return this.priceMin !== null ? formatPrice(this.priceMin) : null;
  }

  get totalAreaFormatted(): string | null {
    return this.totalArea ? formatNumberToString(this.totalArea) : null;
  }
}

export class LotPublicModel extends LotBaseModel {
  readonly image_count;
  constructor(lot: ILotPublic, rootStore: IRootStore) {
    super(lot, rootStore);
    this.image_count = lot.image_count;
    makeObservable(this, {});
  }

  static async fromApi(id: number, rootStore: IRootStore): Promise<BaseResponse<LotPublicModel>> {
    const response = await apiCustom<LotPublicServer>({
      url: apiUrls.lot(id),
    });

    if (response.isError) {
      return {
        isError: response.isError,
      };
    }

    return {
      data: LotPublicModel.fromJson(response.data, rootStore),
      isError: false,
    };
  }

  static fromJson(raw: LotPublicServer, rootStore: IRootStore): LotPublicModel {
    const timeline = TimelineModel.fromJson(raw.timeline);
    return new LotPublicModel(
      {
        ...raw,
        sourceIcon: lotSourceConfig[raw.source] ? lotSourceConfig[raw.source].icon : lotSourceConfig['empty'].icon,
        sourceTitle: lotSourceConfig[raw.source] ? lotSourceConfig[raw.source].title : lotSourceConfig['empty'].title,
        objectType: raw.object_type,
        externalId: raw.external_id,
        priceMin: raw.price_min,
        priceStep: raw.price_step,
        priceStepOfTotal:
          raw.price_min !== null && raw.price_min !== 0 && raw.price_step !== null
            ? (raw.price_step / raw.price_min) * 100
            : null,
        totalArea: raw.total_area,
        amountOfFloors: raw.amount_of_floors,
        lotUrl: '',
        auctionType: raw.auction_type,
        auctionFormat: raw.auction_format,
        auctionOrganizer: raw.auction_organizer,
        timelineModel: timeline,
        viewsCount: raw.views_count,
        image_count: raw.image_count,
        dateAdded: raw.created_at ? formatDate(raw.created_at, DateFormat.date) : '',
        images: raw.images ? raw.images.map((image, index) => ImageModel.fromJson(image, index)) : [],
        isDowngradeAuction: raw.is_downgrade_auction,
      },
      rootStore,
    );
  }
}

export class LotModel extends LocalStore implements ILotClient {
  readonly id;
  readonly title;
  readonly objectType;
  readonly city;
  readonly externalId;
  readonly priceMin;
  readonly priceStep;
  readonly priceStepOfTotal;
  readonly totalArea;
  readonly floor;
  readonly amountOfFloors;
  readonly entrance;
  readonly lotUrl;
  readonly address;
  readonly description;
  readonly status;
  readonly source;
  readonly sourceIcon;
  readonly sourceTitle;
  readonly auctionType;
  readonly auctionFormat;
  readonly images;
  readonly documents;
  readonly dateAdded;
  readonly auctionOrganizer;
  readonly viewsCount;
  readonly isFavorite;
  readonly isSubscribed;
  readonly cutoffPrice;
  readonly priceDecreaseStep;
  readonly isDowngradeAuction;
  readonly isInFavorite: ValueModel<boolean>;
  readonly isInSubscribed: ValueModel<boolean>;
  readonly timelineModel: TimelineModel;
  readonly isInvestBidExisted: ValueModel<boolean>;
  readonly isFollowBidExisted: ValueModel<boolean>;

  readonly toggleFavoriteStage: LoadingStageModel = new LoadingStageModel();
  readonly addFavoriteStage: LoadingStageModel = new LoadingStageModel();
  readonly toggleSubscriptionStage: LoadingStageModel = new LoadingStageModel();
  readonly addSubscriptionStage: LoadingStageModel = new LoadingStageModel();

  private readonly rootStore: IRootStore;

  constructor(
    {
      id,
      title,
      objectType,
      city,
      externalId,
      priceMin,
      priceStep,
      priceStepOfTotal,
      totalArea,
      floor,
      auctionFormat,
      entrance,
      lotUrl,
      address,
      description,
      status,
      source,
      sourceIcon,
      sourceTitle,
      auctionType,
      images,
      documents,
      amountOfFloors,
      auctionOrganizer,
      timelineModel,
      dateAdded,
      viewsCount,
      isFavorite,
      isSubscribed,
      cutoffPrice,
      priceDecreaseStep,
      isDowngradeAuction,
    }: ILotClient,
    isBidExisted: LotBids,
    rootStore: IRootStore,
  ) {
    super();

    this.id = id;
    this.title = title;
    this.objectType = objectType;
    this.city = city;
    this.externalId = externalId;
    this.priceMin = priceMin;
    this.priceStep = priceStep;
    this.priceStepOfTotal = priceStepOfTotal;
    this.totalArea = totalArea;
    this.floor = floor;
    this.amountOfFloors = amountOfFloors;
    this.entrance = entrance;
    this.lotUrl = lotUrl;
    this.address = address;
    this.description = description;
    this.status = status;
    this.source = source;
    this.sourceIcon = sourceIcon;
    this.sourceTitle = sourceTitle;
    this.auctionType = auctionType;
    this.auctionFormat = auctionFormat;
    this.images = images;
    this.documents = documents;
    this.dateAdded = dateAdded;
    this.isFavorite = isFavorite;
    this.isInFavorite = new ValueModel(isFavorite);
    this.auctionOrganizer = auctionOrganizer;
    this.timelineModel = timelineModel;
    this.viewsCount = viewsCount;
    this.rootStore = rootStore;
    this.isSubscribed = isSubscribed;
    this.isInSubscribed = new ValueModel(isSubscribed);
    this.isInvestBidExisted = new ValueModel<boolean>(isBidExisted.invest);
    this.isFollowBidExisted = new ValueModel<boolean>(isBidExisted.follow);
    this.cutoffPrice = cutoffPrice;
    this.priceDecreaseStep = priceDecreaseStep;
    this.isDowngradeAuction = isDowngradeAuction;

    makeObservable(this, {
      isFollowBidExistedValue: computed,
      isInvestBidExistedValue: computed,
    });
  }

  get priceMinFormatted(): string | null {
    return this.priceMin !== null ? formatPrice(this.priceMin) : null;
  }

  get priceStepFormatted(): string | null {
    return this.priceStep !== null ? formatPrice(this.priceStep) : null;
  }

  get priceStepOfTotalFormatted(): string | null {
    return this.priceStepOfTotal !== null
      ? formatNumberToString(this.priceStepOfTotal, { minimumFractionDigits: 2 })
      : null;
  }

  get cutoffPriceFormatted(): string | null {
    return this.cutoffPrice !== null ? formatPrice(this.cutoffPrice) : null;
  }

  get priceDecreaseStepFormatted(): string | null {
    return this.priceDecreaseStep !== null ? formatPrice(this.priceDecreaseStep) : null;
  }

  get totalAreaFormatted(): string | null {
    return this.totalArea ? formatNumberToString(this.totalArea) : null;
  }

  get formattedBidEndTime(): string {
    if (!this.timelineModel.bidEndTimeStage?.date) {
      return '—';
    }

    const formattedDate = this.timelineModel.bidEndTimeStage?.date.toLocaleString('ru', {
      timeZone: 'Europe/Moscow',
    });

    return formattedDate?.replace(',', ' -').slice(0, -3).concat(' (МСК)');
  }

  get isFollowBidExistedValue(): boolean {
    return this.isFollowBidExisted.value;
  }

  get isInvestBidExistedValue(): boolean {
    return this.isInvestBidExisted.value;
  }

  get lotCardAddress(): string {
    // TODO бэк должен заменить "не указан" на null
    return this.address === 'не указан' ? this.city : this.address;
  }

  get lotPageAddress(): string {
    // TODO бэк должен заменить "не указан" на null
    return this.address === 'не указан' ? 'Не указан' : this.address;
  }

  setIsBidExistedValue = (type: BidType) => {
    switch (type) {
      case BidType.follow: {
        this.isFollowBidExisted.change(true);
        break;
      }
      case BidType.invest: {
        this.isInvestBidExisted.change(true);
        break;
      }
    }
  };

  /**
   * Метод с логикой перед добавлением в избранное
   */
  startToggleFavorite = async (): Promise<void> => {
    if (this.rootStore.authStore.isAuthorizing) {
      return;
    }

    if (!this.rootStore.userStore.authorized) {
      this.publish(AppEvent.lotDraftAddToFavorites, { lotId: this.id });

      return;
    }

    this.toggleFavorite();
  };

  /**
   * Метод добавления или удаления из избранных
   */
  toggleFavorite = async (): Promise<BaseResponse> => {
    if (this.toggleFavoriteStage.isLoading) {
      return { isError: true };
    }

    this.isInFavorite.change(!this.isInFavorite.value);
    this.toggleFavoriteStage.loading();

    const response = await apiCustom({
      url: apiUrls.toggleFavorite(this.id),
      method: 'POST',
    });

    return runInAction(() => {
      if (response.isError) {
        this.toggleFavoriteStage.error();
        this.isInFavorite.change(!this.isInFavorite.value);

        return { isError: true };
      }

      this.toggleFavoriteStage.success();

      if (!this.isInFavorite.value) {
        this.publish(AppEvent.lotRemoveFromFavorites, { lotId: this.id });
        this.rootStore.analyticsStore.trackEvent({
          event: AnalyticsEvent.removeFromFavorites,
          payload: {
            lot_name: this.title,
          },
        });
      } else {
        this.rootStore.analyticsStore.trackEvent({
          event: AnalyticsEvent.addToFavorites,
          payload: {
            lot_name: this.title,
          },
        });
      }

      return { isError: false };
    });
  };

  /**
   * Метод только добавления (не удаления) в избранное
   */
  addToFavorites = async (): Promise<BaseResponse> => {
    if (this.addFavoriteStage.isLoading || this.isInFavorite.value) {
      return { isError: true };
    }

    this.isInFavorite.change(true);
    this.addFavoriteStage.loading();

    const response = await apiCustom({
      url: apiUrls.toggleFavorite(this.id),
      method: 'POST',
    });

    return runInAction(() => {
      if (response.isError) {
        this.addFavoriteStage.error();
        this.isInFavorite.change(false);

        return { isError: true };
      }

      this.addFavoriteStage.success();
      this.rootStore.analyticsStore.trackEvent({
        event: AnalyticsEvent.addToFavorites,
        payload: {
          lot_name: this.title,
        },
      });

      return { isError: false };
    });
  };

  /**
   * Метод с логикой перед подпиской на лот
   */
  startToggleSubscription = async (): Promise<void> => {
    if (this.rootStore.authStore.isAuthorizing) {
      return;
    }

    if (!this.rootStore.userStore.authorized || !this.rootStore.userStore.user?.hasEmail) {
      this.publish(AppEvent.lotDraftSubscribe, { lotId: this.id });

      return;
    }

    this.toggleSubscription();
  };

  /**
   * Метод подписки или отписки от уведомлений по лоту
   */
  toggleSubscription = async (): Promise<BaseResponse> => {
    if (this.toggleSubscriptionStage.isLoading) {
      return { isError: true };
    }

    this.isInSubscribed.change(!this.isInSubscribed.value);
    this.toggleSubscriptionStage.loading();

    const response = await apiCustom({
      url: apiUrls.toggleSubscription(this.id),
      method: 'POST',
    });

    return runInAction(() => {
      if (response.isError) {
        this.toggleSubscriptionStage.error();
        this.isInSubscribed.change(!this.isInSubscribed.value);

        return { isError: true };
      }

      this.toggleSubscriptionStage.success();

      if (!this.isInSubscribed.value) {
        this.publish(AppEvent.lotUnsubscribe, { lotId: this.id });
        this.rootStore.analyticsStore.trackEvent({
          event: AnalyticsEvent.unsubscribe,
          payload: {
            lot_name: this.title,
          },
        });
      } else {
        this.rootStore.analyticsStore.trackEvent({
          event: AnalyticsEvent.subscribe,
          payload: {
            lot_name: this.title,
          },
        });
      }

      return { isError: false };
    });
  };

  /**
   * Метод только подписки (не отписки) на уведомления по лоту
   */
  addToSubscriptions = async (): Promise<BaseResponse> => {
    if (this.addSubscriptionStage.isLoading || this.isInSubscribed.value) {
      return { isError: true };
    }

    this.isInSubscribed.change(true);
    this.addSubscriptionStage.loading();

    const response = await apiCustom({
      url: apiUrls.toggleSubscription(this.id),
      method: 'POST',
    });

    return runInAction(() => {
      if (response.isError) {
        this.addSubscriptionStage.error();
        this.isInSubscribed.change(false);

        return { isError: true };
      }

      this.addSubscriptionStage.success();
      this.rootStore.analyticsStore.trackEvent({
        event: AnalyticsEvent.subscribe,
        payload: {
          lot_name: this.title,
        },
      });

      return { isError: false };
    });
  };

  /**
   * Метод получения объекта лота
   *
   * Необходим для кейсов, когда после авторизации пользователя нужно узнать
   * актуальную информацию о наличии лота в избранных и подписках
   */
  static async fromApi(id: number, rootStore: IRootStore): Promise<BaseResponse<LotModel>> {
    const response = await apiCustom<LotServer>({
      url: apiUrls.lot(id),
    });

    if (response.isError) {
      return {
        isError: response.isError,
      };
    }

    return {
      data: LotModel.fromJson(response.data, rootStore),
      isError: false,
    };
  }

  reset = (): void => {
    this.isInSubscribed.change(false);
    this.isInFavorite.change(false);
    this.isInvestBidExisted.change(false);
    this.isFollowBidExisted.change(false);
  };

  static fromJson(raw: LotServer, rootStore: IRootStore): LotModel {
    const timeline = TimelineModel.fromJson(raw.timeline);

    return new LotModel(
      {
        ...raw,
        sourceIcon: lotSourceConfig[raw.source] ? lotSourceConfig[raw.source].icon : lotSourceConfig['empty'].icon,
        sourceTitle: lotSourceConfig[raw.source] ? lotSourceConfig[raw.source].title : lotSourceConfig['empty'].title,
        objectType: raw.object_type,
        externalId: raw.external_id,
        priceMin: raw.price_min,
        priceStep: raw.price_step,
        priceStepOfTotal:
          raw.price_min !== null && raw.price_min !== 0 && raw.price_step !== null
            ? (raw.price_step / raw.price_min) * 100
            : null,
        totalArea: raw.total_area,
        amountOfFloors: raw.amount_of_floors,
        lotUrl: raw.lot_url === 'Не указано' ? null : raw.lot_url,
        auctionType: raw.auction_type,
        auctionFormat: raw.auction_format,
        auctionOrganizer: raw?.auction_organizer ? raw.auction_organizer : null,
        timelineModel: timeline,
        viewsCount: raw.views_count,
        dateAdded: raw.created_at ? formatDate(raw.created_at, DateFormat.date) : '',
        images: raw.images ? raw.images.map((image, index) => ImageModel.fromJson(image, index)) : [],
        isFavorite: raw.is_favorite,
        isSubscribed: raw.is_subscribed,
        cutoffPrice: raw.cutoff_price,
        priceDecreaseStep: raw.price_decrease_step,
        isDowngradeAuction: raw.is_downgrade_auction,
      },
      raw.is_bid_existed ?? { [BidType.follow]: false, [BidType.invest]: false },
      rootStore,
    );
  }
}
