import * as React from 'react';
import { BaseIconProps } from '../commonProps';

const UserIcon = (props: BaseIconProps): React.ReactElement<BaseIconProps> => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
      <path
        d="M17.4723 12.9484C16.5925 13.5362 15.5582 13.85 14.5001 13.85C13.0816 13.8486 11.7216 13.2845 10.7186 12.2814C9.71556 11.2784 9.15144 9.91841 9.15 8.49993C9.15001 7.44182 9.46379 6.40748 10.0516 5.5277C10.6395 4.6479 11.4751 3.96218 12.4526 3.55725C13.4302 3.15232 14.5059 3.04637 15.5437 3.2528C16.5815 3.45923 17.5348 3.96877 18.283 4.71698C19.0312 5.46519 19.5408 6.41847 19.7472 7.45627C19.9536 8.49407 19.8477 9.56977 19.4428 10.5474C19.0378 11.5249 18.3521 12.3605 17.4723 12.9484ZM16.6204 5.32656C15.9928 4.90718 15.2549 4.68334 14.5 4.68334C13.4878 4.68334 12.517 5.08545 11.8012 5.80121C11.0854 6.51697 10.6833 7.48776 10.6833 8.5C10.6833 9.25487 10.9072 9.99278 11.3266 10.6204C11.7459 11.2481 12.342 11.7373 13.0394 12.0261C13.7368 12.315 14.5042 12.3906 15.2446 12.2433C15.985 12.0961 16.665 11.7326 17.1988 11.1988C17.7326 10.665 18.0961 9.98496 18.2433 9.2446C18.3906 8.50423 18.315 7.73683 18.0261 7.03943C17.7373 6.34202 17.2481 5.74594 16.6204 5.32656Z"
        fill="currentColor"
        stroke="#E6EEFF"
        stroke-width="0.3"
      />
      <path
        d="M8.45247 18.4805C9.92427 16.9903 11.9194 16.1524 14 16.15C16.0806 16.1524 18.0757 16.9903 19.5475 18.4805C21.0194 19.9708 21.8477 21.9918 21.85 24.1002C21.85 24.2996 21.7717 24.4905 21.6329 24.631C21.4942 24.7714 21.3065 24.85 21.1111 24.85C20.9158 24.85 20.728 24.7714 20.5893 24.631C20.4505 24.4905 20.3722 24.2995 20.3722 24.1C20.3722 22.39 19.7013 20.7496 18.5065 19.5398C17.3116 18.33 15.6906 17.65 14 17.65C12.3094 17.65 10.6884 18.33 9.4935 19.5398C8.29869 20.7496 7.62778 22.39 7.62778 24.1C7.62778 24.2995 7.54948 24.4905 7.41071 24.631C7.27199 24.7714 7.08425 24.85 6.88889 24.85C6.69353 24.85 6.50579 24.7714 6.36707 24.631C6.22831 24.4905 6.15002 24.2996 6.15 24.1001C6.15233 21.9917 6.98058 19.9708 8.45247 18.4805Z"
        fill="currentColor"
        stroke="#E6EEFF"
        stroke-width="0.3"
      />
    </svg>
  );
};

export default UserIcon;
