export const getCurrentPage = (total?: number | null, offset?: number | null) => {
  const _total = total || 0;
  const _offset = offset || 0;
  if (_total === 0 || _offset === 0) {
    return 1;
  }
  return _offset / 10 + 1;
};

export const PHONE = {
  mask: '+7 (495) 773-33-73',
  clear: '+74957733373',
};

export const EMAILS = {
  service: 'services@kupilot.ru',
  support: 'support@kupilot.ru',
  info: 'info@kupilot.ru',
  partners: 'partners@kupilot.ru',
};
