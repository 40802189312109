import * as React from 'react';
import { BaseIconProps } from '../commonProps';

const InIcon = (props: BaseIconProps): React.ReactElement<BaseIconProps> => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
      <path
        d="M3.89469 13.2823L17.9084 13.2549C17.9117 13.2558 17.9156 13.2567 17.9197 13.2576C17.9298 13.2597 17.9435 13.2618 17.9597 13.2618H18.2291L18.0873 13.0328C18.0056 12.901 17.9079 12.7793 17.7963 12.6702L17.7952 12.6692L14.3268 9.34524C14.1925 9.2115 14.1193 9.03398 14.121 8.85106C14.1227 8.66772 14.1994 8.491 14.3367 8.35935C14.4743 8.2275 14.6617 8.15167 14.8589 8.15003C15.0556 8.14839 15.244 8.22077 15.3839 8.34984L18.5828 11.4155H18.5793L18.8487 11.6737C19.4906 12.2891 19.85 13.1222 19.85 13.9895C19.85 14.8567 19.4906 15.6898 18.8487 16.3052L15.3794 19.63L15.3793 19.63L15.3775 19.6318C15.3091 19.6997 15.227 19.7541 15.1357 19.7917C15.0444 19.8293 14.9461 19.8491 14.8463 19.85C14.7466 19.8508 14.6479 19.8326 14.5559 19.7965C14.4639 19.7605 14.3808 19.7074 14.3112 19.6407C14.2416 19.5739 14.1869 19.495 14.15 19.4085C14.1131 19.3221 14.0946 19.2297 14.0954 19.1368C14.0963 19.0438 14.1164 18.9517 14.1549 18.8659C14.1934 18.7801 14.2495 18.702 14.3204 18.6364L14.3204 18.6364L14.3223 18.6346L17.7916 15.3098L17.7916 15.3098L17.7934 15.308C17.9224 15.1803 18.0318 15.0353 18.1182 14.8775L18.2501 14.6364L17.976 14.6558C17.9592 14.657 17.9448 14.6599 17.9341 14.6624C17.9257 14.6645 17.9171 14.6669 17.9127 14.6682L17.911 14.6687C17.9103 14.6689 17.9097 14.669 17.9092 14.6692L3.8944 14.6966C3.89438 14.6966 3.89437 14.6966 3.89435 14.6966C3.69493 14.6966 3.5049 14.6206 3.36575 14.4873C3.22681 14.3541 3.15 14.1749 3.15 13.9895C3.15 13.804 3.22681 13.6248 3.36575 13.4917C3.50491 13.3583 3.69496 13.2823 3.8944 13.2823L3.89469 13.2823Z"
        fill="currentColor"
        stroke="#E6EEFF"
        stroke-width="0.3"
      />
      <path
        d="M20.625 4.68333L18.875 4.68333C18.685 4.68333 18.5013 4.60434 18.3648 4.46128C18.228 4.31798 18.15 4.12219 18.15 3.91667C18.15 3.71114 18.228 3.51536 18.3648 3.37205C18.5013 3.22899 18.685 3.15 18.875 3.15L20.6248 3.15C21.7427 3.1514 22.8159 3.61718 23.6086 4.44761C24.4015 5.27827 24.8487 6.40591 24.85 7.58351L24.85 20.4166C24.8486 21.5942 24.4014 22.7218 23.6086 23.5524C22.8159 24.3828 21.7427 24.8486 20.6249 24.85L18.875 24.85C18.685 24.85 18.5013 24.771 18.3648 24.6279C18.228 24.4846 18.15 24.2889 18.15 24.0833C18.15 23.8778 18.228 23.682 18.3648 23.5387C18.5013 23.3957 18.685 23.3167 18.875 23.3167L20.625 23.3167C21.3633 23.3167 22.0698 23.0094 22.5897 22.4648C23.1092 21.9204 23.4 21.1836 23.4 20.4167L23.4 7.58333C23.4 6.8164 23.1093 6.07955 22.5897 5.53522C22.0698 4.99065 21.3633 4.68333 20.625 4.68333Z"
        fill="currentColor"
        stroke="#E6EEFF"
        stroke-width="0.3"
      />
    </svg>
  );
};

export default InIcon;
