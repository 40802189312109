import { action, makeObservable, reaction } from 'mobx';

import { apiUrls } from 'config/apiUrls';
import { LotServer } from 'entities/lot';
import { ListModel } from 'models/ListModel';
import { LoadingStageModel } from 'models/LoadingStageModel';
import { LotModel } from 'models/LotModel';
import { ValueModel } from 'models/ValueModel';
import { LocalStore } from 'stores/LocalStore';
import { IRootStore } from 'stores/RootStore';
import { AppEvent } from 'types/appEvent';
import { BaseResponse } from 'types/meta';

import { LotListProfileRobotFiltersModel } from './LotListProfileRobotFiltersModel';
import { apiCustom } from 'utils/api';
import { ResponseListApi } from 'entities';
import { IRobotCreateFilterModel } from 'entities/robot';
import { FloorOptions } from 'entities/lotFilters';

type ListStoreParams = {
  queryParams: URLSearchParams;
  rootStore: IRootStore;
};

export class LotListProfileRobotStore extends LocalStore {
  readonly lots: ListModel<LotModel, number> = new ListModel<LotModel, number>();
  readonly filters: LotListProfileRobotFiltersModel;
  readonly draftFavoriteLot: ValueModel<LotModel['id'] | null> = new ValueModel<LotModel['id'] | null>(null);
  readonly draftSubscribedLot: ValueModel<LotModel['id'] | null> = new ValueModel<LotModel['id'] | null>(null);
  readonly addToFavoritesLoadingStage: LoadingStageModel = new LoadingStageModel();
  readonly addToSubscriptionsLoadingStage: LoadingStageModel = new LoadingStageModel();
  private readonly rootStore: IRootStore;
  private lotListRequest = apiCustom;
  private abortController?: AbortController;

  constructor({ queryParams, rootStore }: ListStoreParams) {
    super();

    makeObservable(this, {
      setFilters: action.bound,
    });

    this.filters = new LotListProfileRobotFiltersModel({ params: queryParams, routerStore: rootStore.routerStore });
    this.filters.init();
    this.rootStore = rootStore;

    this.subscribe(AppEvent.lotDraftAddToFavorites, ({ lotId }) => {
      this.draftFavoriteLot.change(lotId);
      this.rootStore.authStore.popupController.open();
    });

    this.subscribe(AppEvent.lotDraftSubscribe, ({ lotId }) => {
      this.draftSubscribedLot.change(lotId);

      if (!this.rootStore.userStore.authorized) {
        this.rootStore.authStore.popupController.open();
        return;
      }

      if (!this.rootStore.userStore.user?.hasEmail) {
        this.rootStore.userStore.emailPopupController.open();
      }
    });

    this.addReactions([
      reaction(
        () => this.filters.offset,
        () => {
          this.fetchLotItems({ replace: true });
        },
      ),
      reaction(
        () => this.filters.params,
        () => this.fetchLotItems({ replace: true }),
      ),
      reaction(
        () => this.filters.onlyNew.value,
        () => {
          this.fetchLotItems({ replace: true });
        },
      ),
      reaction(
        () => this.rootStore.userStore.authorized,
        async (isAuthorized: boolean) => {
          if (isAuthorized) {
            await Promise.all([this.checkHasDraftFavorites(), this.checkHasDraftSubscriptions()]);
          }
          this.fetchLotItems({ replace: true });
        },
      ),

      reaction(
        () => this.rootStore.userStore.user?.hasEmail,
        async (hasEmail: boolean | undefined) => {
          if (hasEmail) {
            await this.checkHasDraftSubscriptions();
          }
          this.fetchLotItems({ replace: true });
        },
      ),
    ]);
  }

  private async checkHasDraftFavorites(): Promise<void> {
    if (this.draftFavoriteLot.value === null || this.addToFavoritesLoadingStage.isLoading) {
      return;
    }

    this.addToFavoritesLoadingStage.loading();

    const lotResponse = await LotModel.fromApi(this.draftFavoriteLot.value, this.rootStore);

    if (!lotResponse.isError) {
      await lotResponse.data.addToFavorites();
      this.draftFavoriteLot.change(null);
      this.addToFavoritesLoadingStage.success();

      return;
    }

    this.addToFavoritesLoadingStage.error();
  }

  private async checkHasDraftSubscriptions(): Promise<void> {
    if (this.draftSubscribedLot.value === null || this.addToSubscriptionsLoadingStage.isLoading) {
      return;
    }

    if (!this.rootStore.userStore.user?.hasEmail) {
      this.rootStore.userStore.emailPopupController.open();

      return;
    }

    this.addToSubscriptionsLoadingStage.loading();

    const lotResponse = await LotModel.fromApi(this.draftSubscribedLot.value, this.rootStore);

    if (!lotResponse.isError) {
      await lotResponse.data.addToSubscriptions();
      this.draftSubscribedLot.change(null);
      this.addToSubscriptionsLoadingStage.success();

      return;
    }

    this.addToSubscriptionsLoadingStage.error();
  }

  setFilters(filters: IRobotCreateFilterModel) {
    const isUpdate = (a: unknown, b: unknown) => JSON.stringify(a) !== JSON.stringify(b);

    this.filters.area.valueMax.change(filters?.square?.to || null);
    this.filters.area.valueMin.change(filters?.square?.from || null);
    this.filters.price.valueMax.change(filters?.price?.to || null);
    this.filters.price.valueMin.change(filters?.price?.from || null);
    if (isUpdate(this.filters.objectType.selectedOptionsIds, filters?.object_type)) {
      this.filters.objectType.setSelected(filters?.object_type || null);
    }
    if (isUpdate(this.filters.auctionType.selectedOptionsIds, filters?.auction_type)) {
      this.filters.auctionType.setSelected(filters?.auction_type || null);
    }
    if (isUpdate(this.filters.formType.selectedOptionsIds.length, filters?.form_type)) {
      this.filters.formType.setSelected(filters?.form_type || null);
    }
    if (isUpdate(this.filters.organizer.selectedOptionsIds.length, filters?.auction_organizer)) {
      this.filters.organizer.setSelected(filters?.auction_organizer || null);
    }
    if (isUpdate(this.filters.city.selectedOptionsIds.length, filters?.city)) {
      this.filters.city.setSelected(filters?.city || null);
    }
    if (isUpdate(this.filters.region.selectedOptionsIds, filters?.region)) {
      this.filters.region.setSelected(filters?.region || null);
    }
    if (isUpdate(this.filters.geography.selectedValue.value, filters?.geography)) {
      this.filters.geography.setSelected(filters?.geography || null);
    }
    this.filters.aggregatedType.selectedValue.change(filters?.aggregated_type || 'all');

    this.filters.setSideFilters();
  }

  fetchLotItems = async ({
    replace = false,
    offset,
  }: {
    replace: boolean;
    offset?: number;
  }): Promise<BaseResponse<ListModel<LotModel, number>>> => {
    if (replace) {
      this.lots.setIsAllLoaded(false);
      this.lots.isReplaceLoading.change(true);
    }

    if (this.lots.isAllLoaded || this.draftSubscribedLot.value !== null || this.draftFavoriteLot.value !== null) {
      return { isError: true };
    }

    let request = (this.abortController = new AbortController());

    if (this.lots.loadingStage.isLoading && this.abortController) {
      this.abortController.abort();
    }

    this.lots.loadingStage.loading();

    const _filters: { [key: string]: string | number | boolean | object | null | { from: unknown; to: unknown } } = {
      object_type: this.filters.params.object_type,
      aggregated_type: this.filters.isAllAggregationType ? null : this.filters.params.aggregated_type,
      auction_type: this.filters.params.auction_type,
      square: {
        from: this.filters.params.area_from,
        to: this.filters.params.area_to,
      },
      price: {
        from: this.filters.params.price_from,
        to: this.filters.params.price_to,
      },
      form_type: this.filters.params.form_type,
      auction_organizer: this.filters.params.organizer,
      status: this.filters.params.status,
      region: this.filters.params.region,
      city: this.filters.params.city,
      floor: {
        from: this.filters.params.floor_from,
        to: this.filters.params.floor_to,
      },
      amount_of_floors: {
        from: this.filters.params.amount_of_floors_from,
        to: this.filters.params.amount_of_floors_to,
      },
      geography: this.filters.geography.selectedValue.value,
      only_updates: this.filters.onlyNew.value,
    };
    const _params: { [key: string]: any } = {
      limit: this.filters.limit,
      offset: offset ? offset : this.filters.offset,
      search: this.filters.params.search,
      order: this.filters.params.order,
      order_direction: this.filters.params.order_direction,
    };

    if (_params.search === null || _params.search === '') {
      delete _params['search'];
    }

    Object.keys(_filters).forEach((key) => {
      if (_filters[key] === null) {
        delete _filters[key];
      }

      if (typeof _filters[key] === 'object' && _filters[key] && 'from' in _filters[key] && 'to' in _filters[key]) {
        if (_filters[key].to === null) {
          delete _filters[key].to;
        }
        if (_filters[key].from === null) {
          delete _filters[key].from;
        }
      }
    });

    if ('floor' in _filters) {
      const floorOptions: string[] = [];
      Object.keys(this.filters.floorOptions.value).forEach((key) => {
        if (this.filters.floorOptions.value[key as FloorOptions] === true) {
          floorOptions.push(key);
        }
      });

      if (floorOptions.length > 0) {
        (_filters.floor as any)['options'] = floorOptions;
      }
    }

    const response = await this.lotListRequest<ResponseListApi<LotServer>>({
      url: `${apiUrls.lotList}?limit=${this.filters.limit}&offset=${this.filters.offset}`,
      method: 'POST',
      data: {
        params: _params,
        filters: _filters,
      },
      config: {
        signal: request.signal,
      },
    });

    if (response.isError) {
      this.lots.loadingStage.error();

      return { isError: true };
    }

    this.lots.fillByRawData<LotServer>(
      response.data.results,
      (raw) => {
        const model = LotModel.fromJson(raw, this.rootStore);

        return {
          entity: model,
          key: model.id,
        };
      },
      replace,
    );

    this.lots.isInitialLoading.change(false);
    this.lots.isReplaceLoading.change(false);
    this.lots.total.change(response.data.count);
    this.lots.setIsAllLoaded(response.data.results.length < this.filters.limit);
    this.lots.loadingStage.success();

    return { isError: false, data: this.lots };
  };

  destroy(): void {
    super.destroy();
    this.filters.destroy();
  }
}
