import cn from 'classnames';
import * as React from 'react';

import { BaseIconProps } from 'components/icons/commonProps';

import s from './Logo.module.scss';

type LogoIconProps = BaseIconProps & {
  variant?: 'light' | 'dark' | 'primary';
};

const LogoIcon = ({ className, variant = 'light' }: LogoIconProps): React.ReactElement<LogoIconProps> => {
  return (
    <svg
      className={cn(className, s[`logo_${variant}`])}
      xmlns="http://www.w3.org/2000/svg"
      width="92"
      height="22"
      fill="none"
      viewBox="0 0 92 22"
    >
      <path
        d="M78.6468 7.89881C76.6514 4.98058 72.6765 4.22237 69.7491 6.19509C66.8014 8.18144 66.0273 12.22 68.0341 15.1541C69.4835 17.2755 71.9795 18.2539 74.3586 17.8748C75.1588 17.7465 75.9624 18.078 76.4199 18.7465L78.2552 21.4321C78.6616 22.0257 79.472 22.1789 80.0668 21.7726L80.4448 21.5138C81.0384 21.1074 81.1916 20.297 80.7853 19.7022L78.9499 17.0167C78.4993 16.3572 78.463 15.4946 78.8704 14.8068C80.1031 12.7239 80.1031 10.0293 78.6468 7.89881ZM75.4948 14.4674C73.7922 15.7125 71.3984 15.263 70.2644 13.4844C69.2372 11.8738 69.6436 9.71376 71.185 8.58552C72.8876 7.34036 75.2814 7.78985 76.4153 9.56848C77.4425 11.1791 77.0362 13.3403 75.4948 14.4674Z"
        fill="currentColor"
      />
      <path
        d="M10.0066 16.2846L5.17471 8.95218L3.35976 10.7422V16.2846H0V0H3.35976V6.62191L9.58894 0H14.0032L7.57763 6.42555L14.1507 16.2846H10.0066Z"
        fill="currentColor"
      />
      <path
        d="M21.3606 14.691C20.919 15.7705 19.5944 16.4072 18.0984 16.4072C15.5729 16.4072 13.6842 14.691 13.6842 12.0418V4.2179H16.8726V11.5753C16.8726 13.0713 17.5593 13.8318 18.9077 13.8318C20.3549 13.8318 21.3606 12.875 21.3606 11.5753V4.2179H24.549V16.6025C24.549 20.0111 22.0961 21.9974 19.0065 21.9974C15.4753 21.9974 13.6842 20.0599 13.6842 16.9453H16.6512C16.6512 18.3675 17.4855 19.2506 19.1529 19.2506C20.3061 19.2506 21.3606 18.2449 21.3606 16.6513V14.691Z"
        fill="currentColor"
      />
      <path d="M25.9689 16.2846V4.21899H37.0539V16.2846H33.9393V6.76946H29.0835V16.2846H25.9689Z" fill="currentColor" />
      <path
        d="M38.4772 16.2846V4.21899H41.6168V12.0668L46.2274 4.21899H49.5383V16.2846H46.4238V8.09294L41.543 16.2846H38.4772Z"
        fill="currentColor"
      />
      <path
        d="M62.5778 16.2846V2.77177H57.6483L57.4519 6.35287C56.9854 14.9362 55.5393 16.2857 51.4191 16.2857H50.8062V13.3913H51.1989C53.4304 13.3913 54.1171 12.5821 54.4849 6.35287L54.8526 0.00109863H65.9376V16.2857H62.5778V16.2846Z"
        fill="currentColor"
      />
      <path d="M83.9135 17.6376V8.12243H79.7682V5.57196H91.1483V8.12243H87.0281V17.6376H83.9135Z" fill="currentColor" />
    </svg>
  );
};

export const LogoSmallIcon = ({ className, variant = 'light' }: LogoIconProps): React.ReactElement<LogoIconProps> => {
  return (
    <svg
      className={cn(className, s[`logo_${variant}`])}
      xmlns="http://www.w3.org/2000/svg"
      width="43"
      height="22"
      fill="none"
      viewBox="0 0 43 22"
    >
      <path
        d="M5.45772 8.89205L3.50602 4.89871L2.36732 6.07386V8.89326H0V0.00244141H2.36732V3.38207L5.29487 0.00244141H8.16774L5.1855 3.27391L8.09483 8.89447H5.45772V8.89205Z"
        fill="currentColor"
      />
      <path
        d="M14.385 7.71688C14.0594 8.51166 13.0835 8.98196 11.9813 8.98196C10.1195 8.98196 8.72802 7.71688 8.72802 5.76518V0H11.0771V5.42126C11.0771 6.5235 11.5827 7.08373 12.5767 7.08373C13.6425 7.08373 14.3838 6.37889 14.3838 5.42126V0H16.7329V9.12658C16.7329 11.6385 14.9258 13.1029 12.6484 13.1029C10.0466 13.1029 8.72681 11.675 8.72681 9.38056H10.9131C10.9131 10.4293 11.528 11.0795 12.7566 11.0795C13.6061 11.0795 14.3826 10.3382 14.3826 9.16425V7.71688H14.385Z"
        fill="currentColor"
      />
      <path
        d="M17.7841 8.89204V0.0012207H25.953V8.89204H23.6574V1.88001H20.0797V8.89204H17.7841Z"
        fill="currentColor"
      />
      <path
        d="M27.0006 8.89204V0.0012207H29.3132V5.78463L32.7111 0.0012207H35.1513V8.89204H32.8557V2.85586L29.2598 8.89204H27.0006Z"
        fill="currentColor"
      />
      <path
        d="M21.9098 18.8342V11.8221H19.4696L19.3796 13.4129C19.1086 18.0576 18.241 18.8342 15.7108 18.8342H15.3499V16.9542H15.5844C16.8859 16.9542 17.3915 16.5568 17.5908 13.2853L17.7901 9.94214H24.2054V18.833H21.9098V18.8342Z"
        fill="currentColor"
      />
      <path d="M37.1869 18.8342V11.8221H34.1329V9.94214H42.5182V11.8221H39.4825V18.8342H37.1869Z" fill="currentColor" />
      <path
        d="M34.8693 20.335L33.5373 18.3869C33.2275 17.9336 33.1874 17.3478 33.4365 16.863C33.8849 16.1447 34.145 15.2965 34.145 14.3875C34.145 11.8051 32.0511 9.71118 29.4687 9.71118C26.8863 9.71118 24.7924 11.8051 24.7924 14.3875C24.7924 15.7303 25.3587 16.9395 26.264 17.7926C26.2835 17.8109 26.3029 17.8291 26.3224 17.8473L26.3236 17.8485C27.3821 18.8159 28.8209 19.2291 30.2051 19.0079C30.7848 18.9155 31.3681 19.1549 31.6999 19.6398L33.0318 21.5879C33.3259 22.0193 33.9141 22.1299 34.3455 21.8346L34.6201 21.6474C35.0528 21.3545 35.1634 20.7664 34.8693 20.335ZM29.4687 16.7548C28.1611 16.7548 27.1026 15.6951 27.1026 14.3887C27.1026 13.0823 28.1623 12.0226 29.4687 12.0226C30.7751 12.0226 31.8348 13.0823 31.8348 14.3887C31.8348 15.6951 30.7763 16.7548 29.4687 16.7548Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default LogoIcon;
