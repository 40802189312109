import { Collapse, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import cn from 'classnames';
import React, { useEffect, useMemo } from 'react';

import s from './SubscriptionsHistory.module.scss';
import { useRootStore } from 'stores/RootStore';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';

interface SubscriptionsHistoryProps {
  className?: string;
}

type DataType = {
  plan: { isActive: boolean; title: string };
  start: string;
  end: string;
};

const SubscriptionsHistory = observer(({ className }: SubscriptionsHistoryProps): React.ReactElement => {
  const { tariffsStore, userStore } = useRootStore();

  const activeTariff = useMemo(() => {
    if (userStore.activeUserTariff) {
      const tariffInfo = tariffsStore.tariffs.items.find((item) => item.id === userStore.activeUserTariff?.tariff_id);
      return {
        ...userStore.activeUserTariff,
        tariffInfo: tariffInfo,
      };
    }
    return null;
  }, [tariffsStore.tariffs.items, userStore.activeUserTariff]);

  const dataTable = useMemo(() => {
    return tariffsStore.tariffsHistory.items.map((item) => {
      return {
        plan: {
          isActive: activeTariff?.tariffInfo?.name === item.tariff_name ? true : false,
          title: item?.tariff_name || '-',
        },
        start: item.start,
        end: item.end === '' ? '-' : item.end,
      };
    });
  }, [tariffsStore.tariffsHistory.items, activeTariff]);

  useEffect(() => {
    tariffsStore.fetchTariffsHistory({ replace: true });
  }, []);

  const columns: ColumnsType<DataType> = [
    {
      title: 'Тариф',
      dataIndex: 'plan',
      render: (item) => (
        <span className={cn(s.plan__item, { [s.plan__item_active]: item.isActive })}>{item.title}</span>
      ),
      fixed: 'left',
    },
    {
      title: 'Начало',
      dataIndex: 'start',
      render: (item) => <span className={s.plan__item}>{item}</span>,
    },
    {
      title: 'Окончание',
      dataIndex: 'end',
      render: (item) => <span className={s.plan__item}>{item}</span>,
    },
  ];

  const table = <Table scroll={{ x: 'max-content' }} columns={columns} dataSource={dataTable} pagination={false} />;

  const collapseItem = {
    key: '1',
    label: 'История подписок',
    children: table,
  };

  return <Collapse className={cn(s.collapse, className)} ghost expandIconPosition="end" items={[collapseItem]} />;
});

export default SubscriptionsHistory;
