import { formatNumberToString } from './formatNumberToString';

export const formatPrice = (price: number) => {
  return `${formatNumberToString(price, { minimumFractionDigits: 2 })} ₽`;
};

let rounded = (num: number, decimals: number) => Number(num.toFixed(decimals));

export function fortamtPriceShortV2(num: number) {
  if (num <= 999 && num >= -999) {
    return num.toString();
  } else if (num <= 999999 && num >= -999999) {
    console.log(num, num >= 999950 && 999999 <= num);
    if (num >= 999950 && num <= 999999) {
      return '1 млн';
    }
    const value = rounded(num / 1000, 2)
      .toString()
      .split('.');
    return value[0] === '1000' ? '1 млн' : value.join(',') + ' тыс';
  } else if (num <= 999999999 && num >= -999999999) {
    const value = rounded(num / 1000000, 2)
      .toString()
      .split('.');
    return value[0] === '1000' ? '1 млрд' : value.join(',') + ' млн';
  } else if (num <= 9999999999 && num >= -9999999999) {
    const value = rounded(num / 1000000000, 2)
      .toString()
      .split('.');
    return value.join(',') + ' млрд';
  } else {
    return '1 трлн';
  }
}

export function fortamtPriceShortV3(number: number) {
  function number_format(number: any, decimals: any, dec_point: any, thousands_sep: any) {
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    let n = !isFinite(+number) ? 0 : +number,
      prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
      sep = typeof thousands_sep === 'undefined' ? ',' : thousands_sep,
      dec = typeof dec_point === 'undefined' ? '.' : dec_point,
      s: any = '',
      toFixedFix = function (n: any, prec: any) {
        let k = Math.pow(10, prec);
        console.log('ROUND', prec);
        return '' + Math.round(n * k) / k;
      };

    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
      s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }

    if ((s[1] || '').length < prec) {
      s[1] = s[1] || '';
      s[1] += new Array(prec - s[1].length + 1).join('0');
    }

    return s.join(dec);
  }

  if (number <= 999 && number >= -999) {
    return number_format(number, 0, '.', '');
  } else if (number <= 999999 && number >= -999999) {
    const value = number_format(number / 1e3, 2, '.', '').split('.');
    return value[1] === '00' ? value[0] + ' тыс.' : value.join(',') + ' тыс.';
    console.log();
  } else if (number <= 999999999 && number >= -999999999) {
    const value = number_format(number / 1e6, 2, '.', '').split('.');
    return value[1] === '00' ? value[0] + ' млн.' : value.join(',') + ' млн.';
  } else if (number <= 9999999999 && number >= -9999999999) {
    const value = number_format(number / 1e9, 2, '.', '').split('.');
    return value[1] === '00' ? value[0] + '  млрд.' : value.join(',') + ' млрд.';
  } else {
    const value = number_format(number / 1e10, 2, '.', '').split('.');
    return value[1] === '00' ? value[0] + '  трлн.' : value.join(',') + ' трлн.';
  }
}

export const fortamtPriceShort = (price: number) => {
  const string_price = price.toString();
  const format_price = formatNumberToString(price, { minimumFractionDigits: 0 }).toString().split(' ');

  if (price >= 1000 && price < 999999) {
    return format_price[0] + ' тыс';
  }
  if (price > 999999 && price < 1000000000) {
    return format_price[0] + ' млн';
  }
  if (price >= 1000000000 && price < 99999999999999) {
    return format_price[0] + ' млрд';
  }
  return string_price;
};
