import React from 'react';
import s from './Banner.module.scss';

export const Banner = ({
  className = '',
  action,
  description,
  title,
  loading = false,
}: {
  description: string;
  title: string;
  className?: string;
  action: { label: string; onClick: () => void };
  loading?: boolean;
}) => {
  return (
    <div className={s.banner + ' ' + className + ' ' + (loading ? s.loading : '')}>
      <h4 className={s.title}>{title}</h4>
      <p className={s.desc} dangerouslySetInnerHTML={{ __html: description }} />
      <button className={s.buttonRound} onClick={action.onClick}>
        {action.label}
      </button>
    </div>
  );
};
