import React from 'react';
import s from './Services.module.scss';
import { Wrapper } from 'components/Wrapper';
import Slider from 'components/Slider';
import { A11y, Pagination } from 'swiper/modules';
import { CardService } from './components/CardService/CardService';
import service1 from '../assets/s1.png';
import service2 from '../assets/s2.png';
import service3 from '../assets/s3.png';
import { useNavigate } from 'react-router-dom';
import { RouteFullPath } from 'config/routes';

export const Services = () => {
  const navigate = useNavigate();
  const pagination = {
    clickable: true,
    renderBullet: (index: number, className: string) => {
      return `<span class="${className} ${s.bullet}" > </span>`;
    },
    el: '.paginaton-service',
  };
  return (
    <div className={s.container}>
      <Wrapper>
        <h2 className={s.title}>
          Дополнительные <br /> услуги
        </h2>
        <div>
          <Slider
            spaceBetween={46}
            slideClass={s.slide}
            pagination={pagination}
            loop
            slides={[
              <CardService
                title={'Юридический аудит'}
                description={
                  'Защитите себя от рисков.<br />Проверка документов юридическим экспертомдля выявления рисков до заключения сделки'
                }
                image={service1}
                onClick={() => {
                  navigate(RouteFullPath.services_audit_legal);
                }}
              />,
              <CardService
                title={'Сопровождение сделки'}
                description={
                  'Поможем пройти все этапы участия в торгах: <br /> от проверки документовдо <br /> подписания договора'
                }
                image={service2}
                onClick={() => {
                  navigate(RouteFullPath.services_escort);
                }}
              />,
              <CardService
                title={'Онлайн-показ'}
                description={'Посмотрите понравившийся объект: <br /> мы проведем для вас личный тур'}
                image={service3}
                onClick={() => {
                  navigate(RouteFullPath.services_online);
                }}
              />,
            ]}
            onSlideChange={() => {}}
            slidesPerView={'auto'}
            modules={[Pagination]}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
              540: {
                slidesPerView: 1.4,
              },
              768: {
                spaceBetween: 46,
                slidesPerView: 'auto',
              },
            }}
            watchOverflow
          />
          <div className={'paginaton-service ' + s.pagination} />
        </div>
      </Wrapper>
    </div>
  );
};
