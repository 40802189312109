import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useLotListStore } from 'stores/LotListCatalogStore/context';
import { RangeFilterPriceMobile } from 'components/RangeFilterPriceMobile';

type PriceProps = {
  className?: string;
};

const PriceSelect = ({ className }: PriceProps): React.ReactElement<PriceProps> => {
  const { filters } = useLotListStore();
  return (
    <div>
      <RangeFilterPriceMobile
        from={filters.price.from.value}
        to={filters.price.to.value}
        valueMin={filters.price.valueMin.value}
        valueMax={filters.price.valueMax.value}
        onChangeMin={filters.price.valueMin.change}
        onChangeMax={filters.price.valueMax.change}
        placeholderPostfix="₽"
        seporatorHide
        focused
      />
    </div>
  );
};

export default observer(PriceSelect);
