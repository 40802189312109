import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useLotListStore } from 'stores/LotListCatalogStore/context';
import { Popover } from 'antd';
import cn from 'classnames';
import s from './PriceSelect.module.scss';
import { fortamtPriceShortV2 } from 'utils/formatPrice';
import { ArrowStockIcon } from 'components/icons/ArrowStockIcon';
import { RangeFilterPrice } from 'components/RangeFilterPrice';

type PriceProps = {
  className?: string;
};

const PriceSelect = ({ className }: PriceProps): React.ReactElement<PriceProps> => {
  const { filters } = useLotListStore();
  const [open, setOpen] = React.useState(false);
  const placeholder = React.useMemo(() => {
    let priceText = [];

    if (filters.price.valueMin.value) {
      priceText.push('от ' + fortamtPriceShortV2(filters.price.valueMin.value));
    }
    if (filters.price.valueMax.value) {
      priceText.push('до ' + fortamtPriceShortV2(filters.price.valueMax.value));
    }

    if (filters.price.valueMin.value && filters.price.valueMax.value) {
      const split_from = fortamtPriceShortV2(filters.price.valueMin.value).split(' ');
      const split_to = fortamtPriceShortV2(filters.price.valueMax.value).split(' ');
      if (
        split_from[1] &&
        split_to[1] &&
        split_from[1] === split_to[1] &&
        filters.price.valueMin.value.toString().length === filters.price.valueMax.value.toString().length
      ) {
        priceText = ['от ' + split_from[0], split_to.join(' ')];
      }
    }

    return priceText.length === 0 ? 'Цена' : priceText.join(' - ');
  }, [filters.price.valueMax.value, filters.price.valueMin.value]);
  return (
    <div className={s.container}>
      <div
        className={cn(s.select, open && s.open, placeholder !== 'Цена' && s.text, className && className)}
        role="button"
        onClick={() => setOpen((prev) => !prev)}
      >
        <span>{placeholder}</span>
        <ArrowStockIcon />
      </div>
      <Popover
        overlayClassName={s.popoverNoPadding}
        placement="bottom"
        align={{ offset: [90, 5] }}
        content={
          <div>
            <RangeFilterPrice
              from={filters.price.from.value}
              to={filters.price.to.value}
              valueMin={filters.price.valueMin.value}
              valueMax={filters.price.valueMax.value}
              onChangeMin={filters.price.valueMin.change}
              onChangeMax={filters.price.valueMax.change}
              placeholderPostfix="₽"
              placeholder={{
                from: 'от',
                to: 'до',
              }}
              seporatorHide
            />
          </div>
        }
        arrow={false}
        open={open}
        onOpenChange={setOpen}
      ></Popover>
    </div>
  );
};

export default observer(PriceSelect);
