import { CollapsedStatic } from 'components/Сollapsed/Сollapsed';
import React from 'react';
import s from './Faq.module.scss';
import CloseIcon from 'components/icons/CloseIcon/CloseIcon';

export const Faq = () => {
  return (
    <div>
      <h2 className={s.title}>Частые вопросы</h2>
      <div className={s.collapsed}>
        <CollapsedStatic
          title="Как часто появляются новые объекты торгов на сайте?"
          icon={<CloseIcon className={s.iconClose} />}
        >
          <div className={s.content}>
            <p>
              Ежедневно обновляем и пополняем наш сайт новыми объектами, чтобы вы всегда могли найти самые актуальные
              предложения
            </p>
          </div>
        </CollapsedStatic>
        <CollapsedStatic
          title="Как и где подать заявку на участие в торгах?"
          icon={<CloseIcon className={s.iconClose} />}
        >
          <div className={s.content}>
            <p>
              При нажатии выпадает дополнительное поле с текстом: Мы являемся агрегатором лотов с электронных торговых
              площадок. Чтобы принять участие в торгах, вам нужно выбрать интересующий объект и перейти на
              соответствующую электронную торговую площадку для подачи заявки. Ссылка на ЭТП указана в описании карточки
              выбранного объекта.
            </p>
          </div>
        </CollapsedStatic>
        <CollapsedStatic title="Какое имущество представлено на торгах?" icon={<CloseIcon className={s.iconClose} />}>
          <div className={s.content}>
            <p>
              На нашем сайте вы найдете большое разнообразие категорий имущества включая квартиры, дома, земельные
              участки с постройками и без, коммерческие помещения, здания, имущественные комплексы и тд. Мы постоянно
              расширяем ассортимент и добавляем все больше категорий лотов, чтобы вы могли найти именно то, что вам
              нужно
            </p>
          </div>
        </CollapsedStatic>
        <CollapsedStatic title="Что нужно для принятия участия в торгах?" icon={<CloseIcon className={s.iconClose} />}>
          <div className={s.content}>
            <h3>С сопровождением нашего специалиста:</h3>
            <p className={s.padding}>Мы возьмем на себя все задачи, связанные с участием в торгах.</p>
            <p className={s.padding}>
              Ваш личный менеджер поможет собрать пакет документов, объяснит, на какой счет переводить задаток, составит
              заявку, которую гарантированно примут, и будет с вами на связи, отвечая на все вопросы.
            </p>
            <h3 className={s.contnet__margin__top}>Самостоятельно:</h3>
            <ul>
              <li>
                Выпустить усиленную квалифицированную электронную подпись (УКЭП), подходящую для площадки, где будут
                проходить торги.
              </li>
              <li>Пройти аккредитацию на площадке.</li>
              <li>Прикрепить пакет документов на площадку.</li>
              <li>Внести задаток за лот.</li>
              <li>Правильно оформить и подать заявку.</li>
              <li>В день проведения торгов зайдите на электронную торговую площадку и запустите процедуру.</li>
              <li>Внести задаток за лот.</li>
            </ul>
          </div>
        </CollapsedStatic>
      </div>
    </div>
  );
};
