import { Button, Flex, Typography } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { SupportModal } from 'components/SupportModal';
import { Wrapper } from 'components/Wrapper';
import { LogoSmallIcon } from 'components/icons/Logo/Logo';
import TelegramIcon from 'components/icons/TelegramIcon';
import { LINKS } from 'config/links';
import { RouteFullPath, routerUrls } from 'config/routes';
import { AnalyticsEvent } from 'entities/analytics';
import useToggleState from 'hooks/useToggleState';
import { useRootStore } from 'stores/RootStore';
import { useThemeStore } from 'stores/RootStore/context';

import s from './Footer.module.scss';
import { useWidth } from 'hooks/useWidth';
import { EMAILS, PHONE } from 'utils/page';

type FooterProps = {
  className?: string;
};

const Phone = () => {
  return (
    <Wrapper className={s.footer__contacts}>
      <Typography.Text className={cn(s.footer__contacts__item, s.footer__contacts__phone)}>
        <a href={'tel:' + PHONE.clear}>{PHONE.mask}</a>
      </Typography.Text>
      <Typography.Text className={cn(s.footer__contacts__item, s.footer__contacts__time)}>
        по будням с 10:00 до 18:00
      </Typography.Text>
    </Wrapper>
  );
};

const MenuItems = ({
  onClickSupport,
  onMenuClick,
}: {
  onClickSupport: () => void;
  onMenuClick: (e: React.MouseEvent) => void;
}) => {
  return (
    <>
      <Link to={routerUrls.lotsList.create()}>
        <Typography.Text className={s.footer__text}>Каталог объектов</Typography.Text>
      </Link>

      <Link to={routerUrls.lotsList.create()}>
        <Typography.Text className={s.footer__text}>Умный поиск</Typography.Text>
      </Link>

      <Link to={routerUrls.tariffs.create()} onClick={onMenuClick}>
        <Typography.Text className={s.footer__text}>Тарифы</Typography.Text>
      </Link>

      <Link to={routerUrls.servicesOnline.create()} onClick={onMenuClick}>
        <Typography.Text className={s.footer__text + ' ' + s.order__last}>Онлайн-показ</Typography.Text>
      </Link>

      <Link to={routerUrls.servicesOrder.create()} onClick={onMenuClick}>
        <Typography.Text className={s.footer__text}>Юридический аудит</Typography.Text>
      </Link>

      <Link to={routerUrls.servicesEscort.create()} onClick={onMenuClick}>
        <Typography.Text className={s.footer__text}>Сопровождение сделки</Typography.Text>
      </Link>

      <Typography.Link
        href=""
        className={s.footer__text + ' ' + s.order__first}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClickSupport();
        }}
      >
        Связаться с нами
      </Typography.Link>
    </>
  );
};

const MenuItemsMobile = ({
  onClickSupport,
  onMenuClick,
}: {
  onClickSupport: () => void;
  onMenuClick: (e: React.MouseEvent) => void;
}) => {
  return (
    <>
      <Typography.Link
        href=""
        className={s.footer__text + ' ' + s.order__first}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClickSupport();
        }}
      >
        Связаться с нами
      </Typography.Link>

      <Link to={routerUrls.servicesOrder.create()} onClick={onMenuClick}>
        <Typography.Text className={s.footer__text}>Юридический аудит</Typography.Text>
      </Link>

      <Link to={routerUrls.lotsList.create()}>
        <Typography.Text className={s.footer__text}>Каталог объектов</Typography.Text>
      </Link>
      <Link to={routerUrls.servicesEscort.create()} onClick={onMenuClick}>
        <Typography.Text className={s.footer__text}>Сопровождение сделки</Typography.Text>
      </Link>

      <Link to={routerUrls.lotsList.create()}>
        <Typography.Text className={s.footer__text}>Умный поиск</Typography.Text>
      </Link>

      <Link to={routerUrls.servicesOnline.create()} onClick={onMenuClick}>
        <Typography.Text className={s.footer__text + ' ' + s.order__last}>Онлайн-показ</Typography.Text>
      </Link>

      <Link to={routerUrls.tariffs.create()} onClick={onMenuClick}>
        <Typography.Text className={s.footer__text}>Тарифы</Typography.Text>
      </Link>
    </>
  );
};

const Menu = ({ onClickSupport }: { onClickSupport: () => void }) => {
  const { userStore } = useRootStore();
  const { authStore } = useRootStore();
  const { width = window.innerWidth } = useWidth();
  const handleMenuClick = (e: React.MouseEvent) => {
    if (!userStore.authorized) {
      e.preventDefault();
      e.stopPropagation();
      authStore.popupController.open();
      authStore.mode.change('login');
    }
  };
  return (
    <Flex className={s.footer__list}>
      {width > 1100 ? (
        <MenuItems onClickSupport={onClickSupport} onMenuClick={handleMenuClick} />
      ) : (
        <MenuItemsMobile onClickSupport={onClickSupport} onMenuClick={handleMenuClick} />
      )}
    </Flex>
  );
};

const Footer: React.FC<FooterProps> = ({ className }) => {
  const currentYear = new Date().getFullYear();
  const { analyticsStore } = useRootStore();
  const { theme } = useThemeStore();
  const { width = window.innerWidth } = useWidth();
  const { open: openSupportModal, close: closeSupportModal, opened: openedSupportModal } = useToggleState();

  const handleTelegramClick = React.useCallback(() => {
    analyticsStore.trackEvent({ event: AnalyticsEvent.telegramFooterLinkClick });
    window.open('https://t.me/kupilot_robot', '_blank');
  }, [analyticsStore]);

  return (
    <div className={cn(className, s.footer, theme.value === 'dark' && s.footer__dark)}>
      <Wrapper className={cn(s.footer__row, s.footer__links)}>
        <Link className={s.logo} to={RouteFullPath.home}>
          <LogoSmallIcon className={s.logo} variant="primary" />
        </Link>
        {width >= 767 && <Menu onClickSupport={openSupportModal} />}
        <Button
          className={s['footer__button-icon']}
          type="link"
          href={LINKS.telegram}
          target="_blank"
          icon={<TelegramIcon />}
          onClick={handleTelegramClick}
          aria-label="Telegram"
        />
      </Wrapper>
      <Phone />
      {width < 767 && <Menu onClickSupport={openSupportModal} />}
      <Wrapper className={cn(s.footer__row, s.footer__emails)}>
        <Typography.Text className={s.footer__emails__item}>
          <a href={'mailto:' + EMAILS.support}>Техническая поддержка: {EMAILS.support}</a>
        </Typography.Text>
        <Typography.Text className={s.footer__emails__item}>
          <a href={'mailto:' + EMAILS.info}>По остальным вопросам: {EMAILS.info}</a>
        </Typography.Text>
        <Typography.Text className={s.footer__emails__item}>
          <a href={'mailto:' + EMAILS.service}>Заказать дополнительные услуги: {EMAILS.service} </a>
        </Typography.Text>
        <Typography.Text className={s.footer__emails__item}>
          <a href={'mailto:' + EMAILS.partners}>По вопросам сотрудничества: {EMAILS.partners}</a>
        </Typography.Text>
      </Wrapper>
      <Wrapper className={cn(s.footer__row, s.footer__bottom)}>
        <Typography.Text className={s.footer__copyright}>
          © Copyright {currentYear}. Все права защищены.
        </Typography.Text>
        <Typography.Link target="_blank" className={s.footer__policy} href={LINKS.politics}>
          Политика обработки персональных данных
        </Typography.Link>
      </Wrapper>

      <SupportModal opened={openedSupportModal} close={closeSupportModal} />
    </div>
  );
};

export default observer(Footer);
