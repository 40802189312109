import * as React from 'react';
import { BaseIconProps } from '../commonProps';

const CloseIcon = (props: BaseIconProps): React.ReactElement<BaseIconProps> => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18.6346 19.7663L11.9998 13.133L5.36504 19.7663C5.05298 20.0782 4.54569 20.0782 4.23364 19.7663C4.07681 19.6095 4 19.4047 4 19.1999C4 18.9951 4.07841 18.7903 4.23364 18.6335L10.8684 12.0002L4.23364 5.36698C4.07681 5.21018 4 5.00539 4 4.8006C4 4.59581 4.07841 4.39102 4.23364 4.23423C4.5457 3.92225 5.05299 3.92225 5.36504 4.23423L11.9998 10.8675L18.6346 4.23423C18.9466 3.92225 19.4539 3.92225 19.766 4.23423C20.078 4.54622 20.078 5.05339 19.766 5.36538L13.1312 11.9986L19.766 18.6319C20.078 18.9439 20.078 19.4511 19.766 19.7631C19.4539 20.075 18.9466 20.075 18.6346 19.7631L18.6346 19.7663Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CloseIcon;
