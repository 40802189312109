import React, { ReactNode } from 'react';
import s from './Banner.module.scss';
import { Button } from 'antd';
import cn from 'classnames';

export const BannerPlaceholder = ({
  className,
  clsx,
}: {
  className?: string;
  clsx?: {
    root?: string;
  };
}) => {
  return <div className={`${s.banner} ${className} ${clsx?.root || ''}`} />;
};

export const Banner = ({
  title,
  description,
  className = '',
  backgroundImage,
  actions,
  children,
  clsx,
}: {
  title: string;
  description: string;
  onClick?: () => void;
  className?: string;
  backgroundImage?: string;
  actions?: { label: string; onClick: () => void; className?: string; subuttonLabel?: string }[];
  children?: ReactNode;
  clsx?: {
    root?: string;
    title?: string;
    actions?: string;
    desc?: string;
    body?: string;
    subbutonLabel?: string;
  };
}) => {
  return (
    <div
      className={`${s.banner} ${className} ${clsx?.root || ''}`}
      style={{ backgroundImage: backgroundImage ? `url(${backgroundImage})` : undefined }}
    >
      <div className={cn(s.body, clsx?.body)}>
        <h2 className={cn(s.title, clsx?.title)}>{title}</h2>
        <p className={cn(s.desc, clsx?.desc)} dangerouslySetInnerHTML={{__html: description}} />
        {children}
        {actions && actions.length > 0 && (
          <div className={cn(s.actions, clsx?.actions)}>
            {actions.map((item, index) => (
              <Button key={index} onClick={item.onClick} className={`${s.btn} ${item?.className || ''}`}>
                {item.label}
              </Button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
