import RobotIcon from 'components/icons/Robot';
import s from './RobotHeader.module.scss';
import { observer } from 'mobx-react-lite';
import React, { ReactNode, useEffect } from 'react';
import { useRootStore } from 'stores/RootStore';
import { CloseOutlined } from '@ant-design/icons';
import { IRobotCreateFilterModel, IRobotModel } from 'entities/robot';

export const RobotHeader = observer(
  ({
    title,
    className = '',
    disabled,
    isLoading,
    onClickFilter,
    onDelete,
    icon = <RobotIcon />,
  }: {
    title?: string | ReactNode;
    className?: string;
    disabled?: boolean;
    isLoading?: boolean;
    onClickFilter: (filters: IRobotCreateFilterModel) => void;
    onDelete?: () => void;
    icon?: ReactNode;
  }) => {
    const { robotStore, userStore } = useRootStore();
    const { robotFilters, isListInitialLoading, deleteRobotItemFetch, setRobotItem } = robotStore;
    const [active, setActive] = React.useState<IRobotModel | null>(null);
    const isDisabled = React.useMemo(() => {
      return disabled === true || isLoading === true;
    }, [disabled, isLoading]);

    const handlerClickItem = (item: IRobotModel) => {
      setActive(item);
      setRobotItem(item);
      onClickFilter(item.filters);
    };

    const handlerDelete = (id: number) => {
      deleteRobotItemFetch(id).then((res) => {
        if (!res.isError) {
          onDelete && onDelete();
        }
      });
    };

    useEffect(() => {
      return () => {
        setRobotItem(null);
      };
    }, []);

    return (
      <div className={`${s.wrap} ${className}`}>
        {icon}
        {!userStore.authorized ? (
          <div className={s.textEllipsis}>{title}</div>
        ) : (
          <>
            {title && robotFilters.length === 0 && <div className={s.textEllipsis}>{title}</div>}
            {!isListInitialLoading && robotFilters.items.length > 0 && (
              <ul className={s.list}>
                {robotFilters.items.map((item) => (
                  <li className={`${s.list__item} ${item.id === active?.id ? s.active : ''}`} key={item.id}>
                    <button
                      disabled={isDisabled}
                      type="button"
                      onClick={() => handlerClickItem(item)}
                      className={s.list__name}
                    >
                      {item.new_lots_count > 0 ? <span>(+{item.new_lots_count})</span> : null}
                      {item.name}
                    </button>
                    {item.id !== active?.id && (
                      <button
                        disabled={isDisabled}
                        type="button"
                        onClick={() => handlerDelete(item.id)}
                        className={s.list__del}
                      >
                        <CloseOutlined />
                      </button>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </>
        )}
      </div>
    );
  },
);
