import { Typography } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import s from './Footer.module.scss';
import { ViewsCount } from 'components/ViewsCount';

type FooterProps = {
  viewsCount: number | null;
  dateAdded: string;
  className?: string;
  showViewCount?: boolean;
};

const Footer = ({ dateAdded, className, viewsCount, showViewCount }: FooterProps): React.ReactElement<FooterProps> => {
  return (
    <div className={cn(s.footer, className)}>
      {showViewCount ? <ViewsCount className={s['header__views-count']} viewsCount={viewsCount ?? 0} /> : <div />}
      <Typography.Text className={s['footer__date']}>{dateAdded}</Typography.Text>
    </div>
  );
};

export default observer(Footer);
