import { RouteSegment } from 'config/routes/constants';
import { routerUrls } from 'config/routes/routerUrls';

type MenuItem = {
  // Паттерн для мэтчинга через matchPath
  pattern: string;
  // URL страницы для навигации при клике на элемент меню
  url: string;
  title: string;
  isAuthRequired: boolean;
  cheildren?: { key: string; label: string; url: string }[];
};

export const MENU_ITEMS: MenuItem[] = [
  {
    pattern: `/${RouteSegment.lots}/*`,
    url: routerUrls.lotsList.create(),
    title: 'Каталог объектов',
    isAuthRequired: false,
  },
  {
    pattern: `/${RouteSegment.profile}/${RouteSegment.robots}`,
    url: routerUrls.profileRobot.create(),
    title: 'Умный поиск',
    isAuthRequired: true,
  },
  {
    pattern: `/${RouteSegment.tariffs}`,
    url: routerUrls.tariffs.create(),
    title: 'Тарифы',
    isAuthRequired: false,
  },
  {
    pattern: `/${RouteSegment.services_online}`,
    url: '#',
    title: 'Услуги',
    isAuthRequired: false,
    cheildren: [
      { key: '1', label: 'Онлайн-показ', url: routerUrls.servicesOnline.create() },
      { key: '2', label: 'Сопровождение сделки', url: routerUrls.servicesEscort.create() },
      { key: '3', label: 'Юридический аудит', url: routerUrls.servicesOrder.create() },
    ],
  },
];
