import { Space, Typography } from 'antd';
import { SpaceSize } from 'antd/es/space';
import * as React from 'react';

import s from './BaseInput.module.scss';
import { useWidth } from 'hooks/useWidth';

type BaseInputProps = React.PropsWithChildren<{
  title?: string;
  size?: SpaceSize | [SpaceSize, SpaceSize];
  className?: string;
}>;

const BaseInput = ({
  title,
  className,
  size = 'small',
  children,
}: BaseInputProps): React.ReactElement<BaseInputProps> => {
  return (
    <Space className={className + ' ' + s['base-input']} direction="vertical">
      {title && (
        <Typography.Title className={s['base-input__title']} level={5}>
          {title}
        </Typography.Title>
      )}
      {children}
    </Space>
  );
};

export default React.memo(BaseInput);
